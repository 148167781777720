import React, {useEffect, useRef, useState } from 'react';
import {FeatureGroup, LayersControl, MapContainer,  Marker, Popup, TileLayer} from "react-leaflet";
import Fullscreen from 'react-leaflet-fullscreen-plugin';
import L from "leaflet";
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import 'leaflet-dialog/Leaflet.Dialog'
import 'leaflet-dialog/Leaflet.Dialog.css'

import 'leaflet-spin/leaflet.spin.min'

import 'leaflet-responsive-popup/leaflet.responsive.popup.css'
import 'leaflet-responsive-popup/leaflet.responsive.popup'


//Chroma
import chroma from "chroma-js";
import QvantumService from "../../../service/QvantumService";
import {Button} from "primereact/button";
import * as ReactDOM from "react-dom";
import {TabPanel, TabView} from "primereact/tabview";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {DataView} from "primereact/dataview";
import * as turf from "@turf/turf";
import {InputText} from "primereact/inputtext";
import {Divider} from "primereact/divider";
import {Toolbar} from "primereact/toolbar";
import {ContextMenu} from "primereact/contextmenu";
import {InputNumber} from "primereact/inputnumber";



export const Map = (props) => {

    const [map, setMap] = useState(null);
    const position = [51.505, -0.09]
    const [layerIndices, setLayerIndices] = useState([]);
    const adminMarker = useRef({
        'action':'administrationLevel',
        'request':{
            'level':0,
            'lat_lng':[],
            'glowglobeId':''
        }
    });


    const options = {
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
    };

    const coordTemplate = (rowData,row) => {
        let coord = turf.getCoord(rowData[row.field]);
        return (
            <>
                <span className="p-float-label">
                    <InputText value={coord}  className="p-inputtext-sm" style={{width:"100%"}}/>
                    <label htmlFor="lefticon">{rowData.label}</label>
                </span>
            </>

        );
    }

    const areaTemplate = (rowData,row) => {
        let suffix = '';
        if(rowData.metric === 'sqmeters'){
            suffix = ' m2';
        }else if(rowData.metric === 'sqkilometers'){
            suffix = ' km2';
        }else if(rowData.metric === 'sqfeet'){
            suffix = ' ft2';
        }else if(rowData.metric === 'acres'){
            suffix = ' acre';
        }else if(rowData.metric === 'sqmiles'){
            suffix = ' mi2';
        }
        return (
            <>
                <span className="p-float-label">
                    <InputNumber value={rowData[row.field]}
                                 useGrouping={false}
                                 minFractionDigits={2} maxFractionDigits={5}
                                 suffix={suffix}
                                 className="p-inputtext-sm"
                    />
                    <label htmlFor="lefticon">{rowData.label}</label>
                </span>
            </>

        );
    }

    const areaAction = (rowData,row) => {

        let geojson = {
            layer: rowData[row.field],
            title: rowData.metric,
            label: rowData.label
        }

        return (
            <div className="p-toolbar-group-right">
                <Button  type="button"
                         icon="fad fa-clipboard"
                         tooltip={"Copy to clipboard"}
                         tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                         className="p-mr-1"
                         onClick={()=>{props.action({
                             'action':'copy',
                             'data':geojson
                         })}}
                />
            </div>
        );
    }

    const geometryAction = (rowData,row) => {

        let geojson = {
            layer: rowData[row.field],
            title: rowData.metric,
            label: rowData.label
        }

        return (
            <div className="p-toolbar-group-right">
                <Button  type="button"
                         icon="fad fa-download"
                         tooltip={"Download Point as geojson"}
                         tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                         className="p-mr-1"
                         onClick={()=>{props.action({
                            'action':'download',
                            'data':geojson
                            })}}
                />
                <Button  type="button"
                         icon="fad fa-clipboard"
                         tooltip={"Copy to clipboard"}
                         tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                         className="p-mr-1"
                         onClick={()=>{props.action({
                             'action':'copy',
                             'data':geojson
                         })}}
                />
            </div>
        );
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        rowData[field] = newValue;
    }

    const onSaveFeature = (data) => {
        const id = data[0].id;
        const featureIndex = data[0].featureIndex;
        let layerCount = 0;
        map.eachLayer(function(layer){
            if(layer.options.id){
                if(layer.options.id  === id){
                    let geojsonProperties = {};
                    if(layer.feature){
                        if(layerCount === featureIndex){
                            data.forEach(
                                (item)=>{
                                    geojsonProperties[item.property] = item.value;
                                }
                            )
                            layer.feature.properties = geojsonProperties;
                        }else{
                            layerCount++;
                        }

                    }
                }
            }
        });
    }

    const sendBack = (geojson) => {
        map.eachLayer(
            (layer) =>{
                if(layer.options){
                    if(layer.options.id === geojson.id){
                        layer.bringToBack();
                    }
                }
            }
        )
    }

    const sendFront = (geojson) => {
        map.eachLayer(
            (layer) =>{
                if(layer.options){
                    if(layer.options.id === geojson.id){
                        layer.bringToFront();
                    }
                }
            }
        )
    }

    const popUp = (geojson) => {

        let data = [];
        turf.propEach(geojson.layer, function (currentProperties, featureIndex) {
            data = Object.keys(currentProperties).map(
                (property)=>{
                    let value = currentProperties[property];
                    let id = geojson.id;
                    return {
                        property,
                        value,
                        featureIndex,
                        id
                    }
                }
            )
        });



        let sqMeters = turf.area(geojson.layer);
        let sqKilometers = sqMeters/1000000;
        let sqFeet = sqMeters*3.281;
        let acres = sqMeters*4047;
        let sqMiles = sqMeters*0.00000038610215855;

        let center = turf.center(geojson.layer);
        let centerOfMass = turf.centerOfMass(geojson.layer);
        let centroid = turf.centroid(geojson.layer);

        let area = [
            {
                'metric':'sqmeters',
                'label':' Sq. Meters',
                'value':sqMeters
            },
            {
                'metric':'sqkilometers',
                'label':'Sq. Kilometers',
                'value':sqKilometers
            },
            {
                'metric':'sqfeet',
                'label':'Sq. Feet',
                'value':sqFeet
            },
            {
                'metric':'acres',
                'label':'Acres',
                'value':acres
            },
            {
                'metric':'sqmiles',
                'label':'Sq. Miles',
                'value':sqMiles
            }

        ]

        let centerStats = [
            {
                'metric':'center',
                'label':'Center',
                'value':center
            },
            {
                'metric':'centerofmass',
                'label':'Center of Mass',
                'value':centerOfMass
            },
            {
                'metric':'centroid',
                'label':'Centroid',
                'value':centroid
            }
        ]

        const leftContents = (geojson,data) => {
            return (
                <>
                    <Button
                        icon="fad fa-save"
                        className="p-mr-1"
                        tooltip={"Save property changes"}
                        tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                        onClick={()=>onSaveFeature(data)}
                    />
                    <Button
                        icon="fad fa-download"
                        className="p-mr-1"
                        tooltip={"Download polygon"}
                        tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                        onClick={()=>{props.action({
                        'action':'download',
                        'data':geojson
                    })}}/>
                    <Button  type="button"
                             icon="fad fa-clipboard"
                             tooltip={"Copy to clipboard"}
                             tooltipOptions={{ className: 'blue-tooltip', position: 'top' }}
                             onClick={()=>{props.action({
                                 'action':'copy',
                                 'data':geojson
                             })}}
                    />
                </>
            );
        }

        const rightContents = (geojson) => {
            return (
                <>
                    <Button
                        icon="fad fa-send-back"
                        className="p-mr-1 p-button-success"
                        tooltip={"Send "+geojson.title+" to back"}
                        tooltipOptions={{ className: 'green-tooltip', position: 'top' }}
                        onClick={()=>sendBack(geojson)}
                    />

                    <Button
                        icon="fad fa-bring-front"
                        className="p-button-success"
                        tooltip={"Send "+geojson.title+" to front"}
                        tooltipOptions={{ className: 'green-tooltip', position: 'top' }}
                        onClick={()=>sendFront(geojson)}
                    />
                </>
            );
        }

        return (
            <>
                <h5>{geojson.title}</h5>
                <TabView>
                    <TabPanel header="Properties">
                        <DataTable value={data}
                                   editMode="cell"
                                   className="editable-cells-table"
                                   size="small"
                                   responsiveLayout="scroll">
                            <Column field="property" header="Property"
                                    editor={(options) => textEditor(options)}
                                    onCellEditComplete={onCellEditComplete}
                            ></Column>
                            <Column field="value"
                                    editor={(options) => textEditor(options)}
                                    onCellEditComplete={onCellEditComplete}
                                    header="Value"></Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Area">
                        <DataTable value={area} size="small" responsiveLayout="scroll">
                            <Column field="value"
                                    body={areaTemplate}
                            ></Column>

                            <Column field="value" body={areaAction}></Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Geometry">
                        <DataTable value={centerStats} size="small" responsiveLayout="scroll">
                            <Column field="value"
                                    body={coordTemplate}></Column>
                            <Column field="value" body={geometryAction}></Column>
                        </DataTable>
                    </TabPanel>
                </TabView>
                <Toolbar left={leftContents(geojson,data)} right={rightContents(geojson)} />
            </>)
    }

    useEffect(() => {
        if (!map) return;
        // add Leaflet-Geoman controls with some options to the map
        map.pm.addControls({
            position: 'topright',
            positions:{
                'draw':'topleft',
                'edit':'topleft',
                'custom':'topright'
            },
            drawMarker: false,
            drawCircleMarker: false,
            drawPolyline: false,
            drawRectangle: false,
            drawPolygon: false,
            drawCircle: false,
            drawText: false,
            editMode: false,
            dragMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false
        });

        map.pm.Toolbar.copyDrawControl('Marker', {
            name: 'administrationLevelSelector',
            block: 'draw',
            title: 'Select Administration Level',
            toggle:	true,
            /*onClick: (e)=>{
                console.log('!!!')
                let administrationMarker = {
                    markerStyle: {
                        opacity: 0.5,
                        draggable: false,
                    }
                };
                map.pm.enableDraw('administrationLevelSelector', administrationMarker);
            },*/
            actions: [
            'cancel',
            {
                'text':'Administration Level 0',
                onClick: ()=>{
                    adminMarker.current = {
                        'action':'administrationLevel',
                        'request':{
                            'level':0,
                            'lat_lng':[],
                            'glowglobeId':''
                        }
                    };
                }
            },
            {
                'text':'Administration Level 1',
                onClick: ()=>{
                    adminMarker.current = {
                        'action':'administrationLevel',
                        'request':{
                            'level':1,
                            'lat_lng':[],
                            'glowglobeId':''
                        }
                    };
                }
            },
            {
                'text':'Administration Level 2',
                onClick: ()=>{
                    adminMarker.current = {
                        'action':'administrationLevel',
                        'request':{
                            'level':2,
                            'lat_lng':[],
                            'glowglobeId':''
                        }
                    };
                }
            },
            {
                'text':'Administration Level 3',
                onClick: ()=>{
                    adminMarker.current = {
                        'action':'administrationLevel',
                        'request':{
                            'level':3,
                            'lat_lng':[],
                            'glowglobeId':''
                        }
                    };
                }
            }
        ]
        });


        // listen to vertexes being added to currently drawn layer (called workingLayer)
        map.on('pm:create', (e) => {
            if(e.shape === "administrationLevelSelector"){
                adminMarker.current.request.lat_lng = e.marker._latlng;
                adminMarker.current.request.glowglobeId = e.marker._leaflet_id;
                let text = e.marker._latlng.lat+','+e.marker._latlng.lng;
                e.marker.bindPopup(text);
                props.action(adminMarker.current);
            }
        });


        const datasetSelector = {
            name:'datasetSelector',
            block:'custom',
            className:'fad fa-bars fa-1x',
            title:'Browse/Select Dataset',
            toggle:	false,
            onClick: ()=>{
                props.action( {
                    'action':'enableDatasetSelector'
                })
            }
        }
        map.pm.Toolbar.createCustomControl(datasetSelector);

        const layerPanel = {
            name:'layerPanel',
            block:'custom',
            className:'fad fa-layer-group fa-1x',
            title:'Layer Panel',
            toggle:	false,
            onClick: ()=>{
                props.action( {
                    'action':'enableLayerPanel'
                })
            }
        }
        map.pm.Toolbar.createCustomControl(layerPanel);

        const polygonUpload = {
            name:'polygonUpload',
            block:'draw',
            className:'fad fa-upload fa-1x',
            title:'Upload Custom Polygon',
            toggle:	false,
            onClick: ()=>{
                props.action( {
                    'action':'enableUploadCustomPolygon'
                })
            }
        }
        map.pm.Toolbar.createCustomControl(polygonUpload);


        //loadLayers(sampleLayer);

    }, [map]);

    useEffect(() => {
        let currentIds = [];
        if(props.data.layers) {
            props.data.layers.forEach(
                (layer) => {
                    if(!layer.additionalAction) {
                        currentIds[layer.id] = true;
                    }
                }
            )
        }
        if(props.data.basemap) {
            currentIds[props.data.basemap.id] = true;
        }
        if(props.data.custom) {
            props.data.custom.forEach(
                (layer) => {
                    currentIds[layer.id] = true;
                }
            )
        }

        if(map){
            map.eachLayer(function (layer) {
                if(!currentIds[layer.options.glowglobeId]){
                    map.removeLayer(layer);
                }
            });
        }

        if(props.data.layers){
            props.data.layers.forEach(
                (layer) =>{
                    if(!layerIndices[layer.id]){
                        let temp = new L.tileLayer(layer.tileUrl, {
                            minZoom: 1,
                            maxZoom: 15,
                            basemap:false,
                            opacity: layer.opacity,
                            glowglobeId: layer.id
                        });
                        temp.setZIndex(5000).addTo(map);
                        map.spin(true);
                        map.dragging.disable();
                        map.touchZoom.disable();
                        map.doubleClickZoom.disable();
                        map.scrollWheelZoom.disable();
                        map.boxZoom.disable();
                        map.keyboard.disable();
                        temp.on("load",function() {
                            map.dragging.enable();
                            map.touchZoom.enable();
                            map.doubleClickZoom.enable();
                            map.scrollWheelZoom.enable();
                            map.boxZoom.enable();
                            map.keyboard.enable();
                            map.spin(false);
                        });
                    }else if(layer.additionalAction === 'update'){
                        let temp = new L.tileLayer(layer.tileUrl, {
                            minZoom: 1,
                            maxZoom: 15,
                            basemap:false,
                            opacity: layer.opacity,
                            glowglobeId: layer.id
                        });
                        temp.setZIndex(5000).addTo(map);
                        map.spin(true);
                        map.dragging.disable();
                        map.touchZoom.disable();
                        map.doubleClickZoom.disable();
                        map.scrollWheelZoom.disable();
                        map.boxZoom.disable();
                        map.keyboard.disable();
                        temp.on("load",function() {
                            map.dragging.enable();
                            map.touchZoom.enable();
                            map.doubleClickZoom.enable();
                            map.scrollWheelZoom.enable();
                            map.boxZoom.enable();
                            map.keyboard.enable();
                            map.spin(false);
                        });
                    }
                }
            )
        }

        if(props.data.basemap){
            if(!layerIndices[props.data.basemap.id]){
                let temp = new L.tileLayer(props.data.basemap.tileUrl, {
                    minZoom: 1,
                    maxZoom: 15,
                    basemap:true,
                    glowglobeId: props.data.basemap.id
                });
                temp.addTo(map);
            }
        }
        if(props.data.custom){
            props.data.custom.forEach(
                (geojson) =>{
                    if(!layerIndices[props.data.custom.id]){
                        let temp = new L.geoJSON(geojson.layer, {
                            minZoom: 1,
                            maxZoom: 15,
                            basemap:false,
                            opacity: geojson.opacity,
                            fillOpacity: geojson.opacity,
                            fillColor: geojson.color,
                            color: geojson.color,
                            id:geojson.id,
                            glowglobeId: props.data.custom.id,
                            onEachFeature: function (feature, layer) {
                                let div = document.createElement('div');
                                ReactDOM.render(popUp(geojson),div);
                                layer.bindPopup(div);
                            }
                        });
                        temp.setZIndex(5000).addTo(map);
                    }
                }
            )
        }

        setLayerIndices(currentIds);

    },[props.data]);

    useEffect(()=>{
        if(props.layerAction){
            if(props.layerAction.action === 'zoom'){
                map.eachLayer(
                    (layer)=>{
                        if(layer.options){
                            if(layer.options.id === props.layerAction.id){
                                if(layer._bounds){
                                    map.fitBounds(layer._bounds)
                                }
                            }
                        }
                    }
                )
            }else if(props.layerAction.action === 'bringToFront'){
                map.eachLayer(
                    (layer)=>{
                        if(layer.options){
                            if(layer.options.id === props.layerAction.id){
                                layer.bringToFront();
                            }
                        }
                    }
                )
            }else if(props.layerAction.action === 'bringToBack'){
                map.eachLayer(
                    (layer)=>{
                        if(layer.options){
                            if(layer.options.id === props.layerAction.id){
                                layer.bringToBack();
                            }
                        }
                    }
                )
            }
        }
    },[props.layerAction])

    return(
        <div className="large-map">
            <MapContainer zoom={2} center={position} scrollWheelZoom={true} whenCreated={setMap}>
                <Fullscreen
                    eventHandlers={{
                        enterFullscreen: (event) => console.log('entered fullscreen', event),
                        exitFullscreen: (event) => console.log('exited fullscreen', event),
                    }}
                    {...options}
                />
            </MapContainer>
        </div>
    )

}

