import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Tooltip} from "primereact/tooltip";

export const RelevantTable = (props) => {

    const [data, setData] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const urlPath =  '/#/asset/';

    useEffect(() => {
        if (props.dataRelevant) {
            setData(props.dataRelevant);
        }

    }, []);


    const imageBodyTemplate = (data) => {
        if(data.rights.access==="Open"){
            return <>
                <Tooltip target=".image-table"/>
                <img src={'assets/layout/images/open.png'} data-pr-tooltip={data.rights.access} className="image-table" alt=""/>
            </>;
        } else {
            return <>
                <Tooltip target=".image-table"/>
                <img src={'assets/layout/images/close.png'} data-pr-tooltip={data.rights.access} className="image-table" alt=""/>
            </>;
        }
    }

    const titleBodyTemplate = (data) => {
        return <div className="title-column">
            <span className="image-text"><a href={urlPath + data.dataNODE_id}>{data.en_title}</a></span>
        </div>;
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div>
                <div className="p-column-title">SUMMARY</div>
                <div style={{paddingTop:"10px",textAlign:"justify"}}>
                    {data.en_description}
                </div>
            </div>
        );
    }

    return (
        <div className="datatable-rowexpansion-demo">
                <DataTable value={data} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                           rowExpansionTemplate={rowExpansionTemplate} dataKey="dataNODE_id" >
                    <Column expander style={{ width: '3em' }} />
                    <Column field="en_title" body={titleBodyTemplate} style={{ fontSize: '16px' }} />
                    <Column field="rights.access" body={imageBodyTemplate}  style={{width: "150px", textAlign: "center"}} />

                </DataTable>
        </div>
    );
}
