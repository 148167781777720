import axios from 'axios';

export default class QvantumService {

    //Search GARDIAN Index
    async getResults(query,lazyParams) {

        let tempQuery = JSON.parse(JSON.parse(query).query);
        tempQuery.from = lazyParams.first;
        tempQuery.size = lazyParams.rows;
        tempQuery.track_total_hits = "true";

        if(lazyParams.sort_field){
            if(lazyParams.sort_field ==="release_year"){
                if(lazyParams.sort_order === -1){
                    let order = {
                        "order":"desc"
                    }
                    let sortQuery = {
                        "year":order
                    }
                    tempQuery.sort =[sortQuery]
                }else if(lazyParams.sort_order === 1){
                    let order = {
                        "order":"asc"
                    }
                    let sortQuery = {
                        "year":order
                    }
                    tempQuery.sort =[sortQuery]
                }
            }
            if(lazyParams.sort_field ==="category"){
                if(lazyParams.sort_order === -1){
                    let order = {
                        "order":"desc"
                    }
                    let sortQuery = {
                        "resource_type.category.keyword":order
                    }
                    tempQuery.sort =[sortQuery]
                }else if(lazyParams.sort_order === 1){
                    let order = {
                        "order":"asc"
                    }
                    let sortQuery = {
                        "resource_type.category.keyword":order
                    }
                    tempQuery.sort =[sortQuery]
                }
            }
            if(lazyParams.sort_field ==="access"){
                if(lazyParams.sort_order === -1){
                    let order = {
                        "order":"desc"
                    }
                    let sortQuery = {
                        "rights.access.keyword":order
                    }
                    tempQuery.sort =[sortQuery]
                }else if(lazyParams.sort_order === 1){
                    let order = {
                        "order":"asc"
                    }
                    let sortQuery = {
                        "rights.access.keyword":order
                    }
                    tempQuery.sort =[sortQuery]
                }
            }
            if(lazyParams.sort_field ==="title"){
                if(lazyParams.sort_order === -1){
                    let order = {
                        "order":"desc"
                    }
                    let sortQuery = {
                        "title.value.keyword":order
                    }
                    tempQuery.sort =[sortQuery]
                }else if(lazyParams.sort_order === 1){
                    let order = {
                        "order":"asc"
                    }
                    let sortQuery = {
                        "title.value.keyword":order
                    }
                    tempQuery.sort =[sortQuery]
                }
            }
        }

        if(lazyParams.filters.access){
            if(lazyParams.filters.access.value === "Limited"){
                let preterm = {
                    "rights.access.keyword":"Limited"
                }
                let term = {
                    "term":preterm
                }
                tempQuery.query.bool.filter = [term]
            }else if(lazyParams.filters.access.value === "Open"){
                let preterm = {
                    "rights.access.keyword":"Open"
                }
                let term = {
                    "term":preterm
                }
                tempQuery.query.bool.filter = [term]
            }

        }

        if(lazyParams.filters.category){
            if(Array.isArray(lazyParams.filters.category.value)){
                let category_filter = lazyParams.filters.category.value.map(
                    (item)=>{
                        let preterm = {
                            "resource_type.category.keyword":item
                        }

                        let term = {
                            "term":preterm
                        }
                        return term;

                    }
                )

                let should = {
                    "should":category_filter
                }

                let bool = {
                    "bool":should
                }

                if(tempQuery.query.bool.filter){
                    let _filter = tempQuery.query.bool.filter;
                    _filter.push(bool)
                    tempQuery.query.bool.filter = _filter;
                }else{
                    tempQuery.query.bool.filter = bool;
                }
            }
        }

        if(lazyParams.filters.release_year){
            if(lazyParams.filters.release_year.value!=='') {
                let preterm = {
                    "release_year.keyword": lazyParams.filters.release_year.value
                }
                let term = {
                    "term": preterm
                }

                if (tempQuery.query.bool.filter) {
                    let _filter = tempQuery.query.bool.filter;
                    _filter.push(term)
                    tempQuery.query.bool.filter = _filter;
                } else {
                    tempQuery.query.bool.filter = term;
                }
            }
        }

        if(lazyParams.filters.title){
            if(lazyParams.filters.title.value!=='') {
                let preterm = {
                    "title.value": lazyParams.filters.title.value
                }
                let match_phrase = {
                    "match_phrase": preterm
                }
                tempQuery.query.bool.must[1].bool.must.push(match_phrase)
            }

        }

        let countries = {
            terms:{
                field:"geography.countries.code_UNM49.keyword",
                size:200
            }
        }

        let providers = {
            terms:{
                field:"providers.full_name.keyword",
                size:200
            }
        }

        let projects = {
            terms:{
                field:"projects.full_name.keyword",
                size:200
            }
        }

        let funders =
            {
                filter:{
                    term:{
                        "funding_organisations.agent_ids.scheme.keyword":"ROR"
                    }
                },
                aggs:{
                    full_name:{
                        terms:{
                            field:"funding_organisations.full_name.keyword",
                            size:10
                        }
                    }
                }
        }

        let keywords = {
            terms:{
                field:"keywords.value.keyword",
                size:25
            }
        }

        let authors = {
            filter:{
                term:{
                    "authors.agent_ids.scheme.keyword":"ORCID"
                }
            },
            aggs:{
                full_name:{
                    terms:{
                        field:"authors.full_name.keyword",
                        size:10
                    }
                }
            }
        }



        let aggs = {
            countries,
            providers,
            projects,
            funders,
            keywords,
            authors
        }



        let finalQuery = {
            "query": JSON.stringify(tempQuery)
        }

        tempQuery.aggs = aggs;
        let config = {
            method: 'post',
            url: 'https://gardian-api.scio.services/api/query',
            headers: {
                'Content-Type': 'application/json'
            },
            data : tempQuery
        };

     return axios(config)
            .then(function (response) {
                return(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    //GET Document
    async getMetadata(data) {
        let id = data.id;
        let config = {
            method: 'get',
            url: 'https://gardian-api.scio.services/api/query/'+id,
            headers: {
                'Content-Type': 'application/json'
            }
        };


        return axios(config)
            .then(function (response) {
                console.log(response.data)
                return(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //GET GARDIAN Analytics
    async getAnalytics() {
        let config = {
            method: 'get',
            url: 'https://gardian-api.scio.services/api/analytics/gardian_summaries',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return axios(config)
            .then(function (response) {
                return(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //GET GARDIAN Homepage
    async getGardianSummary() {
        let config = {
            method: 'get',
            url: 'https://gardian-api.scio.services/api/analytics/gardian_homepage',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return axios(config)
            .then(function (response) {
                console.log(response.data);
                return(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //DEMO EXAMPLES
    getPubResults() {
        return axios.get('assets/demo/data/gardian_search_pubs_dump.json').then(res => res.data);
    }

    getDatasetResults() {
        return axios.get('assets/demo/data/gardian_search_datasets_dump.json').then(res => res.data);
    }


    getTreeTableNodes() {
        return axios.get('assets/demo/data/treetablenodes.json').then(res => res.data.root);
    }

    getTreeNodes() {
        return axios.get('assets/demo/data/treenodes.json').then(res => res.data.root);
    }

    getLayers() {
        return axios.get('assets/demo/data/layers.json').then(res => res.data);
    }

}
