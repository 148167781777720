import React, {useEffect,  useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {DynamicDivs} from "./DynamicDivs";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";

export const ResourceFilesTable = (props) => {
    const [data, setData] = useState(null);
    const [accessALL, setAccessALL] = useState(null);

    const [expandedRows, setExpandedRows] = useState(null);

    useEffect(() => {
        if (props.dataResource) {
            setData(props.dataResource);
        }

        if ((props.ResAccess)&&(props.ResAccess !=="")) {
             setAccessALL(props.ResAccess);
        }




    }, []);

    const renderComponent =  () => {

        const iconBodyTemplate = (data) => {

            let newDivList = [];
                if(data.locked === false){

                    newDivList.push(
                        <div>
                            <span style={{color: "#457fca"}}>
                                <Tooltip target=".view-icon"/>
                                <i className="fad fa-lock-open-alt fa-2x view-icon" data-pr-tooltip="Accessible" ></i>
                            </span>
                        </div>);


                }else{
                    newDivList.push( <div><span style={{color: "#f58211"}}><Tooltip target=".locked-icon"/><i className="fad fa-user-lock fa-2x locked-icon" data-pr-tooltip="Locked file" ></i></span></div>);
                }
            return  newDivList;
        }

        const TypeTemplate = (data) => {

            return (

                <React.Fragment>
                    <span className="p-column-title">Type</span>
                    {data.mime_type}
                </React.Fragment>
            )
        }


        const FixFileName = (filename) => {

            if (filename.length > 50) {

                return (filename.substring(0,50)+' ...')


            }
            else {
                 return (filename)
            }

        }


        const FileNameTemplate = (data) => {

            return (

            <React.Fragment>
                <span className="p-column-title">File Name</span>
                {FixFileName(data.filename)}
            </React.Fragment>

            )

        }

        const rowExpansionTemplate = (data) => {
            return (

                <>
                    {
                        ((data.description)&&(data.description!==''))?
                            <div style={{paddingBottom:"12px"}}>
                                {data.description}
                            </div>
                            :console.log()
                    }
                    <DynamicDivs type="resourceUrl" data={data}></DynamicDivs>

                </>

            );
        }



        const header = <div className="p-grid">

            <div className="p-col p-d-flex p-jc-end">
                {
                    ((accessALL)&&(accessALL!==''))?
                        <a href={accessALL} target="_blank" rel="noreferrer">
                            <Button label="Download All Files" icon="fad fa-file-download fa-lg" className="exportButton" style={{marginRight: "8px"}} />
                        </a>
                        :console.log()
                }

            </div>
        </div>


       return   <div className="datatable-responsive">
           <DataTable value={data} header={header} className="p-datatable-responsive" expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                      rowExpansionTemplate={rowExpansionTemplate} dataKey="filename" >

               <Column expander style={{ width: '3em' }} />
               <Column field="filename"  className="datatable-NameColumn" header="File Name" body={FileNameTemplate} style={{fontSize: "15px"}} />
               {/*<Column field="type"  header="Type" body={TypeTemplate} style={{width: "200px",fontSize: "15px"}} />*/}
               <Column className="datatable-viewColumn" body={iconBodyTemplate} style={{width: "150px",fontSize: "15px", textAlign: "center"}} />

           </DataTable>
                </div>
    }

    return (
        <div className="datatable-rowexpansion-demo">
            {
               data?renderComponent():console.log()
            }
        </div>
    );
}
