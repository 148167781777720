import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"

export const APIs = () => {


    return (
        <>
            <div>
                <div className="fair-layout-content">

                    <div className="p-grid">
                        <div className="p-col">
                            <div className="card analytics-card">
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6" style={{paddingRight:"24px",paddingLeft:"24px"}}>
                                        <h2 className="h2-title">GARDIAN TOOLS</h2>
                                        <div style={{paddingTop:"10px"}}>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                One of the grand challenges of data-intensive science is to facilitate knowledge
                                                discovery by assisting humans and machines in their discovery of, access to, integration and analysis of, task-appropriate scientific
                                                data and their associated algorithms and workflows.
                                            </p>

                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                The <a target="_blank"  rel="noreferrer" href="https://www.force11.org/group/fairgroup/fairprinciples">FAIR Data Principles</a> is a set of guiding
                                                principles to make data Findable, Accessible, Interoperable, and Reusable. However, those principles are not orthogonal and have not been
                                                designed for automated machine-based evaluation. To this end, we have adopted the Netherlands Institute for Permanent Access to Digital
                                                Research Resources (DANS) <a target="_blank" rel="noreferrer" href="https://planeurope.files.wordpress.com/2015/03/doorn-fair-interoperability-poznan-plan-e-april-2017.pdf">metrics
                                                for FAIR compliance</a>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-col" style={{paddingRight:"24px",paddingLeft:"24px"}}>
                                        <h2 className="h2-title">RELEVANT STANDARDS</h2>
                                        <div style={{paddingTop:"10px",textAlign:"left",fontSize:"18px"}}>
                                            <div>
                                                <i className="fad fa-link"></i> <a target="_blank" rel="noreferrer" href="https://github.com/AgriculturalSemantics/cg-core">CG Core metadata schema</a>
                                                <p style={{fontSize:"16px", textAlign:"justify", marginTop:"10px"}}>
                                                    The CG Core metadata schema is a minimum set of metadata elements, closely aligned with Dublin Core. CG Core facilitates data discovery,
                                                    meta-searching and indexing across CGIAR repositories and inter-linking across related resources (e.g. data with publications). It is openly available with
                                                    a <a href="https://agriculturalsemantics.github.io/cg-core/cgcore.html"  target="_blank" rel="noreferrer">reference guide</a> to help users understand and apply it.
                                                </p>
                                            </div>

                                            <div style={{paddingTop:"15px"}}>
                                                <i className="fad fa-link"></i> <a target="_blank" rel="noreferrer" href="https://bigdata.cgiar.org/resources/agronomy-ontology/">Agronomy Ontology (AgrO)</a>
                                                <p style={{fontSize:"16px", textAlign:"justify", marginTop:"10px"}}>
                                                    AgrO includes terms from the agronomy domain that are semantically organized and can facilitate the collection, storage and use of agronomic data. It enables
                                                    easy interpretation, aggregation, and reuse of the data by humans and machines alike.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="p-grid">
                        <div className="p-col">
                            <div className="card analytics-card">
                                <div className="p-grid">
                                    <div className="p-col">
                                        {/*<h2 className="h2-title">GARDIAN TOOLS</h2>*/}
                                        <div style={{paddingTop:"10px"}}>

                                            <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    );

}
