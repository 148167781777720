import React, {useEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";


export const Am_DonutChart = (props) => {

    useEffect(() => {

        console.log(props);

        const kFormatter = (num) => {
            return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K' : Math.sign(num)*Math.abs(num)
        }

        const stringDivider = (str, width, spaceReplacer) => {

            if (!str.includes(spaceReplacer)) {

                if (str.length>width) {
                    let p=width
                    for (;p>0 && str[p]!==' ';p--) {
                    }
                    if (p>0) {
                        let left = str.substring(0, p);
                        let right = str.substring(p+1);
                        return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
                    }
                }

            }

            return str;
        }

        //-----------------------
        am4core.addLicense("CH289451215");
        am4core.useTheme(am4themes_animated);
        //-----------------------
        let chart = am4core.create(props.chartId, am4charts.PieChart);
        //-----------------------

        // Set inner radius
        chart.innerRadius = am4core.percent(40);

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "frequency";
        pieSeries.dataFields.category = "value";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        // pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.#')}% ({value.value}) \n\n (click to filter)";

        pieSeries.slices.template.adapter.add('tooltipText', function(text, target) {

            if (props.useNAME === "true") {

                let data = target.tooltipDataItem.dataContext;

                let wrap_name = "Other";

                if ((data.name)&&(data.name!=="")) {
                    wrap_name = stringDivider(data.name, 20, "\n");
                }

                if ((props.callback)&&(wrap_name!=="Other")) {
                    return "[bold]{value.percent.formatNumber('#.#')}% ({value.value})[/] \n" + wrap_name + "\n\n (click to filter)";
                }else{
                    return "[bold]{value.percent.formatNumber('#.#')}% ({value.value})[/] \n" + wrap_name;
                }

            }else{

                let data = target.tooltipDataItem.dataContext;

                if (props.WordWrap) {

                    let wrap_name = "Other";

                    if ((data.value)&&(data.value!=="")) {
                        wrap_name = stringDivider(data.value, props.WordWrap, "\n");
                    }

                    if ((props.callback)&&(wrap_name!=="Other")) {
                        return "[bold]{value.percent.formatNumber('#.#')}% ({value.value})[/] \n" + wrap_name + "\n\n (click to filter)";
                    }else{
                        return "[bold]{value.percent.formatNumber('#.#')}% ({value.value})[/] \n" + wrap_name;
                    }

                }else{

                    if ((props.callback)&&((data.value)&&(data.value!==""))) {
                        return "[bold]{value.percent.formatNumber('#.#')}% ({value.value})[/] \n{category}\n\n (click to filter)";
                    }else{
                        return "[bold]{value.percent.formatNumber('#.#')}% ({value.value})[/] \n{category}";
                    }

                }

            }

        });

        if(props.callback) {

            pieSeries.slices.template.events.on("hit", function(ev) {

                if (ev.target.dataItem.category!=="Other") {

                    let resultCHARTClick = {
                        term: ev.target.dataItem.category,
                        type: props.filterType
                    };

                    if (props.useVOC === "true") {

                        resultCHARTClick = {
                            term: ev.target.dataItem.dataContext.voc_code,
                            type: props.filterType
                        };
                    }

                    if(props.callback){
                        props.callback(resultCHARTClick);
                    }

                }

            });

        }


        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        if ((props.showLEGEND)&&(props.showLEGEND === "true")) {
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
            chart.legend = new am4charts.Legend();
            chart.legend.position = "bottom";
        }

        let grouper = pieSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        grouper.clickBehavior = "break";
        // //grouper.threshold = 7;
        grouper.limit = props.showNUM;

        let datax = props.chartData;

        if (props.WordWrap) {

            for (let i = 0; i < datax.length-1; i++) {
                datax[i].value = stringDivider(datax[i].value, props.WordWrap, "\n");
            }
        }

        if (props.useTotalTXT) {

            let TotalSum = 0;

            for (let i = 0; i < datax.length-1; i++) {
                TotalSum += datax[i].frequency;
            }


            let label1 = chart.createChild(am4core.Label);
            label1.text = kFormatter(TotalSum);
            label1.fontSize = 20;
            label1.align = "center";
            label1.isMeasured = false;
            label1.horizontalCenter = "middle";
            label1.VerticalCenter = "middle";
            label1.x = am4core.percent(50);
            label1.y = am4core.percent(40);

            let label2 = chart.createChild(am4core.Label);
            label2.text = props.useTotalTXT;
            label2.fontSize = 16;
            label2.align = "center";
            label2.isMeasured = false;
            label2.horizontalCenter = "middle";
            label2.VerticalCenter = "middle";
            label2.x = am4core.percent(50);
            label2.y = am4core.percent(50);

        }

        chart.data = datax;

    }, [props.filter_query, props.chartData]);



    if (props.chartTitle) {
        return (
            <div style={{textAlign:"center"}}>
                <h4 style={{margin: "0px"}}>{props.chartTitle}</h4>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }else{
        return (
            <div>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }

}
