import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {Fieldset} from "primereact/fieldset";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Divider} from "primereact/divider";

export const SearchGuide = props => {

    const [displayBasic, setDisplayBasic] = useState(false);

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    let history = useHistory();


    let operatorDATA = [
        { "metadataField": "Any Field", "operator": "contains" },
        { "metadataField": "Any Field", "operator": "does not contain" },
        //----------------
        { "metadataField": "Title", "operator": "contains" },
        { "metadataField": "Title", "operator": "does not contain" },
        //----------------
        { "metadataField": "Description", "operator": "contains" },
        { "metadataField": "Description", "operator": "does not contain" },
        //----------------
        { "metadataField": "Controlled Term", "operator": "is" },
        { "metadataField": "Controlled Term", "operator": "is not" },
        //----------------
        { "metadataField": "Other Keyword", "operator": "is" },
        { "metadataField": "Other Keyword", "operator": "is not" },
        //----------------
        { "metadataField": "Region", "operator": "is" },
        { "metadataField": "Region", "operator": "is not" },
        //----------------
        { "metadataField": "Country", "operator": "is" },
        { "metadataField": "Country", "operator": "is not" },
        //----------------
        { "metadataField": "Provider", "operator": "is" },
        { "metadataField": "Provider", "operator": "is not" },
        //----------------
        { "metadataField": "Author", "operator": "is" },
        { "metadataField": "Author", "operator": "contains" },
        //----------------
        { "metadataField": "Release Year", "operator": "is" },
        { "metadataField": "Release Year", "operator": "is not" },
        { "metadataField": "Release Year", "operator": "is greater than or equal to" },
        { "metadataField": "Release Year", "operator": "is less than or equal to" },
        //----------------
        { "metadataField": "Temporal Coverage", "operator": "is" },
        { "metadataField": "Temporal Coverage", "operator": "is not" },
        { "metadataField": "Temporal Coverage", "operator": "is greater than or equal to" },
        { "metadataField": "Temporal Coverage", "operator": "is less than or equal to" },
        //----------------
        { "metadataField": "Funder / Investor", "operator": "is" },
        { "metadataField": "Funder / Investor", "operator": "contains" },
        //----------------
        { "metadataField": "Project / Platform", "operator": "is" },
        { "metadataField": "Project / Platform", "operator": "is not" }
    ];


    let example1query = {"user_query":"pp","json_query":{"rules":[{"field":"allFields","value":"pp","operator":"contains"}],"combinator":"and","not":false}};

    const runExample1 = () => {
        let tempQP = "/search-results/0/{%22user_query%22:%22%22,%22json_query%22:{%22rules%22:[{%22field%22:%22controlledTerms%22,%22value%22:%22maize%22,%22operator%22:%22=%22},{%22field%22:%22region%22,%22value%22:%22UNM49:005%22,%22operator%22:%22=%22}],%22combinator%22:%22and%22,%22not%22:false}}"
        //-------------------------------
        setDisplayBasic(false);
        history.push(tempQP);
    }

    const runExample2 = () => {
        let tempQP ="/search-results/0/{%22user_query%22:%22%22,%22json_query%22:{%22rules%22:[{%22field%22:%22controlledTerms%22,%22value%22:%22food%20security%22,%22operator%22:%22=%22},{%22field%22:%22provider%22,%22value%22:%22IFPRI%22,%22operator%22:%22=%22},{%22field%22:%22funder%22,%22value%22:%22World%20Bank%20Group%20(WB)%22,%22operator%22:%22=%22}],%22combinator%22:%22and%22,%22not%22:false}}"
        //-------------------------------
        setDisplayBasic(false);
        history.push(tempQP);
    }

    const runExample3 = () => {
        let tempQP ="/search-results/0/{%22user_query%22:%22%22,%22json_query%22:{%22rules%22:[{%22field%22:%22allFields%22,%22value%22:%22oryza%20sativa%22,%22operator%22:%22contains%22},{%22rules%22:[{%22field%22:%22provider%22,%22value%22:%22AfricaRice%22,%22operator%22:%22=%22},{%22field%22:%22provider%22,%22value%22:%22IRRI%22,%22operator%22:%22=%22}],%22combinator%22:%22or%22,%22not%22:false},{%22rules%22:[{%22field%22:%22country%22,%22value%22:%22UNM49:050%22,%22operator%22:%22=%22},{%22field%22:%22country%22,%22value%22:%22UNM49:356%22,%22operator%22:%22=%22},{%22field%22:%22country%22,%22value%22:%22UNM49:608%22,%22operator%22:%22=%22}],%22combinator%22:%22or%22,%22not%22:false}],%22combinator%22:%22and%22,%22not%22:false}}"
        //-------------------------------
        setDisplayBasic(false);
        history.push(tempQP);
    }




    return (
        <>

            <Button icon="pi pi-book" onClick={() => onClick('displayBasic')} className="search-btn" label="Guide" style={{width:"100px", backgroundColor:"#009688"}}></Button>

            <Dialog header="ADVANCED SEARCH GUIDE" blockScroll modal maximizable visible={displayBasic} position='center' style={{ width: '90vw' }}  onHide={() => onHide('displayBasic')}>


                <div className="p-grid">
                    <div className="p-col">
                        <div className="card analytics-card">
                            <div className="p-grid">
                                <div className="p-col">
                                    <div className="hg-title">RULE clauses</div>

                                    <div style={{fontSize:"15px", textAlign:"justify", paddingTop:"20px"}}>
                                        GARDIAN’s Advanced Search allows the refinement of your search via the definition of <b>Rule Clauses</b> over metadata fields supported by the GARDIAN model and used as the search’s criteria.
                                        The rules follow the general pattern:
                                    </div>
                                    <div style={{fontSize:"20px", textAlign:"center", paddingBottom:"20px", paddingTop:"20px"}}>
                                        [metadata field] &lt;operator&gt; “value”
                                    </div>
                                    <div style={{fontSize:"15px", textAlign:"justify"}}>
                                        with the operators supported for each metadata field presented in the following table.
                                    </div>

                                    <Fieldset className="fieldset-advanced-search-guide" legend="Operators per Metadata Field" toggleable collapsed>
                                        <div style={{paddingTop:"10px"}}>
                                            <DataTable value={operatorDATA} rowGroupMode="rowspan" groupRowsBy="metadataField"
                                                       sortMode="single" sortField="metadataField" sortOrder={1} responsiveLayout="scroll"
                                                       style={{maxWidth:"650px", margin:"auto"}}>
                                                <Column field="metadataField" header="METADATA FIELD" style={{textAlign:"center"}}></Column>
                                                <Column field="operator" header="OPERATOR" style={{textAlign:"center"}}></Column>
                                            </DataTable>
                                        </div>
                                    </Fieldset>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="p-grid">
                    <div className="p-col">
                        <div className="card analytics-card">
                            <div className="p-grid">
                                <div className="p-col">
                                    <div className="hg-title">GROUP clauses</div>
                                    <div style={{fontSize:"15px", textAlign:"justify", paddingTop:"20px"}}>
                                        Rule clauses are organised in <b>Groups</b>, with each group connecting its Rule clauses via the
                                        application of an AND or OR logical operator between all rules belonging to the group.
                                    </div>
                                    <div style={{fontSize:"15px", textAlign:"justify", paddingTop:"20px"}}>
                                        Group clauses function as a single, independent unit within a query and can be enclosed in other groups.
                                        In this sense, grouping clauses is similar to putting parentheses around a mathematical equation or logic expression.
                                    </div>
                                    <div style={{fontSize:"15px", textAlign:"justify", paddingTop:"20px"}}>
                                        GARDIAN’s advanced search starts with an upper-level group. This
                                        upper-level group contains all the Rule clauses or Group clauses that need to be defined.
                                        The upper-level group uses by default an AND logical operator that can be cahnged by the user.
                                    </div>
                                    <div style={{fontSize:"15px", textAlign:"justify", paddingTop:"20px"}}>
                                        The following examples present how the logical expression corresponding to a query can be defined in the form of grouped clauses.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                {/*---------------------------------------------  EXAMPLE 1   */}

                <div className="p-grid">
                    <div className="p-col">
                        <div className="card analytics-card">
                            <div className="hg-title">Query Example #1</div>

                            <div style={{fontSize:"20px", textAlign:"center", paddingBottom:"10px", paddingTop:"30px"}}>
                                "I want to find all the resources about maize cultivated in South America"
                            </div>

                            <div className="card analytics-card p-shadow-3" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6">
                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <div className="hg-subtitle">QUERY LOGICAL EXPRESSION</div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( Controlled Term = “<span style={{color:"red"}}>maize</span>” ) <span style={{fontWeight:"700"}}>AND</span>
                                                    </div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( Region = “<span style={{color:"red"}}>South America</span>” )
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <div className="hg-subtitle">GROUPED CLAUSES for SEARCH QUERY</div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingBottom:"0px"}}>
                                                        <span style={{color:"blue", fontWeight:"700"}}>GROUP</span> [<span style={{fontWeight:"700"}}>AND</span> operator]
                                                    </div>
                                                    <div className="p-grid">
                                                        <div className="p-col-1">
                                                            <Divider layout="vertical" style={{background:"blue", width:"2px"}}>
                                                            </Divider>
                                                        </div>
                                                        <div className="p-col-11">
                                                            <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                <span style={{color:"green"}}>RULE:</span> [ Controlled Term ] is “<span style={{color:"red"}}>maize</span>”
                                                            </div>
                                                            <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                <span style={{color:"green"}}>RULE:</span> [ Region ] is “<span style={{color:"red"}}>South America</span>” )
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div style={{paddingTop:"20px"}}>
                                            <center>
                                                <Button label="Load Search Query" className="p-button-outlined p-button-secondary" onClick={(e) => runExample1()}/>
                                            </center>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/*---------------------------------------------  EXAMPLE 2   */}



                <div className="p-grid">
                    <div className="p-col">
                        <div className="card analytics-card">
                            <div className="hg-title">Query Example #2</div>

                            <div style={{fontSize:"20px", textAlign:"center", paddingBottom:"10px", paddingTop:"30px"}}>
                                "I want to find all the resources on food security produced by IFPRI and funded by the World Bank"
                            </div>

                            <div className="card analytics-card p-shadow-3" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6">

                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <div className="hg-subtitle">QUERY LOGICAL EXPRESSION</div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( Controlled Term = “<span style={{color:"red"}}>food security</span>” ) <span style={{fontWeight:"700"}}>AND</span>
                                                    </div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( Provider = “<span style={{color:"red"}}>IFPRI</span>” ) <span style={{fontWeight:"700"}}>AND</span>
                                                    </div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( Funder = “<span style={{color:"red"}}>World Bank Group (WB)</span>” )
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <div className="hg-subtitle">GROUPED CLAUSES for SEARCH QUERY</div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingBottom:"0px"}}>
                                                        <span style={{color:"blue", fontWeight:"700"}}>GROUP</span> [<span style={{fontWeight:"700"}}>AND</span> operator]
                                                    </div>
                                                    <div className="p-grid">
                                                        <div className="p-col-1">
                                                            <Divider layout="vertical" style={{background:"blue", width:"2px"}}>
                                                            </Divider>
                                                        </div>
                                                        <div className="p-col-11">
                                                            <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                <span style={{color:"green"}}>RULE:</span> [ Controlled Term ] is “<span style={{color:"red"}}>food security</span>”
                                                            </div>
                                                            <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                <span style={{color:"green"}}>RULE:</span> [ Provider ] is “<span style={{color:"red"}}>IFPRI</span>” )
                                                            </div>
                                                            <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                <span style={{color:"green"}}>RULE:</span> [ Funder ] is “<span style={{color:"red"}}>World Bank Group (WB)</span>” )
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div style={{paddingTop:"20px"}}>
                                            <center>
                                                <Button label="Load Search Query" className="p-button-outlined p-button-secondary" onClick={(e) => runExample2()}/>
                                            </center>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/*---------------------------------------------  EXAMPLE 3   */}

                <div className="p-grid">
                    <div className="p-col">
                        <div className="card analytics-card">
                            <div className="hg-title">Query Example #3</div>

                            <div style={{fontSize:"20px", textAlign:"center", paddingBottom:"10px", paddingTop:"30px"}}>
                                "I want to find all the resources about oryza sativa done by Africa Rice Center or IRRI in Bangladesh, India and Philippines"
                            </div>

                            <div className="card analytics-card p-shadow-3" >
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6">

                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <div className="hg-subtitle">QUERY LOGICAL EXPRESSION</div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( Any Field = “<span style={{color:"red"}}>oryza sativa</span>” ) <span style={{fontWeight:"700"}}>AND</span>
                                                    </div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( ( Provider = “<span style={{color:"red"}}>Africa Rice Center</span>” ) <span style={{fontWeight:"700"}}>OR </span>
                                                          ( Provider = “<span style={{color:"red"}}>IRRI</span>” ) ) <span style={{fontWeight:"700"}}>AND</span>
                                                    </div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                        ( ( Country = “<span style={{color:"red"}}>Bangladesh</span>” ) <span style={{fontWeight:"700"}}>OR </span>
                                                          ( Country = “<span style={{color:"red"}}>India</span>” ) <span style={{fontWeight:"700"}}>OR </span>
                                                         ( Country = “<span style={{color:"red"}}>Philippines</span>” ) )
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <div className="p-grid">
                                                <div className="p-col">
                                                    <div className="hg-subtitle">GROUPED CLAUSES for SEARCH QUERY</div>
                                                    <div style={{fontSize:"15px", textAlign:"left", paddingBottom:"0px"}}>
                                                        <span style={{color:"blue", fontWeight:"700"}}>GROUP</span> [<span style={{fontWeight:"700"}}>AND</span> operator]
                                                    </div>
                                                    <div className="p-grid">
                                                        <div className="p-col-1">
                                                            <Divider layout="vertical" style={{background:"blue", width:"2px"}}>
                                                            </Divider>
                                                        </div>
                                                        <div className="p-col-11">
                                                            <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                <span style={{color:"green"}}>RULE:</span> [ Any Field ] contains “<span style={{color:"red"}}>oryza sativa</span>”
                                                            </div>

                                                            {/* ----------------------------------------------------- */}

                                                            <div>
                                                                <div style={{fontSize:"15px", textAlign:"left", paddingBottom:"0px", paddingTop:"6px"}}>
                                                                    <span style={{color:"blue", fontWeight:"700"}}>GROUP</span> [<span style={{fontWeight:"700"}}>OR</span> operator]
                                                                </div>
                                                                <div className="p-grid">
                                                                    <div className="p-col-1">
                                                                        <Divider layout="vertical" style={{background:"blue", width:"2px"}}>
                                                                        </Divider>
                                                                    </div>
                                                                    <div className="p-col-11">
                                                                        <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                            <span style={{color:"green"}}>RULE:</span> [ Provider ] is “<span style={{color:"red"}}>Africa Rice Center</span>” )
                                                                        </div>
                                                                        <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                            <span style={{color:"green"}}>RULE:</span> [ Funder ] is “<span style={{color:"red"}}>IRRI</span>” )
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* ----------------------------------------------------- */}

                                                            <div>
                                                                <div style={{fontSize:"15px", textAlign:"left", paddingBottom:"0px", paddingTop:"6px"}}>
                                                                    <span style={{color:"blue", fontWeight:"700"}}>GROUP</span> [<span style={{fontWeight:"700"}}>OR</span> operator]
                                                                </div>
                                                                <div className="p-grid">
                                                                    <div className="p-col-1">
                                                                        <Divider layout="vertical" style={{background:"blue", width:"2px"}}>
                                                                        </Divider>
                                                                    </div>
                                                                    <div className="p-col-11">
                                                                        <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                            <span style={{color:"green"}}>RULE:</span> [ Country ] is “<span style={{color:"red"}}>Bangladesh</span>”
                                                                        </div>
                                                                        <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                            <span style={{color:"green"}}>RULE:</span> [ Country ] is “<span style={{color:"red"}}>India</span>” )
                                                                        </div>
                                                                        <div style={{fontSize:"15px", textAlign:"left", paddingTop:"6px"}}>
                                                                            <span style={{color:"green"}}>RULE:</span> [ Country ] is “<span style={{color:"red"}}>Philippines</span>” )
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{paddingTop:"20px"}}>
                                            <center>
                                                <Button label="Load Search Query" className="p-button-outlined p-button-secondary" onClick={(e) => runExample3()}/>
                                            </center>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>





            </Dialog>
        </>

    );
}
