import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";
import {Am_DonutChart} from "../components/am_DonutChart";
import {Am_HBarChart} from "../components/am_HBarChart";
import {Dropdown} from "primereact/dropdown";
import {CenterAnalytics} from "../components/CenterAnalytics";
import {RepoAnalytics} from "../components/RepoAnalytics";
import QvantumService from "../service/QvantumService";




export const Analytics = () => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [dataPublications, setDataPublications] = useState(null);
    const [dataDatasets, setDataDatasets] = useState(null);
    const [dataRepositories, setDataRepositories] = useState(null);

    const [selectedPROVIDER, setSelectedPROVIDER] = useState(null );
    const [selectedREPO, setSelectedREPO] = useState(null );

    const [viewProviderOverview, setViewProviderOverview] = useState(true);
    const [viewRepoOverview, setViewRepoOverview] = useState(true);

    const PROVIDERS = [
        {name: "CGIAR", label: "CGIAR - Consortium of International Agricultural Research Centers"},
        {name: "AfricaRice", label: "Africa Rice Center"},
        {name: "Bioversity", label: "Bioversity International"},
        {name: "Bioversity-CIAT", label: "Bioversity-CIAT Alliance"},
        {name: "CIAT", label: "CIAT - International Center for Tropical Agriculture"},
        {name: "CIFOR", label: "CIFOR - Center for International Forestry Research"},
        {name: "CIMMYT", label: "CIMMYT - International Maize and Wheat Improvement Center"},
        {name: "CIP", label: "CIP - International Potato Center"},
        {name: "ICARDA", label: "ICARDA - International Center for Agricultural Research in the Dry Areas"},
        {name: "ICRAF", label: "ICRAF - World Agroforestry Centre"},
        {name: "ICRISAT", label: "ICRISAT - International Crops Research Institute for the Semi-Arid Tropics"},
        {name: "IFPRI", label: "IFPRI - International Food Policy Research Institute"},
        {name: "IITA", label: "IITA - International Institute of Tropical Agriculture"},
        {name: "ILRI", label: "ILRI - International Livestock Research Institute"},
        {name: "IRRI", label: "IRRI - International Rice Research Institute"},
        {name: "IWMI", label: "IWMI - International Water Management Institute"},
        {name: "WorldFish", label: "WorldFish"},
        //-----------------
        {name: "CGIAR-CRP-PTF", label: "CRPs/PTFs - CGIAR Research Programs and Platforms"},
        {name: "CGIAR-SYSTEM", label: "CGIAR System"},
        //-----------------
        {name: "CTA", label: "CTA - Technical Centre for Agricultural and Rural Cooperation"},
        {name: "IndiaGOV", label: "Government of India (IndiaGOV)"},
        {name: "ICAR", label: "ICAR - Indian Council of Agricultural Research"},
        {name: "IGAD", label: "IGAD - Intergovernmental Authority on Development"},
        {name: "PABRA", label: "PABRA - Pan-Africa Bean Research Alliance"},
        {name: "FCDO", label: "UK Foreign, Commonwealth & Development Office (FCDO)"},
        {name: "USAID-FTF", label: "USAID - Feed the Future"},
        //-----------------
        {name: "WB", label: "World Bank Group (WB)"}

    ];

    const REPOSITORIES = [
        // {name: "Bioversity Library", label: "Bioversity Library"},
        {name: "CGSpace", label: "CGSpace - Repository of Agricultural Research Outputs"},
        {name: "CIFOR Dataverse", label: "CIFOR Dataverse"},
        // {name: "CIFOR Knowledge", label: "CIFOR Knowledge Portal"},
        {name: "CIMMYT Publications Repository", label: "CIMMYT Publications Repository"},
        {name: "CIMMYT Research Data & Software Repository", label: "CIMMYT Research Data & Software Repository"},
        {name: "CIP Dataverse", label: "CIP Dataverse"},
        {name: "GOV.UK", label: "GOV.UK"},
        {name: "HARVARD Dataverse", label: "HARVARD Dataverse"},
        {name: "ICRAF Research Data Repository", label: "ICRAF Research Data Repository"},
        {name: "ICRAF Web Portal", label: "ICRAF Web Portal"},
        {name: "ICRISAT Dataverse", label: "ICRISAT Dataverse"},
        {name: "ICRISAT Library", label: "ICRISAT Library"},
        {name: "IFPRI Library", label: "IFPRI Library"},
        {name: "IITA CKAN", label: "IITA CKAN"},
        // {name: "IITA Publications Database", label: "IITA Publications Database"},
        // {name: "ILRI Datasets Portal", label: "ILRI Datasets Portal"},
        // {name: "IRRI-FHSD", label: "IRRI - Farm Household Survey Database (FHSD)"},
        {name: "IRRI Library", label: "IRRI Library"},
        {name: "KRISHI", label: "KRISHI"},
        {name: "MELDATA", label: "MELDATA"},
        {name: "MELSpace", label: "MELSpace"},
        {name: "Open Government Data (OGD) Platform India", label: "Open Government Data (OGD) Platform India"},
        {name: "USAID Development Data Library (DDL)", label: "USAID Development Data Library (DDL)"},
        {name: "Water Data Portal", label: "Water Data Portal"},
        {name: "WorldFish Repository", label: "WorldFish Repository"},
        //-----------------
        {name: "Microdata Library", label: "Microdata Library"}
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    let params = useParams();


    useEffect(() => {
        const analyticsService = new QvantumService();
        analyticsService.getAnalytics().then(data => setAnalyticsData(data));

    }, []);

    useEffect(() => {
        let tabindex = 0;

        if (params.tab) {

            if ((parseInt(params.tab)) && (parseInt(params.tab)<3)) {
                tabindex = parseInt(params.tab);
            }
        }
        else {
            setSelectedPROVIDER(null);
            loadProviderAnalyticsData(null);
            //-------------------------
            setSelectedREPO(null);
            loadRepoAnalyticsData(null);

        }

        setActiveIndex(tabindex);

    }, [params.tab]);

    useEffect(() => {

        if (params.prov) {

            let SelProv = null;

            PROVIDERS.forEach(item  => {

                if (item.name === params.prov) {
                    SelProv = item;
                }
            })

            REPOSITORIES.forEach(item  => {

                if (item.name === params.prov) {
                    SelProv = item;
                }
            })

            //------------------- Datasets
            if ((params.tab)&&(params.tab === "0")) {
                if (SelProv) {
                    setSelectedPROVIDER(SelProv);
                    //-------------------------
                    setViewProviderOverview(false);
                    loadProviderAnalyticsData(SelProv.name);
                }
            }

            //------------------- Documents / Digital Assets
            if ((params.tab)&&(params.tab === "1")) {
                if (SelProv) {
                    setSelectedPROVIDER(SelProv);
                    //-------------------------
                    setViewProviderOverview(false);
                    loadProviderAnalyticsData(SelProv.name);
                }
            }

            //------------------- Sources (Repositories)
            if ((params.tab)&&(params.tab === "2")) {
                if (SelProv) {
                    setSelectedREPO(SelProv);
                    //-------------------------
                    setViewRepoOverview(false);
                    loadRepoAnalyticsData(SelProv.name);
                }

            }

        }

    }, [analyticsData, params.prov]);





    const loadProviderAnalyticsData = (PROVIDERname) => {

        let dataP = null;
        let dataD = null;
        //-------------------------



        if ((analyticsData)&&(analyticsData.publications)) {
            for (let i = 0; i < analyticsData.publications.length; i++) {
                if (analyticsData.publications[i].provider === PROVIDERname) {  dataP = {...analyticsData.publications[i]} ; }
            }
        }
        //-------------------------
        if ((analyticsData)&&(analyticsData.datasets)) {
            for (let i = 0; i < analyticsData.datasets.length; i++) {


                if (analyticsData.datasets[i].provider === PROVIDERname) {  dataD = {...analyticsData.datasets[i] }; }


            }
        }

        //console.log(analyticsData)

        //-------------------------
        setDataPublications(dataP);
        setDataDatasets(dataD);

    }

    const loadRepoAnalyticsData = (REPOSITORYRname) => {



        let dataR = null;
        //-------------------------
        if ((analyticsData)&&(analyticsData.sources)) {
            for (let i = 0; i < analyticsData.sources.length; i++) {
                if (analyticsData.sources[i].system === REPOSITORYRname) {  dataR = {...analyticsData.sources[i]} ; }
            }
        }
        //-------------------------

        setDataRepositories(dataR);
    }


    const onProviderChange = (e: { value: any}) => {
        setSelectedPROVIDER(e.value);

        //-------------------------
        loadProviderAnalyticsData(e.value.name);
    }


    const onRepoChange = (e: { value: any}) => {
        setSelectedREPO(e.value);
        //-------------------------
        loadRepoAnalyticsData(e.value.name);
    }


    const renderProviderSelector = () => {

        return (
            <div className="p-grid">
                <div className="p-col">
                    <div className="card analytics-card">
                        <div className="p-grid" style={{marginTop:"0px"}}>
                            <div className="p-col">
                                <h2 className="h2-title">PROVIDER ANALYTICS</h2>
                                <Dropdown value={selectedPROVIDER} options={PROVIDERS} onChange={onProviderChange} optionLabel="label" placeholder="Select Provider" style={{float:'left'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    const renderAssetOverview = (dataSUMMARY, assetTypeName) => {

        return (


            <div className="p-grid">
                <div className="p-col">
                        <div className="card analytics-card">
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-7">
                                    <h2 className="h2-title">OVERVIEW</h2>
                                    <div style={{paddingTop:"30px"}}>
                                        <Am_DonutChart chartId = "overview-asset-types"
                                                       chartData = {dataSUMMARY.asset_types}
                                                       useVOC = "false"
                                                       useNAME ="false"
                                                       showLEGEND = "false"
                                                       useTotalTXT ={assetTypeName}
                                                       WordWrap = "14"
                                                       showNUM ="5"
                                                       chartHeight="280px">
                                        </Am_DonutChart>
                                    </div>
                                </div>
                                <div className="p-col">
                                    <Am_HBarChart chartId = "overview-asset-providers"
                                                  chartData = {dataSUMMARY.providers}
                                                  chartTitle = "PROVIDERS"
                                                  useVOC = "false"
                                                  useNAME ="true"
                                               showNUM="7"
                                                  chartHeight="325px">
                                    </Am_HBarChart>
                                </div>
                            </div>
                        </div>
                </div>
            </div>


        )

    }




    return (
        <>
            <div>
                <div className="analytics-layout-content">
                    <TabView className="tableview-tab" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel leftIcon="fad fa-table" header="Datasets">
                            {   (viewProviderOverview && analyticsData)?analyticsData.datasets_summary?
                                renderAssetOverview(analyticsData.datasets_summary,"datasets")
                                :console.log():console.log()
                            }
                            { renderProviderSelector() }
                            {
                                dataDatasets?
                                    <CenterAnalytics data={dataDatasets} analyticstype="Datasets"></CenterAnalytics>
                                    :console.log()
                            }
                        </TabPanel>
                        <TabPanel leftIcon="fad fa-file-alt" header="Documents / Digital Assets">
                            {   (viewProviderOverview && analyticsData)?analyticsData.publications_summary?
                                renderAssetOverview(analyticsData.publications_summary,"resources")
                                :console.log():console.log()
                            }
                            { renderProviderSelector() }
                            {
                                dataPublications?
                                    <CenterAnalytics data={dataPublications} analyticstype="Documents / Digital Assets"></CenterAnalytics>
                                    :console.log()
                            }
                        </TabPanel>
                        <TabPanel leftIcon="fad fa-database" header="Sources">

                            {   (viewRepoOverview && analyticsData)?analyticsData.sources_summary?
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-6">
                                        <div className="card analytics-card">
                                            <h2 className="h2-title">DOCUMENT REPOSITORIES / PORTALS</h2>
                                            <div>
                                                <Am_HBarChart chartId = "overview-pub-sources"
                                                              chartData = {analyticsData.sources_summary.publication_repos}
                                                    //chartTitle = "PUBLICATION REPOSITORIES/PORTALS"
                                                              useVOC = "false"
                                                              useNAME ="false"
                                                     showNUM="7"
                                                              chartHeight="325px">
                                                </Am_HBarChart>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="card analytics-card">
                                            <h2 className="h2-title">DATASET REPOSITORIES / PORTALS</h2>
                                            <div>
                                                <Am_HBarChart chartId = "overview-data-sources"
                                                              chartData = {analyticsData.sources_summary.dataset_repos}
                                                    //chartTitle = "DATASET REPOSITORIES/PORTALS"
                                                              useVOC = "false"
                                                              useNAME ="false"
                                                     showNUM="7"
                                                              chartHeight="325px">
                                                </Am_HBarChart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :console.log():console.log()
                            }

                            <div className="p-grid">
                                <div className="p-col">
                                    <div className="card analytics-card">
                                        <div className="p-grid" style={{marginTop:"0px"}}>
                                            <div className="p-col">
                                                <h2 className="h2-title">REPOSITORY / PORTAL ANALYTICS</h2>
                                                <Dropdown value={selectedREPO} options={REPOSITORIES} onChange={onRepoChange} optionLabel="label" placeholder="Select Repository / Portal" style={{float:'left'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                dataRepositories?
                                    <RepoAnalytics data={dataRepositories}></RepoAnalytics>
                                    :console.log()
                            }
                        </TabPanel>
                    </TabView>
                </div>

            </div>
        </>
    );

}
