import React, {useEffect, useState} from "react";
import {DataTable} from "primereact//datatable";
import {Column} from "primereact/column";
import {Tooltip} from "primereact/tooltip";

export const RelatedTable = (props) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.dataRelevant) {
            setData(props.dataRelevant);
        }

    }, []);


    const imageBodyTemplate = (data) => {
        if(data.rights.access==="Open"){
            return <>
                <Tooltip target=".image-table"/>
                <img src={'assets/layout/images/open.png'} data-pr-tooltip={data.rights.access} className="image-table" alt=""/>
            </>;
        } else {
            return <>
                <Tooltip target=".image-table"/>
                <img src={'assets/layout/images/close.png'} data-pr-tooltip={data.rights.access} className="image-table" alt=""/>
            </>;
        }
    }

    const titleBodyTemplate = (data) => {
        return <div>
            <div style={{fontSize:"12px",paddingBottom:"6px"}}>{data.resource_type.toUpperCase()}</div>
            <div style={{fontSize:"15px", textAlign:"justify"}}>{data.citation}</div>
        </div>;
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div>
                <div className="p-column-title">SUMMARY</div>
                <div style={{paddingTop:"10px",textAlign:"justify"}}>
                    {data.en_description}
                </div>
            </div>
        );
    }

    return (
        <div className="datatable-rowexpansion-demo">
                <DataTable value={data} dataKey="dataNODE_id" >
                    <Column field="citation" body={titleBodyTemplate} style={{ fontSize: '16px' }} />
                </DataTable>
        </div>
    );
}
