import React, {useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";




export const About = () => {

    let repoDATA = [
        //------------------------- AFRICA RICE
        {
            "provider": "Africa Rice Center",
            "provider_url": "https://www.africarice.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/AfricaRice",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },        {
            "provider": "Africa Rice Center",
            "provider_url": "https://www.africarice.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/AfricaRice",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- BIOVERSITY
        // {
        //     "provider": "Bioversity International",
        //     "provider_url": "https://www.bioversityinternational.org/",
        //     //---------------
        //     "resources": "Documents / Digital Assets",
        //     "res_analytics_url": "/#/analytics/0/Bioversity",
        //     //---------------
        //     "repository": "Bioversity Library",
        //     "repo_url": "https://www.bioversityinternational.org/e-library/",
        //     "repo_analytics_url": "/#/analytics/2/Bioversity Library"
        // },
        {
            "provider": "Bioversity International",
            "provider_url": "https://www.bioversityinternational.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/Bioversity",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },
        {
            "provider": "Bioversity International",
            "provider_url": "https://www.bioversityinternational.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/Bioversity",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- Bioversity-CIAT
        {
            "provider": "Bioversity-CIAT Alliance",
            "provider_url": "https://alliancebioversityciat.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/Bioversity-CIAT",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },
        //------------------------- CIAT
        {
            "provider": "CIAT - International Center for Tropical Agriculture",
            "provider_url": "https://ciat.cgiar.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/CIAT",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },
        {
            "provider": "CIAT - International Center for Tropical Agriculture",
            "provider_url": "https://ciat.cgiar.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/CIAT",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- CIFOR
        // {
        //     "provider": "CIFOR - Center for International Forestry Research",
        //     "provider_url": "https://www.cifor.org/",
        //     //---------------
        //     "resources": "Documents / Digital Assets",
        //     "res_analytics_url": "/#/analytics/0/CIAT",
        //     //---------------
        //     "repository": "CIFOR Knowledge Portal",
        //     "repo_url": "https://www.cifor.org/knowledge/",
        //     "repo_analytics_url": "/#/analytics/2/CIFOR Knowledge"
        // },
        {
            "provider": "CIFOR - Center for International Forestry Research",
            "provider_url": "https://www.cifor.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/CIAT",
            //---------------
            "repository": "CIFOR Dataverse",
            "repo_url": "https://data.cifor.org",
            "repo_analytics_url": "/#/analytics/2/CIFOR Dataverse"
        },
        {
            "provider": "CIFOR - Center for International Forestry Research",
            "provider_url": "https://www.cifor.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/CIAT",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- CIMMYT
        {
            "provider": "CIMMYT - International Maize and Wheat Improvement Center",
            "provider_url": "https://www.cimmyt.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/CIMMYT",
            //---------------
            "repository": "CIMMYT Research Data & Software Repository",
            "repo_url": "https://data.cimmyt.org",
            "repo_analytics_url": "/#/analytics/2/CIMMYT Research Data & Software Repository"
        },
        {
            "provider": "CIMMYT - International Maize and Wheat Improvement Center",
            "provider_url": "https://www.cimmyt.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/CIMMYT",
            //---------------
            "repository": "CIMMYT Publications Repository",
            "repo_url": "https://repository.cimmyt.org/",
            "repo_analytics_url": "/#/analytics/2/CIMMYT Publications Repository"
        },

        //------------------------- CIP
        {
            "provider": "CIP - International Potato Center",
            "provider_url": "https://cipotato.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/CIP",
            //---------------
            "repository": "CIP Dataverse",
            "repo_url": "https://data.cipotato.org",
            "repo_analytics_url": "/#/analytics/2/CIP Dataverse"
        },
        {
            "provider": "CIP - International Potato Center",
            "provider_url": "https://cipotato.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/CIP",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- ICARDA
        {
            "provider": "ICARDA - International Center for Agricultural Research in the Dry Areas",
            "provider_url": "https://www.icarda.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/ICARDA",
            //---------------
            "repository": "MELDATA",
            "repo_url": "https://data.mel.cgiar.org",
            "repo_analytics_url": "/#/analytics/2/MELDATA"
        },
        {
            "provider": "ICARDA - International Center for Agricultural Research in the Dry Areas",
            "provider_url": "https://www.icarda.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/ICARDA",
            //---------------
            "repository": "MELSpace",
            "repo_url": "https://repo.mel.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/MELSpace"
        },
        // {
        //     "provider": "ICARDA - International Center for Agricultural Research in the Dry Areas",
        //     "provider_url": "https://www.icarda.org/",
        //     //---------------
        //     "resources": "Documents / Digital Assets",
        //     "res_analytics_url": "/#/analytics/0/ICARDA",
        //     //---------------
        //     "repository": "CGSpace",
        //     "repo_url": "https://cgspace.cgiar.org/",
        //     "repo_analytics_url": "/#/analytics/2/CGSpace"
        // },

        //------------------------- ICRAF
        {
            "provider": "ICRAF - World Agroforestry Centre",
            "provider_url": "https://www.worldagroforestry.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/ICRAF",
            //---------------
            "repository": "ICRAF Research Data Repository",
            "repo_url": "https://data.worldagroforestry.org",
            "repo_analytics_url": "/#/analytics/2/ICRAF Research Data Repository"
        },
        {
            "provider": "ICRAF - World Agroforestry Centre",
            "provider_url": "https://www.worldagroforestry.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/ICRAF",
            //---------------
            "repository": "ICRAF Web Portal",
            "repo_url": "https://www.worldagroforestry.org/publications-all",
            "repo_analytics_url": "/#/analytics/2/ICRAF Web Portal"
        },

        //------------------------- ICRISAT
        {
            "provider": "ICRISAT - International Crops Research Institute for the Semi-Arid Tropics",
            "provider_url": "https://www.icrisat.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/ICRISAT",
            //---------------
            "repository": "ICRISAT Dataverse",
            "repo_url": "http://dataverse.icrisat.org",
            "repo_analytics_url": "/#/analytics/2/ICRISAT Dataverse"
        },
        {
            "provider": "ICRISAT - International Crops Research Institute for the Semi-Arid Tropics",
            "provider_url": "https://www.icrisat.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/ICRISAT",
            //---------------
            "repository": "ICRISAT Library",
            "repo_url": "http://oar.icrisat.org/",
            "repo_analytics_url": "/#/analytics/2/ICRISAT Library"
        },

        //------------------------- IFPRI
        {
            "provider": "IFPRI - International Food Policy Research Institute",
            "provider_url": "https://www.ifpri.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/IFPRI",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },
        {
            "provider": "IFPRI - International Food Policy Research Institute",
            "provider_url": "https://www.ifpri.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/IFPRI",
            //---------------
            "repository": "IFPRI Library",
            "repo_url": "https://ebrary.ifpri.org/",
            "repo_analytics_url": "/#/analytics/2/IFPRI-Ebrary"
        },

        //------------------------- IITA
        // {
        //     "provider": "IITA - International Institute of Tropical Agriculture",
        //     "provider_url": "https://www.iita.org/",
        //     //---------------
        //     "resources": "Documents / Digital Assets",
        //     "res_analytics_url": "/#/analytics/0/IITA",
        //     //---------------
        //     "repository": "IITA Publications Database",
        //     "repo_url": "https://biblio.iita.org/index.php?page=pubyear&kind=year&type=iita",
        //     "repo_analytics_url": "/#/analytics/2/IITA Publications Database"
        // },
        {
            "provider": "IITA - International Institute of Tropical Agriculture",
            "provider_url": "https://www.iita.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/IITA",
            //---------------
            "repository": "IITA CKAN",
            "repo_url": "https://data.iita.org/",
            "repo_analytics_url": "/#/analytics/2/IITA CKAN"
        },
        {
            "provider": "IITA - International Institute of Tropical Agriculture",
            "provider_url": "https://www.iita.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/IITA",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- ILRI
        {
            "provider": "ILRI - International Livestock Research Institute",
            "provider_url": "https://www.ilri.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/ILRI",
            //---------------
            "repository": "ILRI Datasets Portal",
            "repo_url": "https://data.ilri.org/portal/",
            "repo_analytics_url": "/#/analytics/2/ILRI Datasets Portal"
        },
        {
            "provider": "ILRI - International Livestock Research Institute",
            "provider_url": "https://www.ilri.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/ILRI",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- IRRI
        {
            "provider": "IRRI - International Rice Research Institute",
            "provider_url": "https://www.irri.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/IRRI",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },
        {
            "provider": "IRRI - International Rice Research Institute",
            "provider_url": "https://www.irri.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/IRRI",
            //---------------
            "repository": "IRRI Library",
            "repo_url": "https://library.irri.org",
            "repo_analytics_url": "/#/analytics/2/Bioversity Library"
        },

        // {
        //     "provider": "IRRI - International Rice Research Institute",
        //     "provider_url": "https://www.irri.org/",
        //     //---------------
        //     "resources": "Datasets",
        //     "res_analytics_url": "/#/analytics/1/IRRI",
        //     //---------------
        //     "repository": "IRRI - Farm Household Survey Database (FHSD)",
        //     "repo_url": "http://ricestat.irri.org/fhsd/",
        //     "repo_analytics_url": "/#/analytics/2/IRRI-FHSD"
        // },
        //------------------------- IWMI
        {
            "provider": "IWMI - International Water Management Institute",
            "provider_url": "https://www.iwmi.cgiar.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/IWMI",
            //---------------
            "repository": "Water Data Portal",
            "repo_url": "http://waterdata.iwmi.org/",
            "repo_analytics_url": "/#/analytics/2/Water Data Portal"
        },
        {
            "provider": "IWMI - International Water Management Institute",
            "provider_url": "https://www.iwmi.cgiar.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/IWMI",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- WorldFish
        {
            "provider": "WorldFish",
            "provider_url": "https://www.worldfishcenter.org/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/WorldFish",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },
        {
            "provider": "WorldFish",
            "provider_url": "https://www.worldfishcenter.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/WorldFish",
            //---------------
            "repository": "WorldFish Repository",
            "repo_url": "https://digitalarchive.worldfishcenter.org/",
            "repo_analytics_url": "/#/analytics/2/WorldFish Repository"
        },

        //------------------------- CGIAR CRPs/PTFs
        {
            "provider": "CRPs/PTFs - CGIAR Research Programs and Platforms",
            "provider_url": "https://www.cgiar.org/cgiar-portfolio/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/CGIAR-CRP-PTF",
            //---------------
            "repository": "HARVARD Dataverse",
            "repo_url": "https://dataverse.harvard.edu",
            "repo_analytics_url": "/#/analytics/2/HARVARD Dataverse"
        },
        {
            "provider": "CRPs/PTFs - CGIAR Research Programs and Platforms",
            "provider_url": "https://www.cgiar.org/cgiar-portfolio/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/CGIAR-CRP-PTF",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- USAID - FTF
        {
            "provider": "USAID - Feed the Future",
            "provider_url": "https://www.feedthefuture.gov/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/USAID-FTF",
            //---------------
            "repository": "USAID Development Data Library (DDL)",
            "repo_url": "https://data.usaid.gov/",
            "repo_analytics_url": "/#/analytics/2/USAID Development Data Library (DDL)"
        },
        {
            "provider": "USAID - Feed the Future",
            "provider_url": "https://www.feedthefuture.gov/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/USAID-FTF",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },

        //------------------------- PABRA
        {
            "provider": "PABRA - Pan-Africa Bean Research Alliance",
            "provider_url": "https://www.pabra-africa.org/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/PABRA",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },
        //------------------------- CTA
        {
            "provider": "CTA - Technical Centre for Agricultural and Rural Cooperation",
            "provider_url": "https://www.cta.int/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/CTA",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },
        //------------------------- IGAD-LPI
        {
            "provider": "IGAD - Intergovernmental Authority on Development",
            "provider_url": "https://igad.int/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/IGAD",
            //---------------
            "repository": "CGSpace",
            "repo_url": "https://cgspace.cgiar.org/",
            "repo_analytics_url": "/#/analytics/2/CGSpace"
        },
        //------------------------- ICAR
        {
            "provider": "ICAR - Indian Council of Agricultural Research",
            "provider_url": "https://www.icar.org.in/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/ICAR",
            //---------------
            "repository": "KRISHI",
            "repo_url": "https://krishi.icar.gov.in/jspui/",
            "repo_analytics_url": "/#/analytics/2/KRISHI"
        },
        //------------------------- Data GOV.IN
        {
            "provider": "Government of India (IndiaGOV)",
            "provider_url": "https://www.india.gov.in/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/IndiaGOV",
            //---------------
            "repository": "Open Government Data (OGD) Platform India",
            "repo_url": "https://data.gov.in/",
            "repo_analytics_url": "/#/analytics/2/Open Government Data (OGD) Platform India"
        },
        //------------------------- FCDO
        {
            "provider": "UK Foreign, Commonwealth & Development Office (FCDO)",
            "provider_url": "https://www.gov.uk/government/organisations/foreign-commonwealth-development-office/",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/FCDO",
            //---------------
            "repository": "GOV.UK",
            "repo_url": "https://www.gov.uk/research-for-development-outputs",
            "repo_analytics_url": "/#/analytics/2/GOV.UK"
        },
        {
            "provider": "UK Foreign, Commonwealth & Development Office (FCDO)",
            "provider_url": "https://www.gov.uk/government/organisations/foreign-commonwealth-development-office/",
            //---------------
            "resources": "Documents / Digital Assets",
            "res_analytics_url": "/#/analytics/1/FCDO",
            //---------------
            "repository": "GOV.UK",
            "repo_url": "https://www.gov.uk/research-for-development-outputs",
            "repo_analytics_url": "/#/analytics/2/GOV.UK"
        },
        //------------------------- World Bank Microdata Library
        {
            "provider": "World Bank Group (WB)",
            "provider_url": "https://www.worldbank.org/en/home",
            //---------------
            "resources": "Datasets",
            "res_analytics_url": "/#/analytics/0/WB",
            //---------------
            "repository": "Microdata Library",
            "repo_url": "https://microdata.worldbank.org/index.php/home",
            "repo_analytics_url": "/#/analytics/2/Microdata Library"
        }

    ];


    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const providerBodyTemplate = (data) => {

        return (
            <React.Fragment>
                <a href={data.provider_url} target="_blank" rel="noreferrer" style={{fontSize:"15px"}}>{data.provider}</a>
            </React.Fragment>
        )

    }

    const resourceAnalyticsBodyTemplate = (data) => {

        return (
            <React.Fragment>
                <a href={data.res_analytics_url} target="_blank" rel="noreferrer"style={{fontSize:"15px"}}>{data.resources}</a>
            </React.Fragment>
        )

    }

    const repositoryAnalyticsBodyTemplate = (data) => {

        return (
            <React.Fragment>
                <a href={data.repo_analytics_url} target="_blank" rel="noreferrer" style={{fontSize:"15px"}}>{data.repository}</a>
                <a href={data.repo_url} target={"_blank"}  rel="noreferrer">  <sup><i className="fad fa-external-link-alt"></i></sup></a>
            </React.Fragment>
        )

    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    }

    const clearFilters = () => {
        setGlobalFilterValue('');
    }


    const renderHeader = () => {
        return (
            <div className="p-d-flex p-jc-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilters} style={{height:"32px"}}/>
                <span className="p-input-icon-left" >
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e)=>onGlobalFilterChange(e)} placeholder="Provider Search" style={{height:"32px"}}/>
                </span>
            </div>
        )
    }

    return (
        <>
            <div>
                <div className="fair-layout-content">

                    <div className="p-grid">
                        <div className="p-col">
                            <div className="card analytics-card">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{paddingRight:"10px",paddingLeft:"10px"}}>
                                        <h2 className="h2-title">ABOUT GARDIAN</h2>
                                        <div style={{paddingTop:"10px"}}>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                GARDIAN, the Global Agricultural Research Data Innovation & Acceleration Network, is a digital platform providing support across the
                                                entire data lifecycle, allowing users to manage data responsibly towards open and FAIR outcomes.
                                            </p>

                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                GARDIAN offers a search engine to discover agri-food datasets, documents, and digital assets from CGIAR centers and its partners, using
                                                multiple and complex criteria. The search engine includes analytics for understanding the “where” and “what” dimensions of
                                                research outcomes. <a href="#/fair/" target="_blank"  rel="noreferrer">FAIR Metrics</a> have also been developed to help incentivize FAIRer data assets, and
                                                for monitoring progress towards full implementation of the <a href="https://cgspace.cgiar.org/bitstream/handle/10568/113623/?sequence=1&isAllowed=y" target="_blank" rel="noreferrer">CGIAR Open and FAIR Data Assets Policy</a>.
                                            </p>

                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                GARDIAN additionally offers a broad set of <a href="#/tools" target="_blank">tools</a> that apply the FAIR Data principles for all the different stages of the data
                                                lifecycle, including Data <a href="#/tools/0" target="_blank">Collection</a>, <a href="#/tools/1" target="_blank"  rel="noreferrer">Curation</a> and <a href="#/tools/2" target="_blank">Analysis</a>.
                                            </p>

                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                GARDIAN had been developed by <a href="https://www.scio.systems/" target="_blank" rel="noreferrer">SCiO</a> and is maintained
                                                by the <a href="https://bigdata.cgiar.org/" target="_blank"  rel="noreferrer">CGIAR Platform for Big Data in Agriculture</a> since 2019. Infrastructure, tools, and approaches to make
                                                CGIAR data more visible, interoperable and reusable continue to be further refined.
                                            </p>

                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                <a href="https://bigdata.cgiar.org/about-the-platform/" target="_blank"  rel="noreferrer">More about the Platform</a>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col">
                            <div className="card analytics-card">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{paddingRight:"10px",paddingLeft:"10px"}}>
                                        <h2 className="h2-title">BEHIND GARDIAN SEARCH</h2>
                                        <div style={{paddingTop:"10px"}}>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                At the heart of GARDIAN Search lies its Metadata and Geospatial Indexes, powering the search for Documents/Datasets and Maps respectively.
                                            </p>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                The building process for the <b>GARDIAN Metadata Index</b> starts from the GARDIAN harvesters, the components responsible for accessing all
                                                metadata records exposed from <a href="#providers">a large number of repositories</a> and harmonizing the different schemas
                                                against a <a target="_blank"  rel="noreferrer" href="https://github.com/AgriculturalSemantics/cg-core">CG Core</a>-compatible metadata model.
                                            </p>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                Following the harvesting process, GARDIAN enacts its metadata cleaning, correction, and enrichment pipelines. Cleaning and correction are
                                                based on a set of curated dictionaries covering <a target="_blank"  rel="noreferrer" href="https://www.cgiar.org/research/research-portfolio/">CGIAR CRPs and Platforms</a>, funders, and standards
                                                for countries and geographical regions (<a target="_blank" rel="noreferrer" href="https://unstats.un.org/unsd/methodology/m49/">UN M49</a>, <a target="_blank"  rel="noreferrer" href="https://www.iso.org/iso-3166-country-codes.html">ISO 3166</a>).
                                                Enrichment is realized via the extraction and embedding of additional metadata, obtained
                                                from <a target="_blank"  rel="noreferrer" href="https://www.doi.org/registration_agencies.html">DOI providers</a>, <a target="_blank"  rel="noreferrer" href="https://ror.org/">ROR</a>, and the <a href="https://gadm.org/" target="_blank" rel="noreferrer">Database of Global Administrative Areas (GADM)</a>.
                                            </p>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                The cleaned, corrected and enriched metadata records are subsequently deduplicated and assigned with a FAIR score metric, before ingested
                                                in the GARDIAN Elasticsearch index.
                                            </p>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                The <b>GARDIAN Geospatial Index</b> covers global, high-value geospatial datasets. To cope with the volume and particularities of
                                                geospatial data, GARDIAN employs a processing pipeline for decoupling querying and visualization
                                                of such data. The pipeline operates over an infrastructure tailored for Big Data processing and includes mature and tested technologies like Apache Spark.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-col">
                            <div id="providers" className="card analytics-card">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{paddingRight:"10px",paddingLeft:"10px"}}>
                                        <h2 className="h2-title">GARDIAN METADATA PROVIDERS</h2>
                                        <div style={{paddingTop:"10px"}}>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                GARDIAN acknowledges the work of a wide range of providers in indexing documents, datasets,
                                                and other digital materials covering the agri-food sector.
                                            </p>
                                        </div>

                                        <div style={{paddingTop:"10px"}}>
                                            <DataTable value={repoDATA} paginator rowGroupMode="rowspan" groupRowsBy="provider"
                                                       sortMode="single" sortField="provider" sortOrder={1} responsiveLayout="scroll"
                                                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
                                                //----------
                                                       globalFilter={globalFilterValue}
                                                       header={renderHeader()} emptyMessage="No provider found.">
                                                <Column field="provider" header="Provider" body={providerBodyTemplate}></Column>
                                                <Column field="restypes" header="Type of Resources" body={resourceAnalyticsBodyTemplate} ></Column>
                                                <Column field="origrepo" header="Repository" body={repositoryAnalyticsBodyTemplate}></Column>
                                            </DataTable>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="p-grid">
                        <div className="p-col">
                            <div className="card analytics-card">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{paddingRight:"10px",paddingLeft:"10px"}}>
                                        <h2 className="h2-title">GARDIAN SUPPORT</h2>
                                        <div style={{paddingTop:"10px"}}>
                                            <p style={{fontSize:"16px", textAlign:"justify"}}>
                                                If you are experiencing issues with GARDIAN or have feedback for us, please let us know via the form below.
                                            </p>
                                        </div>

                                        <div className="p-fluid" style={{paddingTop:"20px", paddingLeft:"20px", paddingRight:"20px"}}>
                                            <div className="p-field">
                                                <label htmlFor="form_name">Name  (only if you’d like us to know)</label>
                                                <InputText id="form_name" type="text" />
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="form_email">Email (only if you’d like a response)</label>
                                                <InputText id="form_email" type="text" />
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="form_subject">Subject</label>
                                                <InputText id="form_subject" type="text" />
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="form_message">Message</label>
                                                <InputTextarea id="form_message" rows="4" />
                                            </div>
                                        </div>

                                        <div>
                                            <Button className="search-btn" label="Submit Message"></Button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </>
    );

}
