import React, {useEffect,useState} from 'react';
import {Am_AssetsOverTimeChart} from "./am_AssetsOverTimeChart";

export const RepoAnalytics = (props) => {

    const [analyticsData, setAnalyticsData] = useState(null);
    const [chartAccessTitle, setChartAccessTitle] = useState("");

    useEffect( () => {

        if (props.data) {
            setAnalyticsData(props.data);
        }

    }, [props.data])

    //------------------------------------------------

    useEffect( () => {
        if (props.analyticstype) {
            let title = props.analyticstype + " over time";
            setChartAccessTitle(title);
        }

    }, [props.analyticstype])

    //------------------------------------------------


    return (
        <>

            <div className="p-grid">
                <div className="p-col">
                    {   analyticsData?(analyticsData.publications)&&(analyticsData.publications.assets_over_time)&&(analyticsData.publications.assets_over_time.length>0)?
                        <div className="card analytics-card">
                            <div className="p-grid">
                                <div className="p-col">
                                    <Am_AssetsOverTimeChart chartId="analytics-publications-overtime"
                                                            chartData= {analyticsData.publications.assets_over_time}
                                                            chartTitle= "Documents / Digital Assets over time"
                                                            useVOC="false"
                                                            useNAME="false"
                                                            chartHeight="340px">
                                    </Am_AssetsOverTimeChart>

                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }
                </div>
            </div>

            <div className="p-grid">
                <div className="p-col">
                    {   analyticsData?(analyticsData.datasets)&&(analyticsData.datasets.assets_over_time)&&(analyticsData.datasets.assets_over_time.length>0)?
                        <div className="card analytics-card">
                            <div className="p-grid">
                                <div className="p-col">
                                    <Am_AssetsOverTimeChart chartId="analytics-datasets-overtime"
                                                            chartData= {analyticsData.datasets.assets_over_time}
                                                            chartTitle= "Datasets over time"
                                                            useVOC="false"
                                                            useNAME="false"
                                                            chartHeight="340px">
                                    </Am_AssetsOverTimeChart>

                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }
                </div>
            </div>






        </>
    )
}
