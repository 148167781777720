import React, {useEffect, useState} from "react";
import {AdvancedSearch} from "../components/AdvancedSearch";
import CountUp from "react-countup";
import {Button} from "primereact/button";
import {TabPanel, TabView} from "primereact/tabview";
import {Am_RegionsMapChart} from "../components/am_RegionsMapChart";
import {useHistory} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";
import QvantumService from "../service/QvantumService";

export const Home = () => {

    const UNregions = [
        {code: "UNM49:145", label: "Asia / Western Asia", id: "westAsia", value:0},
        {code: "UNM49:021", label: "Americas / Northern America", id:"northAmerica", value:0},
        {code: "UNM49:143", label: "Asia / Central Asia", id:"centralAsia", value:0},
        {code: "UNM49:057", label: "Oceania / Micronesia", id:"micronesia", value:0},
        {code: "UNM49:054", label: "Oceania / Melanesia", id:"melanesia", value:0},
        {code: "UNM49:015", label: "Africa / Northern Africa", id:"northAfrica", value:0},
        {code: "UNM49:030", label: "Asia / Eastern Asia", id:"eastAsia", value:0},
        {code: "UNM49:018", label: "Africa / Sub-Saharan Africa / Southern Africa", id:"southAfrica", value:0},
        {code: "UNM49:035", label: "Asia / South-eastern Asia", id:"southeastAsia", value:0},
        {code: "UNM49:013", label: "Americas / Latin America and the Caribbean / Central America", id:"centralAmerica", value:0},
        {code: "UNM49:151", label: "Europe / Eastern Europe", id:"eastEurope", value:0},
        {code: "UNM49:011", label: "Africa / Sub-Saharan Africa / Western Africa", id:"westAfrica", value:0},
        {code: "UNM49:014", label: "Africa / Sub-Saharan Africa / Eastern Africa", id:"eastAfrica", value:0},
        {code: "UNM49:155", label: "Europe / Western Europe", id:"westEurope", value:0},
        {code: "UNM49:053", label: "Oceania / Australia and New Zealand", id:"australiaNZ", value:0},
        {code: "UNM49:061", label: "Oceania / Polynesia", id:"polynesia", value:0},
        {code: "UNM49:005", label: "Americas / Latin America and the Caribbean / South America", id:"southAmerica", value:0},
        {code: "UNM49:154", label: "Europe / Northern Europe", id:"northEurope", value:0},
        {code: "UNM49:039", label: "Europe / Southern Europe", id:"southEurope", value:0},
        {code: "UNM49:017", label: "Africa / Sub-Saharan Africa / Middle Africa", id:"middleAfrica", value:0},
        {code: "UNM49:034", label: "Asia / Southern Asia", id:"southAsia", value:0},
        {code: "UNM49:029", label: "Americas / Latin America and the Caribbean / Caribbean", id:"caribbean", value:0}
    ];


    const [gardianMetadata, setGardianMetadata] = useState(null);
    //---------------------------
    const [pubRegions, setpubRegions] = useState([]);
    const [datRegions, setdatRegions] = useState([]);
    //---------------------------
    const [regions, setRegions] = useState([]);
    //---------------------------
    const [activeIndex, setActiveIndex] = useState(0);

    let history = useHistory();

    useEffect(() => {
        const gardianSummaryService = new QvantumService();
        gardianSummaryService.getGardianSummary().then(data => setGardianMetadata(data));
    }, []);


    useEffect(() => {
        if (gardianMetadata) {

            let dataR = [];
            if ((gardianMetadata.publications)&&(gardianMetadata.publications.regions)&&(gardianMetadata.publications.regions.length>0)) {
                for (let i = 0; i < gardianMetadata.publications.regions.length; i++) {
                    for (let j = 0; j < UNregions.length; j++) {
                        if (gardianMetadata.publications.regions[i].code === UNregions[j].code) {

                            let Robj = {...UNregions[j] };
                            Robj.value = gardianMetadata.publications.regions[i].freq;
                            //---------------
                            dataR.push(Robj) ;
                        }
                    }
                }
                //--------------
                setpubRegions(dataR);
            }
            //-------------------------
            let dataD = [];
            if ((gardianMetadata.datasets)&&(gardianMetadata.datasets.regions)&&(gardianMetadata.datasets.regions.length>0)) {
                for (let i = 0; i < gardianMetadata.datasets.regions.length; i++) {
                    for (let j = 0; j < UNregions.length; j++) {
                        if (gardianMetadata.datasets.regions[i].code === UNregions[j].code) {

                            let Robj = {...UNregions[j] };
                            Robj.value = gardianMetadata.datasets.regions[i].freq;
                            //---------------
                            dataD.push(Robj) ;
                        }
                    }
                }
                //--------------
                setdatRegions(dataD);
                //----------------------
                setActiveIndex(0);
                setRegions(dataD);            }

        }
    }, [gardianMetadata]);


    const loadRegionData = (activeIdx) => {
        setActiveIndex(activeIdx);
        //-------------------------
        if (activeIdx===0) {
            setRegions(datRegions);
        }
        //-------------------------
        if (activeIdx===1) {
            setRegions(pubRegions);
        }
    }

    const exploreRegionHandler = (value) => {

        let pre_filter_str = "/search-results/" + activeIndex + "/{\"user_query\":\"\",\"json_query\":{\"rules\":[{\"field\":\"region\",\"value\":\"";
        let post_filter_str = "\",\"operator\":\"=\"}],\"combinator\":\"and\",\"not\":false}}";

        let newQuery = pre_filter_str + value.term +  post_filter_str;
        //---------------------
        history.push(newQuery);
    }


    return (
        <>

            <div className="our-layout-content home-container" >
                <div className="p-grid">
                    <div className="p-col" style={{textAlign: "center",marginTop: "20px"}}>
                        <img src="assets/layout/images/GARDIAN-sLogo.png"style={{maxHeight: "140px"}} alt=""/>
                        <h2 style={{fontWeight: "bold"}}>Welcome to GARDIAN</h2>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div style={{textAlign: "center",width: "70%", margin:"auto", marginTop: "20px", marginBottom: "20px"}}>
                            <h4>the one-stop hub for agri-food data and publications discovery</h4>
                            <AdvancedSearch supportADVANCED="false"></AdvancedSearch>
                        </div>
                    </div>
                </div>
            </div>


            <div className="our-layout-content home-container" style={{backgroundColor:"#ddd"}}>
                <div className="p-grid" style={{paddingTop:"10px", paddingBottom:"40px" }}>
                    <div className="p-col-12">
                        <h2 style={{textAlign: "center", paddingTop:"30px", marginBottom: "20px"}}>What's in GARDIAN?</h2>
                    </div>

                    <div className="p-col-4" style={{textAlign: "center"}}>
                        <i className="fad fa-file-alt fa-5x home-icons"></i>

                        { (gardianMetadata)&&(gardianMetadata.publications)&&(gardianMetadata.publications.total)?
                            <h2 style={{marginBottom:"6px"}}><CountUp end={gardianMetadata.publications.total} duration={3} separator="," /></h2>
                            :
                            console.log()
                        }

                        <h4 style={{fontWeight: "bold", margin:"0px"}}>DOCUMENTS / DIGITAL ASSETS</h4>
                    </div>

                    <div className="p-col-4" style={{textAlign: "center"}}>
                        <i className="fad fa-file-spreadsheet fa-5x home-icons"></i>

                        { (gardianMetadata)&&(gardianMetadata.datasets)&&(gardianMetadata.datasets.total)?
                            <h2 style={{marginBottom:"6px"}}><CountUp end={gardianMetadata.datasets.total} duration={3} separator="," /></h2>
                            :
                            console.log()
                        }


                        <h4 style={{fontWeight: "bold", margin:"0px"}}>DATASETS</h4>
                    </div>

                    <div className="p-col-4" style={{textAlign: "center"}}>
                        <Link to="maps">
                            <i className="fad fa-globe fa-5x home-icons"></i>
                            <h2 style={{marginBottom:"6px"}}><CountUp end={12} duration={3} separator="," suffix="+ TB"/></h2>
                            <h4 style={{fontWeight: "bold", margin:"0px"}}>GEOSPATIAL DATA</h4>
                        </Link>
                    </div>
                </div>

            </div>


            <div className="our-layout-content home-container" >
                <div className="p-grid"  style={{marginTop: "50px", marginBottom:"50px"}}>

                    <div className="p-col-3" style={{textAlign: "center"}}/>

                        <div className="p-col-3" style={{textAlign: "center"}}>
                            <Link to="about/#providers">
                                <h4>from</h4>
                                <i className="fad fa-sitemap fa-5x home-icons-inv"></i>
                                <h2 style={{marginBottom:"6px"}}><CountUp end={23} duration={3} separator="," /></h2>
                                <h4 style={{fontWeight: "bold", margin:"0px"}}>ORGANIZATIONS</h4>
                            </Link>
                        </div>

                    <div className="p-col-3" style={{textAlign: "center"}}>
                        <Link to="about/#providers">
                            <h4>stored in</h4>
                            <i className="fad fa-database fa-5x home-icons-inv"></i>
                            <h2 style={{marginBottom:"6px"}}><CountUp end={21} duration={3} separator=","/></h2>
                            <h4 style={{fontWeight: "bold", margin:"0px"}}>REPOSITORIES</h4>
                        </Link>
                    </div>
                    <div className="p-col-3" style={{textAlign: "center"}}/>
                </div>

            </div>


            <div className="our-layout-content home-container" style={{backgroundColor:"#ddd"}}>
                <div className="p-grid" style={{paddingTop:"30px", paddingBottom:"60px" }}>
                    <div className="p-col-12">
                        <h2 style={{textAlign: "center", paddingTop:"20px", marginBottom: "30px"}}>What do you want to do?</h2>
                    </div>

                    <div className="p-col-4" style={{textAlign: "center"}}>
                        <center>
                            <a href="/#/search">

                                <Button label="SEARCH GARDIAN RESOURCES" icon="fad fa-search fa-2x" iconPos="left" className="p-button buttons-home" style={{fontSize:"20px", fontWeight:"500", height:"160px"}}/>
                            </a>
                        </center>
                    </div>

                    <div className="p-col-4" style={{textAlign: "center"}}>
                        <center>
                            <a href="/#/tools">
                                <Button label="EXPLORE DATA TOOLS" icon="fad fa-tools fa-2x" iconPos="left" className="p-button buttons-home" style={{fontSize:"20px", fontWeight:"500", height:"160px"}}/>
                            </a>
                        </center>
                    </div>

                    <div className="p-col-4" style={{textAlign: "center"}}>
                        <center>
                            <a href="/#/analytics">
                                <Button label="VIEW GARDIAN ANALYTICS" icon="fad fa-analytics fa-2x" iconPos="left" className="p-button buttons-home" style={{fontSize:"20px", fontWeight:"500", height:"160px"}}/>
                            </a>
                        </center>
                    </div>
                </div>

            </div>


            <div className="our-layout-content home-container" >
                <div className="p-grid">
                    <div className="p-col-12">
                        <h2 style={{textAlign: "center", marginTop:"20px",marginBottom: "0px"}}>Explore GARDIAN's assets</h2>
                        {/*<h4 style={{textAlign: "center", marginTop:"16px", marginBottom: "30px", paddingLeft:"100px", paddingRight:"100px"}}>*/}
                        {/*    Explore data assets by UN region from across CGIAR and a growing set of institutional partners, including: USAID, the UK’s Foreign, Commonwealth and Development Office (FCDO),*/}
                        {/*    the World Bank, the United States Department of Agriculture, the Indian Council for Agricultural Research, and the Open Government Data Platform India.*/}
                        {/*</h4>*/}

                        <h4 style={{textAlign: "center", marginTop:"16px", marginBottom: "30px", paddingLeft:"100px", paddingRight:"100px"}}>
                            Explore data assets by UN region from across CGIAR and a growing set of institutional partners, including: USAID, the UK’s Foreign, Commonwealth and Development Office (FCDO),
                            the Indian Council for Agricultural Research, and the Open Government Data Platform India.
                        </h4>


                    </div>
                    <div className="p-col-12 analytics-layout-content" style={{padding: "0 80px", textAlignLast:"center"}}>

                        <TabView id="tvhome" style={{display:"inline-block"}} activeIndex={activeIndex} onTabChange={(e) => loadRegionData(e.index)}>
                            <TabPanel  header="Datasets" />
                            <TabPanel header="Documents / Digital Assets" />
                        </TabView>

                        <div className="p-grid" style={{paddingTop:"10px", paddingBottom:"40px" }}>


                            { (regions)&&(regions.length>0)?

                                <div className="p-col-12">
                                    <Am_RegionsMapChart
                                        callback={exploreRegionHandler}
                                        chartId="asset-map-chart"
                                        chartData={regions}
                                        useVOC="false"
                                        useNAME="false"
                                        chartHeight="500px">
                                    </Am_RegionsMapChart>
                                </div>

                                :console.log()
                            }

                        </div>


                    </div>
                </div>
            </div>



        </>


    );
}
