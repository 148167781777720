import React, {useEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldHigh from "@amcharts/amcharts4-geodata/worldHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export const Am_MapChart = (props) => {

    const varCOUNTRIES = [
        { "code_UNM49": "UNM49:004", "code_ISO3166_1_a2": "AF", "code_ISO3166_1_a3": "AFG", "value": "Afghanistan" },
        { "code_UNM49": "UNM49:008", "code_ISO3166_1_a2": "AL", "code_ISO3166_1_a3": "ALB", "value": "Albania" },
        { "code_UNM49": "UNM49:012", "code_ISO3166_1_a2": "DZ", "code_ISO3166_1_a3": "DZA", "value": "Algeria" },
        { "code_UNM49": "UNM49:016", "code_ISO3166_1_a2": "AS", "code_ISO3166_1_a3": "ASM", "value": "American Samoa" },
        { "code_UNM49": "UNM49:020", "code_ISO3166_1_a2": "AD", "code_ISO3166_1_a3": "AND", "value": "Andorra" },
        { "code_UNM49": "UNM49:024", "code_ISO3166_1_a2": "AO", "code_ISO3166_1_a3": "AGO", "value": "Angola" },
        { "code_UNM49": "UNM49:028", "code_ISO3166_1_a2": "AG", "code_ISO3166_1_a3": "ATG", "value": "Antigua and Barbuda" },
        { "code_UNM49": "UNM49:031", "code_ISO3166_1_a2": "AZ", "code_ISO3166_1_a3": "AZE", "value": "Azerbaijan" },
        { "code_UNM49": "UNM49:032", "code_ISO3166_1_a2": "AR", "code_ISO3166_1_a3": "ARG", "value": "Argentina" },
        { "code_UNM49": "UNM49:036", "code_ISO3166_1_a2": "AU", "code_ISO3166_1_a3": "AUS", "value": "Australia" },
        { "code_UNM49": "UNM49:040", "code_ISO3166_1_a2": "AT", "code_ISO3166_1_a3": "AUT", "value": "Austria" },
        { "code_UNM49": "UNM49:044", "code_ISO3166_1_a2": "BS", "code_ISO3166_1_a3": "BHS", "value": "Bahamas" },
        { "code_UNM49": "UNM49:048", "code_ISO3166_1_a2": "BH", "code_ISO3166_1_a3": "BHR", "value": "Bahrain" },
        { "code_UNM49": "UNM49:050", "code_ISO3166_1_a2": "BD", "code_ISO3166_1_a3": "BGD", "value": "Bangladesh" },
        { "code_UNM49": "UNM49:051", "code_ISO3166_1_a2": "AM", "code_ISO3166_1_a3": "ARM", "value": "Armenia" },
        { "code_UNM49": "UNM49:052", "code_ISO3166_1_a2": "BB", "code_ISO3166_1_a3": "BRB", "value": "Barbados" },
        { "code_UNM49": "UNM49:056", "code_ISO3166_1_a2": "BE", "code_ISO3166_1_a3": "BEL", "value": "Belgium" },
        { "code_UNM49": "UNM49:060", "code_ISO3166_1_a2": "BM", "code_ISO3166_1_a3": "BMU", "value": "Bermuda" },
        { "code_UNM49": "UNM49:064", "code_ISO3166_1_a2": "BT", "code_ISO3166_1_a3": "BTN", "value": "Bhutan" },
        { "code_UNM49": "UNM49:068", "code_ISO3166_1_a2": "BO", "code_ISO3166_1_a3": "BOL", "value": "Bolivia" },
        { "code_UNM49": "UNM49:070", "code_ISO3166_1_a2": "BA", "code_ISO3166_1_a3": "BIH", "value": "Bosnia and Herzegovina" },
        { "code_UNM49": "UNM49:072", "code_ISO3166_1_a2": "BW", "code_ISO3166_1_a3": "BWA", "value": "Botswana" },
        { "code_UNM49": "UNM49:074", "code_ISO3166_1_a2": "BV", "code_ISO3166_1_a3": "BVT", "value": "Bouvet Island" },
        { "code_UNM49": "UNM49:076", "code_ISO3166_1_a2": "BR", "code_ISO3166_1_a3": "BRA", "value": "Brazil" },
        { "code_UNM49": "UNM49:084", "code_ISO3166_1_a2": "BZ", "code_ISO3166_1_a3": "BLZ", "value": "Belize" },
        { "code_UNM49": "UNM49:086", "code_ISO3166_1_a2": "IO", "code_ISO3166_1_a3": "IOT", "value": "British Indian Ocean Territory" },
        { "code_UNM49": "UNM49:090", "code_ISO3166_1_a2": "SB", "code_ISO3166_1_a3": "SLB", "value": "Solomon Islands" },
        { "code_UNM49": "UNM49:092", "code_ISO3166_1_a2": "VG", "code_ISO3166_1_a3": "VGB", "value": "British Virgin Islands" },
        { "code_UNM49": "UNM49:096", "code_ISO3166_1_a2": "BN", "code_ISO3166_1_a3": "BRN", "value": "Brunei Darussalam" },
        { "code_UNM49": "UNM49:100", "code_ISO3166_1_a2": "BG", "code_ISO3166_1_a3": "BGR", "value": "Bulgaria" },
        { "code_UNM49": "UNM49:104", "code_ISO3166_1_a2": "MM", "code_ISO3166_1_a3": "MMR", "value": "Myanmar" },
        { "code_UNM49": "UNM49:108", "code_ISO3166_1_a2": "BI", "code_ISO3166_1_a3": "BDI", "value": "Burundi" },
        { "code_UNM49": "UNM49:112", "code_ISO3166_1_a2": "BY", "code_ISO3166_1_a3": "BLR", "value": "Belarus" },
        { "code_UNM49": "UNM49:116", "code_ISO3166_1_a2": "KH", "code_ISO3166_1_a3": "KHM", "value": "Cambodia" },
        { "code_UNM49": "UNM49:120", "code_ISO3166_1_a2": "CM", "code_ISO3166_1_a3": "CMR", "value": "Cameroon" },
        { "code_UNM49": "UNM49:124", "code_ISO3166_1_a2": "CA", "code_ISO3166_1_a3": "CAN", "value": "Canada" },
        { "code_UNM49": "UNM49:132", "code_ISO3166_1_a2": "CV", "code_ISO3166_1_a3": "CPV", "value": "Cabo Verde" },
        { "code_UNM49": "UNM49:136", "code_ISO3166_1_a2": "KY", "code_ISO3166_1_a3": "CYM", "value": "Cayman Islands" },
        { "code_UNM49": "UNM49:140", "code_ISO3166_1_a2": "CF", "code_ISO3166_1_a3": "CAF", "value": "Central African Republic" },
        { "code_UNM49": "UNM49:144", "code_ISO3166_1_a2": "LK", "code_ISO3166_1_a3": "LKA", "value": "Sri Lanka" },
        { "code_UNM49": "UNM49:148", "code_ISO3166_1_a2": "TD", "code_ISO3166_1_a3": "TCD", "value": "Chad" },
        { "code_UNM49": "UNM49:152", "code_ISO3166_1_a2": "CL", "code_ISO3166_1_a3": "CHL", "value": "Chile" },
        { "code_UNM49": "UNM49:156", "code_ISO3166_1_a2": "CN", "code_ISO3166_1_a3": "CHN", "value": "China" },
        { "code_UNM49": "UNM49:162", "code_ISO3166_1_a2": "CX", "code_ISO3166_1_a3": "CXR", "value": "Christmas Island" },
        { "code_UNM49": "UNM49:166", "code_ISO3166_1_a2": "CC", "code_ISO3166_1_a3": "CCK", "value": "Cocos (Keeling) Islands" },
        { "code_UNM49": "UNM49:170", "code_ISO3166_1_a2": "CO", "code_ISO3166_1_a3": "COL", "value": "Colombia" },
        { "code_UNM49": "UNM49:174", "code_ISO3166_1_a2": "KM", "code_ISO3166_1_a3": "COM", "value": "Comoros" },
        { "code_UNM49": "UNM49:175", "code_ISO3166_1_a2": "YT", "code_ISO3166_1_a3": "MYT", "value": "Mayotte" },
        { "code_UNM49": "UNM49:178", "code_ISO3166_1_a2": "CG", "code_ISO3166_1_a3": "COG", "value": "Congo" },
        { "code_UNM49": "UNM49:180", "code_ISO3166_1_a2": "CD", "code_ISO3166_1_a3": "COD", "value": "Democratic Republic of the Congo" },
        { "code_UNM49": "UNM49:184", "code_ISO3166_1_a2": "CK", "code_ISO3166_1_a3": "COK", "value": "Cook Islands" },
        { "code_UNM49": "UNM49:188", "code_ISO3166_1_a2": "CR", "code_ISO3166_1_a3": "CRI", "value": "Costa Rica" },
        { "code_UNM49": "UNM49:191", "code_ISO3166_1_a2": "HR", "code_ISO3166_1_a3": "HRV", "value": "Croatia" },
        { "code_UNM49": "UNM49:192", "code_ISO3166_1_a2": "CU", "code_ISO3166_1_a3": "CUB", "value": "Cuba" },
        { "code_UNM49": "UNM49:196", "code_ISO3166_1_a2": "CY", "code_ISO3166_1_a3": "CYP", "value": "Cyprus" },
        { "code_UNM49": "UNM49:203", "code_ISO3166_1_a2": "CZ", "code_ISO3166_1_a3": "CZE", "value": "Czechia" },
        { "code_UNM49": "UNM49:204", "code_ISO3166_1_a2": "BJ", "code_ISO3166_1_a3": "BEN", "value": "Benin" },
        { "code_UNM49": "UNM49:208", "code_ISO3166_1_a2": "DK", "code_ISO3166_1_a3": "DNK", "value": "Denmark" },
        { "code_UNM49": "UNM49:212", "code_ISO3166_1_a2": "DM", "code_ISO3166_1_a3": "DMA", "value": "Dominica" },
        { "code_UNM49": "UNM49:214", "code_ISO3166_1_a2": "DO", "code_ISO3166_1_a3": "DOM", "value": "Dominican Republic" },
        { "code_UNM49": "UNM49:218", "code_ISO3166_1_a2": "EC", "code_ISO3166_1_a3": "ECU", "value": "Ecuador" },
        { "code_UNM49": "UNM49:222", "code_ISO3166_1_a2": "SV", "code_ISO3166_1_a3": "SLV", "value": "El Salvador" },
        { "code_UNM49": "UNM49:226", "code_ISO3166_1_a2": "GQ", "code_ISO3166_1_a3": "GNQ", "value": "Equatorial Guinea" },
        { "code_UNM49": "UNM49:231", "code_ISO3166_1_a2": "ET", "code_ISO3166_1_a3": "ETH", "value": "Ethiopia" },
        { "code_UNM49": "UNM49:232", "code_ISO3166_1_a2": "ER", "code_ISO3166_1_a3": "ERI", "value": "Eritrea" },
        { "code_UNM49": "UNM49:233", "code_ISO3166_1_a2": "EE", "code_ISO3166_1_a3": "EST", "value": "Estonia" },
        { "code_UNM49": "UNM49:234", "code_ISO3166_1_a2": "FO", "code_ISO3166_1_a3": "FRO", "value": "Faroe Islands" },
        { "code_UNM49": "UNM49:238", "code_ISO3166_1_a2": "FK", "code_ISO3166_1_a3": "FLK", "value": "Falkland Islands (Malvinas)" },
        { "code_UNM49": "UNM49:239", "code_ISO3166_1_a2": "GS", "code_ISO3166_1_a3": "SGS", "value": "South Georgia and the South Sandwich Islands" },
        { "code_UNM49": "UNM49:242", "code_ISO3166_1_a2": "FJ", "code_ISO3166_1_a3": "FJI", "value": "Fiji" },
        { "code_UNM49": "UNM49:246", "code_ISO3166_1_a2": "FI", "code_ISO3166_1_a3": "FIN", "value": "Finland" },
        { "code_UNM49": "UNM49:248", "code_ISO3166_1_a2": "AX", "code_ISO3166_1_a3": "ALA", "value": "Aland Islands" },
        { "code_UNM49": "UNM49:250", "code_ISO3166_1_a2": "FR", "code_ISO3166_1_a3": "FRA", "value": "France" },
        { "code_UNM49": "UNM49:254", "code_ISO3166_1_a2": "GF", "code_ISO3166_1_a3": "GUF", "value": "French Guiana" },
        { "code_UNM49": "UNM49:258", "code_ISO3166_1_a2": "PF", "code_ISO3166_1_a3": "PYF", "value": "French Polynesia" },
        { "code_UNM49": "UNM49:260", "code_ISO3166_1_a2": "TF", "code_ISO3166_1_a3": "ATF", "value": "French Southern Territories" },
        { "code_UNM49": "UNM49:262", "code_ISO3166_1_a2": "DJ", "code_ISO3166_1_a3": "DJI", "value": "Djibouti" },
        { "code_UNM49": "UNM49:266", "code_ISO3166_1_a2": "GA", "code_ISO3166_1_a3": "GAB", "value": "Gabon" },
        { "code_UNM49": "UNM49:268", "code_ISO3166_1_a2": "GE", "code_ISO3166_1_a3": "GEO", "value": "Georgia" },
        { "code_UNM49": "UNM49:270", "code_ISO3166_1_a2": "GM", "code_ISO3166_1_a3": "GMB", "value": "Gambia" },
        { "code_UNM49": "UNM49:275", "code_ISO3166_1_a2": "PS", "code_ISO3166_1_a3": "PSE", "value": "State of Palestine" },
        { "code_UNM49": "UNM49:276", "code_ISO3166_1_a2": "DE", "code_ISO3166_1_a3": "DEU", "value": "Germany" },
        { "code_UNM49": "UNM49:288", "code_ISO3166_1_a2": "GH", "code_ISO3166_1_a3": "GHA", "value": "Ghana" },
        { "code_UNM49": "UNM49:292", "code_ISO3166_1_a2": "GI", "code_ISO3166_1_a3": "GIB", "value": "Gibraltar" },
        { "code_UNM49": "UNM49:296", "code_ISO3166_1_a2": "KI", "code_ISO3166_1_a3": "KIR", "value": "Kiribati" },
        { "code_UNM49": "UNM49:300", "code_ISO3166_1_a2": "GR", "code_ISO3166_1_a3": "GRC", "value": "Greece" },
        { "code_UNM49": "UNM49:304", "code_ISO3166_1_a2": "GL", "code_ISO3166_1_a3": "GRL", "value": "Greenland" },
        { "code_UNM49": "UNM49:308", "code_ISO3166_1_a2": "GD", "code_ISO3166_1_a3": "GRD", "value": "Grenada" },
        { "code_UNM49": "UNM49:312", "code_ISO3166_1_a2": "GP", "code_ISO3166_1_a3": "GLP", "value": "Guadeloupe" },
        { "code_UNM49": "UNM49:316", "code_ISO3166_1_a2": "GU", "code_ISO3166_1_a3": "GUM", "value": "Guam" },
        { "code_UNM49": "UNM49:320", "code_ISO3166_1_a2": "GT", "code_ISO3166_1_a3": "GTM", "value": "Guatemala" },
        { "code_UNM49": "UNM49:324", "code_ISO3166_1_a2": "GN", "code_ISO3166_1_a3": "GIN", "value": "Guinea" },
        { "code_UNM49": "UNM49:328", "code_ISO3166_1_a2": "GY", "code_ISO3166_1_a3": "GUY", "value": "Guyana" },
        { "code_UNM49": "UNM49:332", "code_ISO3166_1_a2": "HT", "code_ISO3166_1_a3": "HTI", "value": "Haiti" },
        { "code_UNM49": "UNM49:334", "code_ISO3166_1_a2": "HM", "code_ISO3166_1_a3": "HMD", "value": "Heard Island and McDonald Islands" },
        { "code_UNM49": "UNM49:336", "code_ISO3166_1_a2": "VA", "code_ISO3166_1_a3": "VAT", "value": "Vatican City State" },
        { "code_UNM49": "UNM49:340", "code_ISO3166_1_a2": "HN", "code_ISO3166_1_a3": "HND", "value": "Honduras" },
        { "code_UNM49": "UNM49:344", "code_ISO3166_1_a2": "HK", "code_ISO3166_1_a3": "HKG", "value": "Hong Kong" },
        { "code_UNM49": "UNM49:348", "code_ISO3166_1_a2": "HU", "code_ISO3166_1_a3": "HUN", "value": "Hungary" },
        { "code_UNM49": "UNM49:352", "code_ISO3166_1_a2": "IS", "code_ISO3166_1_a3": "ISL", "value": "Iceland" },
        { "code_UNM49": "UNM49:356", "code_ISO3166_1_a2": "IN", "code_ISO3166_1_a3": "IND", "value": "India" },
        { "code_UNM49": "UNM49:360", "code_ISO3166_1_a2": "ID", "code_ISO3166_1_a3": "IDN", "value": "Indonesia" },
        { "code_UNM49": "UNM49:364", "code_ISO3166_1_a2": "IR", "code_ISO3166_1_a3": "IRN", "value": "Iran" },
        { "code_UNM49": "UNM49:368", "code_ISO3166_1_a2": "IQ", "code_ISO3166_1_a3": "IRQ", "value": "Iraq" },
        { "code_UNM49": "UNM49:372", "code_ISO3166_1_a2": "IE", "code_ISO3166_1_a3": "IRL", "value": "Ireland" },
        { "code_UNM49": "UNM49:376", "code_ISO3166_1_a2": "IL", "code_ISO3166_1_a3": "ISR", "value": "Israel" },
        { "code_UNM49": "UNM49:380", "code_ISO3166_1_a2": "IT", "code_ISO3166_1_a3": "ITA", "value": "Italy" },
        { "code_UNM49": "UNM49:384", "code_ISO3166_1_a2": "CI", "code_ISO3166_1_a3": "CIV", "value": "Côte d’Ivoire" },
        { "code_UNM49": "UNM49:388", "code_ISO3166_1_a2": "JM", "code_ISO3166_1_a3": "JAM", "value": "Jamaica" },
        { "code_UNM49": "UNM49:392", "code_ISO3166_1_a2": "JP", "code_ISO3166_1_a3": "JPN", "value": "Japan" },
        { "code_UNM49": "UNM49:398", "code_ISO3166_1_a2": "KZ", "code_ISO3166_1_a3": "KAZ", "value": "Kazakhstan" },
        { "code_UNM49": "UNM49:400", "code_ISO3166_1_a2": "JO", "code_ISO3166_1_a3": "JOR", "value": "Jordan" },
        { "code_UNM49": "UNM49:404", "code_ISO3166_1_a2": "KE", "code_ISO3166_1_a3": "KEN", "value": "Kenya" },
        { "code_UNM49": "UNM49:408", "code_ISO3166_1_a2": "KP", "code_ISO3166_1_a3": "PRK", "value": "Democratic People''s Republic of Korea" },
        { "code_UNM49": "UNM49:410", "code_ISO3166_1_a2": "KR", "code_ISO3166_1_a3": "KOR", "value": "Korea (the Republic of)" },
        { "code_UNM49": "UNM49:414", "code_ISO3166_1_a2": "KW", "code_ISO3166_1_a3": "KWT", "value": "Kuwait" },
        { "code_UNM49": "UNM49:417", "code_ISO3166_1_a2": "KG", "code_ISO3166_1_a3": "KGZ", "value": "Kyrgyzstan" },
        { "code_UNM49": "UNM49:418", "code_ISO3166_1_a2": "LA", "code_ISO3166_1_a3": "LAO", "value": "Laos" },
        { "code_UNM49": "UNM49:422", "code_ISO3166_1_a2": "LB", "code_ISO3166_1_a3": "LBN", "value": "Lebanon" },
        { "code_UNM49": "UNM49:426", "code_ISO3166_1_a2": "LS", "code_ISO3166_1_a3": "LSO", "value": "Lesotho" },
        { "code_UNM49": "UNM49:428", "code_ISO3166_1_a2": "LV", "code_ISO3166_1_a3": "LVA", "value": "Latvia" },
        { "code_UNM49": "UNM49:430", "code_ISO3166_1_a2": "LR", "code_ISO3166_1_a3": "LBR", "value": "Liberia" },
        { "code_UNM49": "UNM49:434", "code_ISO3166_1_a2": "LY", "code_ISO3166_1_a3": "LBY", "value": "Libya" },
        { "code_UNM49": "UNM49:438", "code_ISO3166_1_a2": "LI", "code_ISO3166_1_a3": "LIE", "value": "Liechtenstein" },
        { "code_UNM49": "UNM49:440", "code_ISO3166_1_a2": "LT", "code_ISO3166_1_a3": "LTU", "value": "Lithuania" },
        { "code_UNM49": "UNM49:442", "code_ISO3166_1_a2": "LU", "code_ISO3166_1_a3": "LUX", "value": "Luxembourg" },
        { "code_UNM49": "UNM49:446", "code_ISO3166_1_a2": "MO", "code_ISO3166_1_a3": "MAC", "value": "Macao" },
        { "code_UNM49": "UNM49:450", "code_ISO3166_1_a2": "MG", "code_ISO3166_1_a3": "MDG", "value": "Madagascar" },
        { "code_UNM49": "UNM49:454", "code_ISO3166_1_a2": "MW", "code_ISO3166_1_a3": "MWI", "value": "Malawi" },
        { "code_UNM49": "UNM49:458", "code_ISO3166_1_a2": "MY", "code_ISO3166_1_a3": "MYS", "value": "Malaysia" },
        { "code_UNM49": "UNM49:462", "code_ISO3166_1_a2": "MV", "code_ISO3166_1_a3": "MDV", "value": "Maldives" },
        { "code_UNM49": "UNM49:466", "code_ISO3166_1_a2": "ML", "code_ISO3166_1_a3": "MLI", "value": "Mali" },
        { "code_UNM49": "UNM49:470", "code_ISO3166_1_a2": "MT", "code_ISO3166_1_a3": "MLT", "value": "Malta" },
        { "code_UNM49": "UNM49:474", "code_ISO3166_1_a2": "MQ", "code_ISO3166_1_a3": "MTQ", "value": "Martinique" },
        { "code_UNM49": "UNM49:478", "code_ISO3166_1_a2": "MR", "code_ISO3166_1_a3": "MRT", "value": "Mauritania" },
        { "code_UNM49": "UNM49:480", "code_ISO3166_1_a2": "MU", "code_ISO3166_1_a3": "MUS", "value": "Mauritius" },
        { "code_UNM49": "UNM49:484", "code_ISO3166_1_a2": "MX", "code_ISO3166_1_a3": "MEX", "value": "Mexico" },
        { "code_UNM49": "UNM49:492", "code_ISO3166_1_a2": "MC", "code_ISO3166_1_a3": "MCO", "value": "Monaco" },
        { "code_UNM49": "UNM49:496", "code_ISO3166_1_a2": "MN", "code_ISO3166_1_a3": "MNG", "value": "Mongolia" },
        { "code_UNM49": "UNM49:498", "code_ISO3166_1_a2": "MD", "code_ISO3166_1_a3": "MDA", "value": "Moldova" },
        { "code_UNM49": "UNM49:499", "code_ISO3166_1_a2": "ME", "code_ISO3166_1_a3": "MNE", "value": "Montenegro" },
        { "code_UNM49": "UNM49:500", "code_ISO3166_1_a2": "MS", "code_ISO3166_1_a3": "MSR", "value": "Montserrat" },
        { "code_UNM49": "UNM49:504", "code_ISO3166_1_a2": "MA", "code_ISO3166_1_a3": "MAR", "value": "Morocco" },
        { "code_UNM49": "UNM49:508", "code_ISO3166_1_a2": "MZ", "code_ISO3166_1_a3": "MOZ", "value": "Mozambique" },
        { "code_UNM49": "UNM49:512", "code_ISO3166_1_a2": "OM", "code_ISO3166_1_a3": "OMN", "value": "Oman" },
        { "code_UNM49": "UNM49:516", "code_ISO3166_1_a2": "NA", "code_ISO3166_1_a3": "NAM", "value": "Namibia" },
        { "code_UNM49": "UNM49:520", "code_ISO3166_1_a2": "NR", "code_ISO3166_1_a3": "NRU", "value": "Nauru" },
        { "code_UNM49": "UNM49:524", "code_ISO3166_1_a2": "NP", "code_ISO3166_1_a3": "NPL", "value": "Nepal" },
        { "code_UNM49": "UNM49:528", "code_ISO3166_1_a2": "NL", "code_ISO3166_1_a3": "NLD", "value": "Netherlands" },
        { "code_UNM49": "UNM49:531", "code_ISO3166_1_a2": "CW", "code_ISO3166_1_a3": "CUW", "value": "Curaçao" },
        { "code_UNM49": "UNM49:533", "code_ISO3166_1_a2": "AW", "code_ISO3166_1_a3": "ABW", "value": "Aruba" },
        { "code_UNM49": "UNM49:534", "code_ISO3166_1_a2": "SX", "code_ISO3166_1_a3": "SXM", "value": "Sint Maarten (Dutch part)" },
        { "code_UNM49": "UNM49:535", "code_ISO3166_1_a2": "BQ", "code_ISO3166_1_a3": "BES", "value": "Bonaire, Sint Eustatius and Saba" },
        { "code_UNM49": "UNM49:540", "code_ISO3166_1_a2": "NC", "code_ISO3166_1_a3": "NCL", "value": "New Caledonia" },
        { "code_UNM49": "UNM49:548", "code_ISO3166_1_a2": "VU", "code_ISO3166_1_a3": "VUT", "value": "Vanuatu" },
        { "code_UNM49": "UNM49:554", "code_ISO3166_1_a2": "NZ", "code_ISO3166_1_a3": "NZL", "value": "New Zealand" },
        { "code_UNM49": "UNM49:558", "code_ISO3166_1_a2": "NI", "code_ISO3166_1_a3": "NIC", "value": "Nicaragua" },
        { "code_UNM49": "UNM49:562", "code_ISO3166_1_a2": "NE", "code_ISO3166_1_a3": "NER", "value": "Niger" },
        { "code_UNM49": "UNM49:566", "code_ISO3166_1_a2": "NG", "code_ISO3166_1_a3": "NGA", "value": "Nigeria" },
        { "code_UNM49": "UNM49:570", "code_ISO3166_1_a2": "NU", "code_ISO3166_1_a3": "NIU", "value": "Niue" },
        { "code_UNM49": "UNM49:574", "code_ISO3166_1_a2": "NF", "code_ISO3166_1_a3": "NFK", "value": "Norfolk Island" },
        { "code_UNM49": "UNM49:578", "code_ISO3166_1_a2": "NO", "code_ISO3166_1_a3": "NOR", "value": "Norway" },
        { "code_UNM49": "UNM49:580", "code_ISO3166_1_a2": "MP", "code_ISO3166_1_a3": "MNP", "value": "Northern Mariana Islands" },
        { "code_UNM49": "UNM49:581", "code_ISO3166_1_a2": "UM", "code_ISO3166_1_a3": "UMI", "value": "United States Minor Outlying Islands" },
        { "code_UNM49": "UNM49:583", "code_ISO3166_1_a2": "FM", "code_ISO3166_1_a3": "FSM", "value": "Micronesia (Federated States of)" },
        { "code_UNM49": "UNM49:584", "code_ISO3166_1_a2": "MH", "code_ISO3166_1_a3": "MHL", "value": "Marshall Islands" },
        { "code_UNM49": "UNM49:585", "code_ISO3166_1_a2": "PW", "code_ISO3166_1_a3": "PLW", "value": "Palau" },
        { "code_UNM49": "UNM49:586", "code_ISO3166_1_a2": "PK", "code_ISO3166_1_a3": "PAK", "value": "Pakistan" },
        { "code_UNM49": "UNM49:591", "code_ISO3166_1_a2": "PA", "code_ISO3166_1_a3": "PAN", "value": "Panama" },
        { "code_UNM49": "UNM49:598", "code_ISO3166_1_a2": "PG", "code_ISO3166_1_a3": "PNG", "value": "Papua New Guinea" },
        { "code_UNM49": "UNM49:600", "code_ISO3166_1_a2": "PY", "code_ISO3166_1_a3": "PRY", "value": "Paraguay" },
        { "code_UNM49": "UNM49:604", "code_ISO3166_1_a2": "PE", "code_ISO3166_1_a3": "PER", "value": "Peru" },
        { "code_UNM49": "UNM49:608", "code_ISO3166_1_a2": "PH", "code_ISO3166_1_a3": "PHL", "value": "Philippines" },
        { "code_UNM49": "UNM49:612", "code_ISO3166_1_a2": "PN", "code_ISO3166_1_a3": "PCN", "value": "Pitcairn" },
        { "code_UNM49": "UNM49:616", "code_ISO3166_1_a2": "PL", "code_ISO3166_1_a3": "POL", "value": "Poland" },
        { "code_UNM49": "UNM49:620", "code_ISO3166_1_a2": "PT", "code_ISO3166_1_a3": "PRT", "value": "Portugal" },
        { "code_UNM49": "UNM49:624", "code_ISO3166_1_a2": "GW", "code_ISO3166_1_a3": "GNB", "value": "Guinea-Bissau" },
        { "code_UNM49": "UNM49:626", "code_ISO3166_1_a2": "TL", "code_ISO3166_1_a3": "TLS", "value": "Timor-Leste" },
        { "code_UNM49": "UNM49:630", "code_ISO3166_1_a2": "PR", "code_ISO3166_1_a3": "PRI", "value": "Puerto Rico" },
        { "code_UNM49": "UNM49:634", "code_ISO3166_1_a2": "QA", "code_ISO3166_1_a3": "QAT", "value": "Qatar" },
        { "code_UNM49": "UNM49:638", "code_ISO3166_1_a2": "RE", "code_ISO3166_1_a3": "REU", "value": "Réunion" },
        { "code_UNM49": "UNM49:642", "code_ISO3166_1_a2": "RO", "code_ISO3166_1_a3": "ROU", "value": "Romania" },
        { "code_UNM49": "UNM49:643", "code_ISO3166_1_a2": "RU", "code_ISO3166_1_a3": "RUS", "value": "Russia" },
        { "code_UNM49": "UNM49:646", "code_ISO3166_1_a2": "RW", "code_ISO3166_1_a3": "RWA", "value": "Rwanda" },
        { "code_UNM49": "UNM49:652", "code_ISO3166_1_a2": "BL", "code_ISO3166_1_a3": "BLM", "value": "Saint Barthélemy" },
        { "code_UNM49": "UNM49:654", "code_ISO3166_1_a2": "SH", "code_ISO3166_1_a3": "SHN", "value": "Saint Helena" },
        { "code_UNM49": "UNM49:659", "code_ISO3166_1_a2": "KN", "code_ISO3166_1_a3": "KNA", "value": "Saint Kitts and Nevis" },
        { "code_UNM49": "UNM49:660", "code_ISO3166_1_a2": "AI", "code_ISO3166_1_a3": "AIA", "value": "Anguilla" },
        { "code_UNM49": "UNM49:662", "code_ISO3166_1_a2": "LC", "code_ISO3166_1_a3": "LCA", "value": "Saint Lucia" },
        { "code_UNM49": "UNM49:663", "code_ISO3166_1_a2": "MF", "code_ISO3166_1_a3": "MAF", "value": "Saint Martin (French Part)" },
        { "code_UNM49": "UNM49:666", "code_ISO3166_1_a2": "PM", "code_ISO3166_1_a3": "SPM", "value": "Saint Pierre and Miquelon" },
        { "code_UNM49": "UNM49:670", "code_ISO3166_1_a2": "VC", "code_ISO3166_1_a3": "VCT", "value": "Saint Vincent and the Grenadines" },
        { "code_UNM49": "UNM49:674", "code_ISO3166_1_a2": "SM", "code_ISO3166_1_a3": "SMR", "value": "San Marino" },
        { "code_UNM49": "UNM49:678", "code_ISO3166_1_a2": "ST", "code_ISO3166_1_a3": "STP", "value": "Sao Tome and Principe" },
        { "code_UNM49": "UNM49:682", "code_ISO3166_1_a2": "SA", "code_ISO3166_1_a3": "SAU", "value": "Saudi Arabia" },
        { "code_UNM49": "UNM49:686", "code_ISO3166_1_a2": "SN", "code_ISO3166_1_a3": "SEN", "value": "Senegal" },
        { "code_UNM49": "UNM49:688", "code_ISO3166_1_a2": "RS", "code_ISO3166_1_a3": "SRB", "value": "Serbia" },
        { "code_UNM49": "UNM49:690", "code_ISO3166_1_a2": "SC", "code_ISO3166_1_a3": "SYC", "value": "Seychelles" },
        { "code_UNM49": "UNM49:694", "code_ISO3166_1_a2": "SL", "code_ISO3166_1_a3": "SLE", "value": "Sierra Leone" },
        { "code_UNM49": "UNM49:702", "code_ISO3166_1_a2": "SG", "code_ISO3166_1_a3": "SGP", "value": "Singapore" },
        { "code_UNM49": "UNM49:703", "code_ISO3166_1_a2": "SK", "code_ISO3166_1_a3": "SVK", "value": "Slovakia" },
        { "code_UNM49": "UNM49:704", "code_ISO3166_1_a2": "VN", "code_ISO3166_1_a3": "VNM", "value": "Viet Nam" },
        { "code_UNM49": "UNM49:705", "code_ISO3166_1_a2": "SI", "code_ISO3166_1_a3": "SVN", "value": "Slovenia" },
        { "code_UNM49": "UNM49:706", "code_ISO3166_1_a2": "SO", "code_ISO3166_1_a3": "SOM", "value": "Somalia" },
        { "code_UNM49": "UNM49:710", "code_ISO3166_1_a2": "ZA", "code_ISO3166_1_a3": "ZAF", "value": "South Africa" },
        { "code_UNM49": "UNM49:716", "code_ISO3166_1_a2": "ZW", "code_ISO3166_1_a3": "ZWE", "value": "Zimbabwe" },
        { "code_UNM49": "UNM49:724", "code_ISO3166_1_a2": "ES", "code_ISO3166_1_a3": "ESP", "value": "Spain" },
        { "code_UNM49": "UNM49:728", "code_ISO3166_1_a2": "SS", "code_ISO3166_1_a3": "SSD", "value": "South Sudan" },
        { "code_UNM49": "UNM49:729", "code_ISO3166_1_a2": "SD", "code_ISO3166_1_a3": "SDN", "value": "Sudan" },
        { "code_UNM49": "UNM49:732", "code_ISO3166_1_a2": "EH", "code_ISO3166_1_a3": "ESH", "value": "Western Sahara" },
        { "code_UNM49": "UNM49:740", "code_ISO3166_1_a2": "SR", "code_ISO3166_1_a3": "SUR", "value": "Suriname" },
        { "code_UNM49": "UNM49:744", "code_ISO3166_1_a2": "SJ", "code_ISO3166_1_a3": "SJM", "value": "Svalbard and Jan Mayen Islands" },
        { "code_UNM49": "UNM49:748", "code_ISO3166_1_a2": "SZ", "code_ISO3166_1_a3": "SWZ", "value": "Eswatini" },
        { "code_UNM49": "UNM49:752", "code_ISO3166_1_a2": "SE", "code_ISO3166_1_a3": "SWE", "value": "Sweden" },
        { "code_UNM49": "UNM49:756", "code_ISO3166_1_a2": "CH", "code_ISO3166_1_a3": "CHE", "value": "Switzerland" },
        { "code_UNM49": "UNM49:760", "code_ISO3166_1_a2": "SY", "code_ISO3166_1_a3": "SYR", "value": "Syria" },
        { "code_UNM49": "UNM49:762", "code_ISO3166_1_a2": "TJ", "code_ISO3166_1_a3": "TJK", "value": "Tajikistan" },
        { "code_UNM49": "UNM49:764", "code_ISO3166_1_a2": "TH", "code_ISO3166_1_a3": "THA", "value": "Thailand" },
        { "code_UNM49": "UNM49:768", "code_ISO3166_1_a2": "TG", "code_ISO3166_1_a3": "TGO", "value": "Togo" },
        { "code_UNM49": "UNM49:772", "code_ISO3166_1_a2": "TK", "code_ISO3166_1_a3": "TKL", "value": "Tokelau" },
        { "code_UNM49": "UNM49:776", "code_ISO3166_1_a2": "TO", "code_ISO3166_1_a3": "TON", "value": "Tonga" },
        { "code_UNM49": "UNM49:780", "code_ISO3166_1_a2": "TT", "code_ISO3166_1_a3": "TTO", "value": "Trinidad and Tobago" },
        { "code_UNM49": "UNM49:784", "code_ISO3166_1_a2": "AE", "code_ISO3166_1_a3": "ARE", "value": "United Arab Emirates" },
        { "code_UNM49": "UNM49:788", "code_ISO3166_1_a2": "TN", "code_ISO3166_1_a3": "TUN", "value": "Tunisia" },
        { "code_UNM49": "UNM49:792", "code_ISO3166_1_a2": "TR", "code_ISO3166_1_a3": "TUR", "value": "Turkey" },
        { "code_UNM49": "UNM49:795", "code_ISO3166_1_a2": "TM", "code_ISO3166_1_a3": "TKM", "value": "Turkmenistan" },
        { "code_UNM49": "UNM49:796", "code_ISO3166_1_a2": "TC", "code_ISO3166_1_a3": "TCA", "value": "Turks and Caicos Islands" },
        { "code_UNM49": "UNM49:798", "code_ISO3166_1_a2": "TV", "code_ISO3166_1_a3": "TUV", "value": "Tuvalu" },
        { "code_UNM49": "UNM49:800", "code_ISO3166_1_a2": "UG", "code_ISO3166_1_a3": "UGA", "value": "Uganda" },
        { "code_UNM49": "UNM49:804", "code_ISO3166_1_a2": "UA", "code_ISO3166_1_a3": "UKR", "value": "Ukraine" },
        { "code_UNM49": "UNM49:807", "code_ISO3166_1_a2": "MK", "code_ISO3166_1_a3": "MKD", "value": "North Macedonia" },
        { "code_UNM49": "UNM49:818", "code_ISO3166_1_a2": "EG", "code_ISO3166_1_a3": "EGY", "value": "Egypt" },
        { "code_UNM49": "UNM49:826", "code_ISO3166_1_a2": "GB", "code_ISO3166_1_a3": "GBR", "value": "United Kingdom" },
        { "code_UNM49": "UNM49:831", "code_ISO3166_1_a2": "GG", "code_ISO3166_1_a3": "GGY", "value": "Guernsey" },
        { "code_UNM49": "UNM49:832", "code_ISO3166_1_a2": "JE", "code_ISO3166_1_a3": "JEY", "value": "Jersey" },
        { "code_UNM49": "UNM49:833", "code_ISO3166_1_a2": "IM", "code_ISO3166_1_a3": "IMN", "value": "Isle of Man" },
        { "code_UNM49": "UNM49:834", "code_ISO3166_1_a2": "TZ", "code_ISO3166_1_a3": "TZA", "value": "Tanzania" },
        { "code_UNM49": "UNM49:840", "code_ISO3166_1_a2": "US", "code_ISO3166_1_a3": "USA", "value": "United States of America" },
        { "code_UNM49": "UNM49:850", "code_ISO3166_1_a2": "VI", "code_ISO3166_1_a3": "VIR", "value": "United States Virgin Islands" },
        { "code_UNM49": "UNM49:854", "code_ISO3166_1_a2": "BF", "code_ISO3166_1_a3": "BFA", "value": "Burkina Faso" },
        { "code_UNM49": "UNM49:858", "code_ISO3166_1_a2": "UY", "code_ISO3166_1_a3": "URY", "value": "Uruguay" },
        { "code_UNM49": "UNM49:860", "code_ISO3166_1_a2": "UZ", "code_ISO3166_1_a3": "UZB", "value": "Uzbekistan" },
        { "code_UNM49": "UNM49:862", "code_ISO3166_1_a2": "VE ", "code_ISO3166_1_a3": "VEN", "value": "Venezuela" },
        { "code_UNM49": "UNM49:876", "code_ISO3166_1_a2": "WF", "code_ISO3166_1_a3": "WLF", "value": "Wallis and Futuna Islands" },
        { "code_UNM49": "UNM49:882", "code_ISO3166_1_a2": "WS", "code_ISO3166_1_a3": "WSM", "value": "Samoa" },
        { "code_UNM49": "UNM49:887", "code_ISO3166_1_a2": "YE", "code_ISO3166_1_a3": "YEM", "value": "Yemen" },
        { "code_UNM49": "UNM49:894", "code_ISO3166_1_a2": "ZM", "code_ISO3166_1_a3": "ZMB", "value": "Zambia" }
    ];


    useEffect(() => {

        am4core.addLicense("MP289451215");
        //-----------------------
        am4core.useTheme(am4themes_animated);
        //-----------------------
        let chart = am4core.create(props.chartId, am4maps.MapChart);
        //------------------
        let selected_countries = [];
        let newArr = [...props.chartData]
        newArr.forEach(obj => selected_countries.push(obj.code_ISO3166_1_a2))
        //------------------
        chart.geodata = am4geodata_worldHigh;
        chart.projection = new am4maps.projections.Miller();
        //------------------
        let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        polygonSeries.useGeodata = true;
        //polygonSeries.calculateVisualCenter = true;
        //------------------
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "";
        polygonTemplate.polygon.fillOpacity = 0.6;
        polygonTemplate.tooltipPosition = "fixed";

        let activeCountry = polygonTemplate.states.create("active");
        activeCountry.properties.fill = am4core.color("#5583C4");

        if(props.callback) {

            activeCountry.properties.tooltipText = "[bold]{frequency}[/] \n{name}\n\n (click to filter)";

            polygonTemplate.events.on("hit", function(ev) {

                if (ev.target.isActive) {

                    let code_UNM49 = "";

                    let resultCHARTClick = {
                        term: ev.target.dataItem.dataContext.name,
                        type: props.filterType
                    };

                    if (props.useVOC === "true") {



                        for (let i = 0; i < varCOUNTRIES.length; i++) {
                            if (varCOUNTRIES[i].code_ISO3166_1_a2 === ev.target.dataItem.dataContext.id) {
                                code_UNM49 = varCOUNTRIES[i].code_UNM49;
                            }
                        }

                        resultCHARTClick = {
                            term: code_UNM49,
                            type: props.filterType
                        };
                    }

                    if(props.callback){
                        if (code_UNM49!=="") {
                            props.callback(resultCHARTClick);
                        }
                    }

                }

            });

        } else {
            activeCountry.properties.tooltipText = "{name}";
        }

        let north, south, west, east;

        chart.events.on("ready", function(ev) {

            // Find extreme coordinates for all pre-zoom countries

            if(selected_countries){
                for(let i = 0; i < selected_countries.length; i++) {
                    let country = polygonSeries.getPolygonById(selected_countries[i]);

                    if (country) {
                        if (country.north) {
                            if (north === undefined || (country.north > north)) {
                                north = country.north;
                            }
                        }
                        //----------------------------------
                        if (country.south) {
                            if (south === undefined || (country.south < south)) {
                                south = country.south;
                            }
                        }
                        //----------------------------------
                        if (country.west) {
                            if (west === undefined || (country.west < west)) {
                                west = country.west;
                            }
                        }
                        //----------------------------------
                        if (country.east) {
                            if (east === undefined || (country.east > east)) {
                                east = country.east;
                            }
                        }

                        country.isActive = true;

                    }

                }
                // Pre-zoom
                chart.zoomToRectangle(north, east, south, west, 1, true);
            }

        });

        chart.smallMap = new am4maps.SmallMap();
        chart.smallMap.series.push(polygonSeries);

        chart.smallMap.rectangle.stroke = am4core.color("#5583C4");
        chart.smallMap.rectangle.strokeWidth = 2;

        chart.smallMap.background.stroke = am4core.color("#666666")
        chart.smallMap.background.strokeOpacity = 0.9;
        chart.smallMap.background.strokeWidth = 1;


        chart.smallMap.align = "left";
        chart.smallMap.valign = "top";

        let smallSeries = chart.smallMap.series.getIndex(0);
        smallSeries.mapPolygons.template.stroke = smallSeries.mapPolygons.template.fill;
        smallSeries.mapPolygons.template.strokeWidth = 1;

        chart.zoomControl = new am4maps.ZoomControl();

        let homeButton = new am4core.Button();
        homeButton.events.on("hit", function(){
            //chart.goHome();
            chart.zoomToRectangle(north, east, south, west, 1, true);
        });

        homeButton.icon = new am4core.Sprite();
        homeButton.padding(7, 5, 7, 5);
        homeButton.width = 30;
        homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
        homeButton.marginBottom = 10;
        homeButton.parent = chart.zoomControl;
        homeButton.insertBefore(chart.zoomControl.plusButton);

    }, [props.filter_query, props.chartData]);

    if (props.chartTitle) {
        return (
            <div style={{textAlign:"center"}}>
                <h4 style={{margin: "0px"}}>{props.chartTitle}</h4>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }else{
        return (
            <div>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }

}
