import React, {useEffect, useState} from "react";

export const Div_TAgent = (props) => {
    const [divs, setDivs] = useState(null);

    useEffect(() => {

        displayAgents(props.data);

    }, []);

    const  displayAgents = (data) => {
        let newArrayList = [];
        let duplicatesCheck = [];
        data.forEach(agent  => {
            let check = agent.full_name;
            if (duplicatesCheck.indexOf(check)<0) {
                duplicatesCheck.push(check);
                //--------------------
                if (props.isVertical === "true") {

                    let divobj =
                        <div className="margin-bottom" >
                            { (agent.email) && (agent.email !== "") ?
                                <a href={"mailto:" + agent.email}>
                                    <i className="fad fa-envelope fa-lg" style={{marginRight: "5px"}}></i>
                                </a>
                                :console.log()
                            }
                            { (agent.url)&&(agent.url!=="")?
                                <a href={agent.url} target={"_blank"}  rel="noreferrer">{agent.full_name}</a>
                                :
                                <span>{agent.full_name}</span>
                            }

                            { (agent.agent_ids)?
                                agent.agent_ids.map( (item) => {

                                    if (item.scheme==="ROR") {
                                        let urlID = 'https://ror.org/' + item.value;
                                        return (
                                            <a href={urlID} target={"_blank"}  rel="noreferrer"> <sup><i className="fab fa-orcid fa-lg" style={{color: "#000"}}></i></sup></a>
                                        ) ;
                                    }

                                    //----------------------------------------------

                                    if (item.scheme==="ORCID") {
                                        let urlID = 'https://orcid.org/' + item.value;
                                        return (
                                            <a href={urlID} target={"_blank"}  rel="noreferrer"> <sup><i className="fab fa-orcid fa-lg" style={{color: "#82c91e"}}></i></sup></a>
                                        ) ;
                                    }

                                })
                              :console.log()
                            }
                        </div>;

                    newArrayList.push(divobj);

                }else{

                    let divobj =
                        <span className="agent" >
                            { (agent.url)&&(agent.url!=="")?
                                <a href={agent.url} target={"_blank"}  rel="noreferrer">{agent.full_name}</a>
                                :
                                <span>{agent.full_name}</span>
                            }

                            { (agent.agent_ids)?
                                agent.agent_ids.map( (item) => {

                                    if (item.scheme==="ROR") {
                                        let urlID = 'https://ror.org/' + item.value;
                                        return (
                                            <a href={urlID} target={"_blank"}  rel="noreferrer"> <sup><i className="fab fa-orcid fa-lg" style={{color: "#000"}}></i></sup></a>
                                        ) ;
                                    }

                                    //----------------------------------------------

                                    if (item.scheme==="ORCID") {
                                        let urlID = 'https://orcid.org/' + item.value;
                                        return (
                                            <a href={urlID} target={"_blank"}  rel="noreferrer"> <sup><i className="fab fa-orcid fa-lg" style={{color: "#82c91e"}}></i></sup></a>
                                        ) ;
                                    }

                                })
                                :console.log()
                            }

                        </span>;

                    newArrayList.push(divobj);

                }

            }

       })

        return setDivs(newArrayList);
    }

    return (
        <>
            {divs}
        </>

    );
}
