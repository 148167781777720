import React from 'react';
import classNames from 'classnames';
import AppMenu from './AppMenu';

const AppTopbar = (props) => {

	let menuButtonClassName = classNames('layout-menubutton ', { 'layout-menubutton-active': props.menuActive })

	const getInk = (el) => {
        for (let i = 0; i < el.children.length; i++) {
            if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
                return el.children[i];
            }
        }
        return null;
	}

	const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

	const onItemClick = (event) => {
		const ink = getInk(event.currentTarget);
		if (ink) {
			removeClass(ink, 'p-ink-active');
		}
	}

	return (
		<div className="layout-topbar">
			<button type="button" className={menuButtonClassName} onClick={props.onMenuButtonClick}>
				<div className="layout-menubutton-icon" />
			</button>

			<div className="layout-topbar-grid">
				<div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
					<button type="button" className="layout-logo p-link" onClick={() => { window.location = "/#" }}>
                        <img src="assets/layout/images/GARDIAN-LOGO-MAIN-HEADER.png" alt="gardian-layout" />
					</button>
				</div>

				<div className="layout-topbar-grid-column">
					<AppMenu model={props.model.leftMenu} horizontal={props.horizontal} menuHoverActive={props.menuHoverActive} isMobile={props.isMobile}
						onMenuItemClick={props.onMenuItemClick} onRootMenuItemClick={props.onRootMenuItemClick} onSidebarClick={props.onSidebarClick} />
				</div>

				<div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
					<span className="layout-topbar-search">
						<span className="p-input-icon-right">
							<AppMenu model={props.model.rightMenu} horizontal={props.horizontal} menuHoverActive={props.menuHoverActive} isMobile={props.isMobile}
                                     onMenuItemClick={props.onMenuItemClick} onRootMenuItemClick={props.onRootMenuItemClick} onSidebarClick={props.onSidebarClick} />
						</span>
					</span>
				</div>
			</div>
		</div>
	);
}

export default AppTopbar;
