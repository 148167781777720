(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("leaflet"));
	else if(typeof define === 'function' && define.amd)
		define(["leaflet"], factory);
	else if(typeof exports === 'object')
		exports["GeoRasterLayer"] = factory(require("leaflet"));
	else
		root["GeoRasterLayer"] = factory(root["L"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__870__) {
return 