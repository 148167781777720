import React, {useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import {Am_FairScoring} from "../components/am_FairScoring";
import {DynamicDivs} from "../components/DynamicDivs";
import {Am_TagCloud} from "../components/am_TagCloud";
import {GeographyTree} from "../components/GeographyTree";
import {Am_MapChart} from "../components/am_MapChart";
import {RelevantTable} from "../components/RelevantTable";
import {ModalAccessRights} from "../components/ModalAccessRights";
import {ResourceFilesTable} from "../components/ResourceFilesTable";
import {Tooltip} from "primereact/tooltip";
import {Div_TAgent} from "../components/div_TAgent";
import {RelatedTable} from "../components/RelatedTable";
import ReactHtmlParser from "react-html-parser";
import QvantumService from "../service/QvantumService";

export const AssetInfo = () => {
    const [assetMetadata, setAssetMetadata] = useState(null);
    const [controlledvoc, setControlledvoc] = useState([]);
    const [keywords, setKeywords] = useState([]);

    const  id   = useParams();

    useEffect(() => {
        const metadataService = new QvantumService();
        metadataService.getMetadata(id).then(data => setAssetMetadata(data));

    }, []);

    useEffect(() => {

        if (assetMetadata) {

           //console.log(assetMetadata)

            let controlledkeyws = [];
            let freekeyws = [];

            assetMetadata.keywords.forEach(item  => {

                if (item.scheme==="free") {
                    freekeyws.push(item) ;
                } else {
                    controlledkeyws.push(item) ;
                }
            })
            //-----------------------------------------------
            setControlledvoc(controlledkeyws);
            setKeywords(freekeyws);

        }

    }, [assetMetadata]);



    const renderPage = () => {

        let RelevantResHeader = "RELEVANT DATASETS";
        if  ((assetMetadata.resource_type.type).toLowerCase().includes("dataset")) {  RelevantResHeader = "RELEVANT PUBLICATIONS"; }
        //------------------------------
        return (

            <div className="p-grid p-dir-rev">
                <div className="p-col-12 p-lg-8">

                    { assetMetadata?(assetMetadata.title)&&(assetMetadata.title.length>0)?
                        <div className="card cards-margin-bottom">
                            <div className="p-type-landing">{assetMetadata.resource_type.category}</div>
                            <DynamicDivs type="titles" data={assetMetadata.title} ></DynamicDivs>

                            { (assetMetadata.providers)&&(assetMetadata.providers.length>0)?
                                <>
                                    <hr/>
                                    <p className="p-publishers-landing">PROVIDER(s)</p>
                                    <p className="p-providers-landing">
                                        <Div_TAgent isVertical="true" data={assetMetadata.providers}></Div_TAgent>
                                    </p>

                                </>
                                :console.log()
                            }

                        </div>
                        :console.log():console.log()
                    }


                    { assetMetadata? ( ((assetMetadata.authors)&&(assetMetadata.authors.length>0)) ||
                        ((assetMetadata.contact_points)&&(assetMetadata.contact_points.length>0)) )?

                        <div className="card cards-margin-bottom">
                            <div className="p-grid">

                                { ((assetMetadata.contact_points)&&(assetMetadata.contact_points.length>0)) ?
                                    <>
                                        <div className="p-col-12">
                                            <h2  className="h2-landing-authors">AUTHOR(s)</h2>
                                            <Div_TAgent isVertical="false" data={assetMetadata.authors}></Div_TAgent>
                                        </div>
                                        {/*<div className="p-col">*/}
                                        {/*    <h2 className="h2-landing-contact">CONTACT POINT(s)</h2>*/}
                                        {/*    <Div_TAgent isVertical="true" data={assetMetadata.contact_points}></Div_TAgent>*/}

                                        {/*</div>*/}
                                    </>
                                    :
                                    <div className="p-col">
                                        <h2  className="h2-landing-authors">AUTHOR(s)</h2>
                                        <Div_TAgent isVertical="false" data={assetMetadata.authors}></Div_TAgent>
                                    </div>
                                }

                            </div>
                        </div>

                        :console.log():console.log()
                    }


                    { assetMetadata?(assetMetadata.description)&&(assetMetadata.description.length>0)?
                        <div className="card cards-margin-bottom" style={{textAlign:"justify"}}>
                            <h2 className="h2-summary-landing">SUMMARY</h2>
                            <DynamicDivs type="summaries" data={assetMetadata.description} ></DynamicDivs>
                        </div>
                        :console.log():console.log()
                    }



                    {   assetMetadata?(assetMetadata.methodology)&&
                    ( ((assetMetadata.methodology.unit_of_analysis)&&(assetMetadata.methodology.unit_of_analysis.length>0)) ||
                        ((assetMetadata.methodology.data_collection_frequency)&&(assetMetadata.methodology.data_collection_frequency!=='')) )?

                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                {
                                    ((assetMetadata.methodology.unit_of_analysis)&&(assetMetadata.methodology.unit_of_analysis.length>0))?

                                        ((assetMetadata.methodology.data_collection_frequency) && (assetMetadata.methodology.data_collection_frequency !== '')) ?

                                            <div className="p-col-12 p-md-6">
                                                <h2 className="h2-summary-landing">UNIT OF ANALYSIS</h2>
                                                <DynamicDivs type="text-value" data={assetMetadata.methodology.unit_of_analysis}></DynamicDivs>

                                            </div>
                                            : <div className="p-col">
                                                <h2 className="h2-summary-landing">UNIT OF ANALYSIS</h2>
                                                <DynamicDivs type="text-value" data={assetMetadata.methodology.unit_of_analysis}></DynamicDivs>
                                            </div>
                                        :console.log()
                                }

                                {
                                    (assetMetadata.methodology.data_collection_frequency)&&(assetMetadata.methodology.data_collection_frequency!=='')?
                                        <div  className="p-col">

                                            <h2 className="h2-summary-landing">DATA COLLECTION FREQUENCY</h2>
                                            <div style={{fontSize:"18px"}}>
                                                {assetMetadata.methodology.data_collection_frequency}
                                            </div>

                                        </div>
                                        :console.log()
                                }
                            </div>
                        </div>
                        :console.log():console.log()
                    }



                    { assetMetadata?(assetMetadata.methodology)&&(assetMetadata.methodology.universe)&&(assetMetadata.methodology.universe.length>0)?
                        <div className="card cards-margin-bottom" style={{textAlign:"justify"}}>
                            <h2 className="h2-summary-landing">POPULATION COVERED BY THE DATA</h2>
                            <DynamicDivs type="text-value" data={assetMetadata.methodology.universe} ></DynamicDivs>
                        </div>
                        :console.log():console.log()
                    }


                    {   assetMetadata?(assetMetadata.methodology)&&
                    ( ((assetMetadata.methodology.data_collection_mode)&&(assetMetadata.methodology.data_collection_mode!=='')) ||
                        ((assetMetadata.methodology.instrument)&&(assetMetadata.methodology.instrument!=='')) )?

                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                {
                                    ((assetMetadata.methodology.data_collection_mode)&&(assetMetadata.methodology.data_collection_mode!==''>0))?

                                        ((assetMetadata.methodology.instrument) && (assetMetadata.methodology.instrument !== '')) ?

                                            <div className="p-col-12 p-md-6">
                                                <h2 className="h2-summary-landing">DATA COLLECTION METHOD</h2>
                                                <div style={{fontSize:"18px"}}>
                                                    {assetMetadata.methodology.data_collection_mode}
                                                </div>
                                            </div>
                                            : <div className="p-col">
                                                <h2 className="h2-summary-landing">DATA COLLECTION METHOD</h2>
                                                <div style={{fontSize:"18px"}}>
                                                    {assetMetadata.methodology.data_collection_mode}
                                                </div>
                                            </div>
                                        :console.log()
                                }

                                {
                                    (assetMetadata.methodology.instrument)&&(assetMetadata.methodology.instrument!=='')?
                                        <div  className="p-col">

                                            <h2 className="h2-summary-landing">DATA COLLECTION INSTRUMENT</h2>
                                            <div style={{fontSize:"18px"}}>
                                                {assetMetadata.methodology.instrument}
                                            </div>

                                        </div>
                                        :console.log()
                                }
                            </div>
                        </div>
                        :console.log():console.log()
                    }


                    { assetMetadata?(assetMetadata.methodology)&&(assetMetadata.methodology.sampling_process)&&(assetMetadata.methodology.sampling_process!=='')?
                        <div className="card cards-margin-bottom" style={{textAlign:"justify"}}>
                            <h2 className="h2-summary-landing">SAMPLING PROCEDURE</h2>
                            <div style={{fontSize:"18px"}}>
                                {assetMetadata.methodology.sampling_process}
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    <div className="p-grid">

                        {   assetMetadata?(controlledvoc)&&(controlledvoc.length>0)?

                            ((keywords)&&(keywords.length>0))?
                                <div className="p-col-12 p-md-6">
                                    <div className="card cards-margin-bottom">
                                        <h2 className="h2-title">CONTROLLED TERMS</h2>
                                        <Am_TagCloud chartId="controlledvoc_tag_cloud"
                                                     chartData={controlledvoc}
                                                     useVOC="true"
                                                     chartHeight="300px">
                                        </Am_TagCloud>
                                    </div>
                                </div>
                                :<div className="p-col">
                                    <div className="card cards-margin-bottom">
                                        <h2 className="h2-title">CONTROLLED TERMS</h2>
                                        <Am_TagCloud chartId="controlledvoc_tag_cloud"
                                                     chartData={controlledvoc}
                                                     useVOC="true"
                                                     chartHeight="300px">
                                        </Am_TagCloud>
                                    </div>
                                </div>

                            :console.log():console.log()
                        }

                        { assetMetadata?(keywords)&&(keywords.length>0)?
                            <div  className="p-col">
                                <div className="card cards-margin-bottom">
                                    <h2 className="h2-title">OTHER KEYWORDS</h2>
                                    <Am_TagCloud chartId="keyword_tag_cloud"
                                                 chartData={keywords}
                                                 useVOC="false"
                                                 chartHeight="300px">
                                    </Am_TagCloud>
                                </div>
                            </div>
                            :console.log():console.log()
                        }

                    </div>

                    {   assetMetadata?((assetMetadata.data_temporal_coverage)&&(assetMetadata.data_temporal_coverage.from)&&(assetMetadata.data_temporal_coverage.from!=='')) ?
                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h2 className="h2-summary-landing">TEMPORAL COVERAGE</h2>
                                </div>
                                <div  className="p-col">
                                    {assetMetadata.data_temporal_coverage.description ?
                                        <div style={{fontSize: "16px", fontWeight: "500"}}>{assetMetadata.data_temporal_coverage.description}</div>
                                        :console.log()
                                    }
                                    <div>
                                        <span style={{fontSize:"18px",fontWeight:"500"}}>FROM:</span>
                                        <span style={{fontSize:"24px",fontWeight:"700"}}> {assetMetadata.data_temporal_coverage.from}</span>

                                        <span style={{fontSize:"18px",fontWeight:"500"}}>  TO:</span>
                                        <span style={{fontSize:"24px",fontWeight:"700"}}> {assetMetadata.data_temporal_coverage.to}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?((assetMetadata.data_collection_period)&&(assetMetadata.data_collection_period.from)&&(assetMetadata.data_collection_period.from!=='')) ?
                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h2 className="h2-summary-landing">DATA COLLECTION PERIOD</h2>
                                </div>
                                <div  className="p-col">
                                    <div>
                                        <span style={{fontSize:"18px",fontWeight:"500"}}>FROM:</span>
                                        <span style={{fontSize:"24px",fontWeight:"700"}}> {assetMetadata.data_collection_period.from}</span>

                                        <span style={{fontSize:"18px",fontWeight:"500"}}>  TO:</span>
                                        <span style={{fontSize:"24px",fontWeight:"700"}}> {assetMetadata.data_collection_period.to}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.geography) &&
                    ( ((assetMetadata.geography.countries) && (assetMetadata.geography.countries.length>0)) ||
                        ((assetMetadata.geography.regions  ) && (assetMetadata.geography.regions.length>0  )) ) ?
                        <div className="card cards-margin-bottom">
                            <h2 className="h2-landing">GEOGRAPHY</h2>
                            <div className="p-grid">

                                { (assetMetadata.geography.countries)&&(assetMetadata.geography.countries.length>0)?
                                    <div className="p-col-12 p-md-6">
                                        <Am_MapChart chartId="asset-map-chart"
                                                     chartData={assetMetadata.geography.countries}
                                                     useVOC="false"
                                                     useNAME="false"
                                                     chartHeight="340px">
                                        </Am_MapChart>
                                    </div>
                                    :console.log()
                                }

                                <div  className="p-col">
                                    <GeographyTree treeData={assetMetadata.geography}></GeographyTree>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(((assetMetadata.projects)&&(assetMetadata.projects.length>0)) ||
                                       ((assetMetadata.funding_organisations)&&(assetMetadata.funding_organisations.length>0)))?
                        <div className="card cards-margin-bottom">

                            { ((assetMetadata.projects)&&(assetMetadata.projects.length>0))?
                                <div className="p-grid">
                                    <div className="p-col">
                                        <h2 className="h2-landing-project">PROJECT(s)</h2>

                                        <p className="p-project-landing">
                                            <Div_TAgent isVertical="true" data={assetMetadata.projects}></Div_TAgent>
                                        </p>

                                    </div>
                                </div>
                                :console.log()
                            }


                            { (((assetMetadata.projects)&&(assetMetadata.projects.length>0))&&
                                ((assetMetadata.funding_organisations)&&(assetMetadata.funding_organisations.length>0)))?

                                <hr></hr>

                                :console.log()
                            }


                            { (assetMetadata.funding_organisations)&&(assetMetadata.funding_organisations.length>0) ?
                                <>

                                    <div className="p-grid" style={{paddingTop: "15px"}}>

                                        <div className="p-col">
                                            <h2 className="h2-landing-project">FUNDER(s)</h2>
                                            <p className="p-providers-landing">
                                                <Div_TAgent isVertical="true" data={assetMetadata.funding_organisations}></Div_TAgent>
                                            </p>

                                        </div>
                                    </div>
                                </>

                                :console.log()
                            }

                        </div>
                        :console.log():console.log()
                    }


                    {   assetMetadata?(assetMetadata.resource_files)&&(assetMetadata.resource_files.length>0)?
                        <div className="card cards-margin-bottom">
                            <h2 className="h2-landing">RESOURCE FILES</h2>
                            <div className="p-grid">
                                <div className="p-col">
                                    <ResourceFilesTable ResAccess={assetMetadata.download_all_url}   dataResource={assetMetadata.resource_files}></ResourceFilesTable>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.related_resources)&&(assetMetadata.related_resources.length>0)?
                        <div className="card">
                            <h2 className="h2-landing">RELATED RESOURCES</h2>
                            <div className="p-grid">
                                <div className="p-col">
                                    <RelatedTable dataRelevant={assetMetadata.related_resources}></RelatedTable>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.relevant_resources)&&(assetMetadata.relevant_resources.length>0)?
                        <div className="card">
                            <h2 className="h2-landing">{RelevantResHeader}</h2>
                            <div className="p-grid">
                                <div className="p-col">
                                    <RelevantTable dataRelevant={assetMetadata.relevant_resources}></RelevantTable>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                </div>


                <div className="p-col-12 p-lg-4">

                    {   assetMetadata?(assetMetadata.citation)?
                        <div className="card cards-margin-bottom">
                            <h2 className="h2-landing-citation">HOW TO CITE</h2>
                            <div className="p-grid">
                                <div  className="p-col-fixed landing-thumbnail-fixed">
                                    { ( (assetMetadata.thumbnails)&&(assetMetadata.thumbnails.length>0))?
                                        <img className="title-thumbnail" alt="thumbnail" style={{width: "110px"}} src={assetMetadata.thumbnails[0].url} onError={(e) =>
                                            (assetMetadata.resource_type.type==='publication')?
                                                e.target.src = 'assets/layout/images/publication-icon.png'
                                                :e.target.src = 'assets/layout/images/spreadsheet-icon.png'} />
                                        :
                                        <img className="title-thumbnail" alt="thumbnail" style={{width: "110px"}} src="" onError={(e) =>
                                            (assetMetadata.resource_type.type==='publication')?
                                                e.target.src = 'assets/layout/images/publication-icon.png'
                                                :e.target.src = 'assets/layout/images/spreadsheet-icon.png'} />

                                    }

                                </div>
                                <div  className="p-col" style={{width: "-webkit-fill-available", textAlign:"left"}}>
                                    <p className="citation" style={{textAlign:"left"}}>{ ReactHtmlParser(assetMetadata.citation) }</p>
                                </div>
                            </div>
                            <div className="addthis_inline_share_toolbox" style={{paddingTop: "12px"}}></div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.rights)?
                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                <h2 className="h2-landing">ACCESS RIGHTS</h2>

                                { (assetMetadata.rights.access==="Open")?
                                    <div className="div-access-asset">
                                        <Tooltip target=".image-access-asset"/>
                                        <img alt="" src={'assets/layout/images/open.png'} data-pr-tooltip={assetMetadata.rights.access} className="image-access-asset"/>
                                    </div>

                                    :<div className="div-access-asset">
                                        <Tooltip target=".image-access-asset" mouseTrack mouseTrackLeft={10}/>
                                        <img alt="" src={'assets/layout/images/close.png'} data-pr-tooltip={assetMetadata.rights.access} className="image-access-asset"/>
                                    </div>
                                }

                                <div className="p-col-12">
                                    <div className="div-align-left">

                                        <DynamicDivs type="license" data={assetMetadata.rights}></DynamicDivs>

                                        { (assetMetadata.rights.terms_of_use)&&(assetMetadata.rights.terms_of_use.length>0)?
                                            <ModalAccessRights type="access-modal" title="Terms of Use" data={assetMetadata.rights.terms_of_use}></ModalAccessRights>
                                            :console.log()
                                        }

                                        { (assetMetadata.rights.disclaimer)&&(assetMetadata.rights.disclaimer.length>0)?
                                            <ModalAccessRights type="access-modal" title="Disclaimer" data={assetMetadata.rights.disclaimer}></ModalAccessRights>
                                            :console.log()
                                        }


                                        { (assetMetadata.rights.rights_holders)&&(assetMetadata.rights.rights_holders.length>0)?
                                            <>
                                                <hr/>
                                                <p style={{fontWeight: "bold"}}>Rights Holder(s)</p>
                                                <Div_TAgent isVertical="true" data={assetMetadata.rights.rights_holders}></Div_TAgent>
                                            </>
                                            : console.log()
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata? ((assetMetadata.dois)&&(assetMetadata.dois.length>0)) || ((assetMetadata.sources)&&(assetMetadata.sources.length>0))?
                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                <h2 className="h2-landing">LINKS</h2>
                                <div className="p-col-12">
                                    <div className="div-align-left">

                                        { (assetMetadata.dois)&&(assetMetadata.dois.length>0)?<DynamicDivs type="dois" data={assetMetadata.dois} ></DynamicDivs>:console.log() }

                                        { (assetMetadata.sources)&&(assetMetadata.sources.length>0)?<DynamicDivs type="url" data={assetMetadata.sources} ></DynamicDivs>:console.log() }

                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.other_pids)&&(assetMetadata.other_pids.length>0)?
                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                <h2 className="h2-landing">IDENTIFIERS</h2>
                                <div className="p-col-12">
                                    <div className="div-align-left">
                                        <DynamicDivs type="identifiers" data={assetMetadata.other_pids} ></DynamicDivs>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.fair_score)&&(assetMetadata.fair_score.length>0)?
                        <div className="card cards-margin-bottom">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h2 className="h2-landing" style={{ marginBottom: '0px'}}>FAIR INDICATORS <Link to="/fair/" target="_blank"><i className="fad fa-question-circle" style={{"--fa-primary-color":"white"}}></i></Link></h2>
                                    <div style={{textAlign: '-webkit-center'}}>
                                        <Am_FairScoring chartId="fair-scoring-chart"
                                                        chartData={assetMetadata.fair_score}>

                                        </Am_FairScoring>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                    {   assetMetadata?(assetMetadata.dois)&&(assetMetadata.dois.length>0)?
                        <div className="card cards-margin-bottom">
                            <h2 className="h2-landing-citation">ATTENTION & CITATIONS</h2>
                            <div className="p-grid">
                                <div  className="p-col-6">
                                    <div className="altmetric-embed" data-badge-popover="right" data-badge-type="medium-donut" data-doi={assetMetadata.dois[0].value}  data-link-target='_blank'></div>
                                </div>
                                <div  className="p-col-6">
                                    <div className="dimen-badge">
                                        <span className="__dimensions_badge_embed__" data-doi={assetMetadata.dois[0].value}  ></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :console.log():console.log()
                    }

                </div>

                <Helmet>
                    <script type='text/javascript' src='https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js'></script>
                    <script type='text/javascript' src="https://badge.dimensions.ai/badge.js" charSet="utf-8"></script>
                    <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-60d8677064ad774c"></script>
                </Helmet>


            </div>


        )
    }

    return (
        <>

            <div className="p-grid our-layout-content landing-container">
                {
                    assetMetadata?renderPage():console.log()
                }
            </div>
        </>

    );
}
