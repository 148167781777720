import React, {useEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_unRegionsHigh from "@amcharts/amcharts4-geodata/unRegionsHigh";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export const Am_RegionsMapChart = (props) => {

    useEffect(() => {


        const UNregions = [
            {code: "UNM49:145", label: "Asia / Western Asia", id: "westAsia", value:0},
            {code: "UNM49:021", label: "Americas / Northern America", id:"northAmerica", value:0},
            {code: "UNM49:143", label: "Asia / Central Asia", id:"centralAsia", value:0},
            {code: "UNM49:057", label: "Oceania / Micronesia", id:"micronesia", value:0},
            {code: "UNM49:054", label: "Oceania / Melanesia", id:"melanesia", value:0},
            {code: "UNM49:015", label: "Africa / Northern Africa", id:"northAfrica", value:0},
            {code: "UNM49:030", label: "Asia / Eastern Asia", id:"eastAsia", value:0},
            {code: "UNM49:018", label: "Africa / Sub-Saharan Africa / Southern Africa", id:"southAfrica", value:0},
            {code: "UNM49:035", label: "Asia / South-eastern Asia", id:"southeastAsia", value:0},
            {code: "UNM49:013", label: "Americas / Latin America and the Caribbean / Central America", id:"centralAmerica", value:0},
            {code: "UNM49:151", label: "Europe / Eastern Europe", id:"eastEurope", value:0},
            {code: "UNM49:011", label: "Africa / Sub-Saharan Africa / Western Africa", id:"westAfrica", value:0},
            {code: "UNM49:014", label: "Africa / Sub-Saharan Africa / Eastern Africa", id:"eastAfrica", value:0},
            {code: "UNM49:155", label: "Europe / Western Europe", id:"westEurope", value:0},
            {code: "UNM49:053", label: "Oceania / Australia and New Zealand", id:"australiaNZ", value:0},
            {code: "UNM49:061", label: "Oceania / Polynesia", id:"polynesia", value:0},
            {code: "UNM49:005", label: "Americas / Latin America and the Caribbean / South America", id:"southAmerica", value:0},
            {code: "UNM49:154", label: "Europe / Northern Europe", id:"northEurope", value:0},
            {code: "UNM49:039", label: "Europe / Southern Europe", id:"southEurope", value:0},
            {code: "UNM49:017", label: "Africa / Sub-Saharan Africa / Middle Africa", id:"middleAfrica", value:0},
            {code: "UNM49:034", label: "Asia / Southern Asia", id:"southAsia", value:0},
            {code: "UNM49:029", label: "Americas / Latin America and the Caribbean / Caribbean", id:"caribbean", value:0}
        ];

        am4core.addLicense("MP289451215");
        //-----------------------
        am4core.useTheme(am4themes_animated);
        //-----------------------
        let chart = am4core.create(props.chartId, am4maps.MapChart);
        //------------------
        chart.geodata = am4geodata_unRegionsHigh;

//        chart.geodata = am4geodata_continentsHigh;




        // Set projection
//        chart.projection = new am4maps.projections.NaturalEarth1();
//        chart.panBehavior = "rotateLong";
        chart.projection = new am4maps.projections.Miller();

        //------------------
        chart.seriesContainer.draggable = false;
        chart.seriesContainer.resizable = false;
        chart.maxZoomLevel = 1;
        chart.seriesContainer.events.disableType("doublehit");
        chart.chartContainer.background.events.disableType("doublehit");
        //------------------
        let selected_regions = [];
        let newArr = [...props.chartData];
        for(let i = 0; i < newArr.length; i++) {
            if (newArr[i].value>0) {
                selected_regions.push(newArr[i].id);
            }
        }
        //------------------
        let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        //------------------
        polygonSeries.heatRules.push({
            property: "fill",
            target: polygonSeries.mapPolygons.template,
            min: am4core.color("#f9e8d2"),
            max: am4core.color("#ff8702")
        });
        //------------------
        polygonSeries.data = props.chartData;
        //------------------
// Set up heat legend
//         let heatLegend = chart.createChild(am4maps.HeatLegend);
//         heatLegend.series = polygonSeries;
//         heatLegend.align = "center";
//         heatLegend.valign = "bottom";
//         heatLegend.width = am4core.percent(15);
//         heatLegend.marginLeft = "80px"; //am4core.percent(4);
//       //  heatLegend.marginBottom = "20px";
//         heatLegend.minValue = 0;
//         heatLegend.maxValue = 1000000;
//
// // Set up custom heat map legend labels using axis ranges
//         let minRange = heatLegend.valueAxis.axisRanges.create();
//         minRange.value = heatLegend.minValue;
//         minRange.label.text = "Min";
//         let maxRange = heatLegend.valueAxis.axisRanges.create();
//         maxRange.value = heatLegend.maxValue;
//         maxRange.label.text = "Max";

// // Blank out internal heat legend value axis labels
//         heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function(labelText) {
//             return "";
//         });
        //------------------
// Configure series tooltip
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}: {value}";
        polygonTemplate.polygon.fillOpacity = 0.6;

        let activeRegion = polygonTemplate.states.create("active");

        //------------------ Call Back Function

        if(props.callback) {

            activeRegion.properties.tooltipText = "[bold]{name}[/] \n{value} resources\n\n (click to explore)";

            polygonTemplate.events.on("hit", function(ev) {

                if (ev.target.isActive) {

                    let resultCHARTClick = {
                        term: ev.target.dataItem.dataContext.code,
                        type: props.filterType
                    };

                    if(props.callback){
                        props.callback(resultCHARTClick);
                    }

                }

            });

        } else {
            activeRegion.properties.tooltipText = "{name}: {value}";
        }

        //------------------

        chart.events.on("ready", function(ev) {
            //--------------- Clear ALL
            for(let i = 0; i < UNregions.length; i++) {
                let region = polygonSeries.getPolygonById(UNregions[i].id);
                region.isActive = false;
            }
            //--------------- Check only those with data
            if(selected_regions){
                for(let i = 0; i < selected_regions.length; i++) {

                    let region = polygonSeries.getPolygonById(selected_regions[i]);
                    region.isActive = true;
                }
            }

        });


        // chart.smallMap = new am4maps.SmallMap();
        // chart.smallMap.series.push(polygonSeries);
        //
        // chart.smallMap.rectangle.stroke = am4core.color("#5583C4");
        // chart.smallMap.rectangle.strokeWidth = 2;
        //
        // chart.smallMap.background.stroke = am4core.color("#666666")
        // chart.smallMap.background.strokeOpacity = 0.9;
        // chart.smallMap.background.strokeWidth = 1;
        //
        //
        // chart.smallMap.align = "left";
        // chart.smallMap.valign = "top";
        //
        // let smallSeries = chart.smallMap.series.getIndex(0);
        // smallSeries.mapPolygons.template.stroke = smallSeries.mapPolygons.template.fill;
        // smallSeries.mapPolygons.template.strokeWidth = 1;

        // chart.zoomControl = new am4maps.ZoomControl();
        //
        // let homeButton = new am4core.Button();
        // homeButton.events.on("hit", function(){
        //     chart.goHome();
        // });
        //
        // homeButton.icon = new am4core.Sprite();
        // homeButton.padding(7, 5, 7, 5);
        // homeButton.width = 30;
        // homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
        // homeButton.marginBottom = 10;
        // homeButton.parent = chart.zoomControl;
        // homeButton.insertBefore(chart.zoomControl.plusButton);

    }, [props.filter_query, props.chartData]);

    if (props.chartTitle) {
        return (
            <div style={{textAlign:"center"}}>
                <h4 style={{margin: "0px"}}>{props.chartTitle}</h4>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }else{
        return (
            <div>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }

}
