import React, {useEffect, useState} from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';

const AppBreadcrumb = () => {

    const [breadcrumbPath, setBreadcrumbPath] = useState(null);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {

        setBreadcrumbPath("ΗΟΜΕ");
        if (location.pathname.search("search")>-1)     { setBreadcrumbPath("Search");      }
        if (location.pathname.search("asset")>-1)      { setBreadcrumbPath("GARDIAN Resource"); }
        if (location.pathname.search("analytics")>-1)  { setBreadcrumbPath("Analytics"); }
        if (location.pathname.search("about")>-1)      { setBreadcrumbPath("About"); }
        if (location.pathname.search("maps")>-1)      { setBreadcrumbPath("Geospatial Data"); }
        if (location.pathname.search("tools")>-1)      { setBreadcrumbPath("Tools"); }
        if (location.pathname.search("api")>-1)        { setBreadcrumbPath("APIs"); }
        if (location.pathname.search("fair")>-1)       { setBreadcrumbPath("FAIR metrics"); }

    }, [location.pathname])

    return (
        <div className="layout-breadcrumb">
            <ul>

                { (breadcrumbPath==="ΗΟΜΕ")?
                    <>
                        <span style={{color:"white",fontSize:"16px" }}>Global Agricultural Research Data Innovation Acceleration Network</span>
                    </>
                    :
                    <>
                        <li><button type="button" className="p-link" onClick={() => history.push('/')} ><i className="pi pi-home"></i></button></li>
                        <li><i className="pi pi-chevron-right chevron-icon"></i></li>
                        <li><button className="p-link">{breadcrumbPath}</button></li>
                    </>
                }

            </ul>
        </div>
    );
}

export default withRouter(AppBreadcrumb);
