import React from 'react';
import {Divider} from "primereact/divider";
import {Privacy} from "./gardian/components/Privacy";
import {Disclaimer} from "./gardian/components/Disclaimer";
import {TermsOfUse} from "./gardian/components/TermsOfUse";

const AppFooter = () => {

    return (
        <div className="layout-footer" >
            <div className="p-grid footer-grid">
                <div className="p-col">

                    <div className="p-grid footer-grid">
                        <div className="p-col-8" style={{textAlign: "left",paddingTop:"50px"}}>
                            <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer">
                                <i className="fab fa-creative-commons license-icons" />
                                <i className="fab fa-creative-commons-by license-icons"   />
                                <i className="fab fa-creative-commons-nc license-icons" />
                                <i className="fab fa-creative-commons-sa license-icons"  />
                            </a>
                        </div>
                        <div className="p-col-4">
                            <a href="https://bigdata.cgiar.org/" target="_blank" rel="noreferrer" style={{color: "#a5a5a5"}}>
                                <img src="assets/layout/images/CGIAR_footer.png" alt="" style={{height:"66px"}}></img>
                            </a>
                        </div>
                    </div>
                    <Divider align="center" className="footer-divider"></Divider>
                    <div className="p-grid footer-grid">
                            <div className="p-col-8" style={{textAlign: "left"}}>
                                <Privacy></Privacy>
                                <Disclaimer></Disclaimer>
                                <TermsOfUse></TermsOfUse>
                            </div>
                        <div className="p-col-4">
                            <a href="https://scio.systems/" target="_blank" rel="noreferrer" style={{color: "#a5a5a5"}}>
                                <span>powered by</span>
                                <span style={{fontSize: "larger", color: "#dcdcdc"}}> SCiO</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppFooter;
