import React, {useEffect, useState} from "react";
import {AdvancedSearch} from "../components/AdvancedSearch";
import {TabPanel, TabView} from "primereact/tabview";
import {ResultsTable} from "../components/ResultsTable";
import {useParams} from "react-router-dom";
import QvantumService from "../service/QvantumService";
import {Maps} from "../components/Maps";
import {GlowGlobe} from "../components/glowglobe/GlowGlobe";

export const SearchResults = () => {
    const [pubFullResults, setPubFullResults] = useState(null);
    const [datasetFullResults, setDatasetFullResults] = useState(null);
    //-------------------------------
    const [pubResults, setPubResults] = useState(null);
    const [datasetResults, setDatasetResults] = useState(null);
    //-------------------------------
    const [pubHeader,  setPubHeader]  = useState("Documents / Digital Assets");
    const [dataHeader, setDataHeader] = useState("Datasets");
    //-------------------------------
    const [activeIndex, setActiveIndex] = useState(0);
    //-------------------------------
    const [loadingPUBS, setLoadingPUBS] = useState(true);
    const [loadingDATA, setLoadingDATA] = useState(true);
    //-------------------------------
    const [totalPubs,  setTotalPubs]  = useState("searching ...");
    const [totalData, setTotalData] = useState("searching ...");
    //-------------------------------
    const [totalFullPubs,  setTotalFullPubs]  = useState(null);
    const [totalFullData, setTotalFullData] = useState(null);


    //------------------------------- FULL Parameters for Publications & Datasets
    const [fullParamsQService, setFullParamsQService] = useState({
        first: 0,
        page: 1,
        sort_field:null,
        sort_order:null,
        rows:1000,
        filters: {
            'release_year': { value: '', matchMode: 'contains' },
            'title': { value: '', matchMode: 'contains' },
            'category': { value: '', matchMode: 'contains' },
            'access': { value: '', matchMode: 'contains' },
        }
    });


    //------------------------------- Lazy Parameters for Publications
    const [lazyParamsPublication, setLazyParamsPublication] = useState({
        first: 0,
        page: 1,
        sort_field:null,
        sort_order:null,
        rows:10,
        filters: {
            'release_year': { value: '', matchMode: 'contains' },
            'title': { value: '', matchMode: 'contains' },
            'category': { value: '', matchMode: 'contains' },
            'access': { value: '', matchMode: 'contains' },
        }
    });

    //------------------------------- Lazy Parameters for Datasets
    const [lazyParamsDataset, setLazyParamsDataset] = useState({
        first: 0,
        page: 1,
        sort_field:null,
        sort_order:null,
        rows:10,
        filters: {
            'release_year': { value: '', matchMode: 'contains' },
            'title': { value: '', matchMode: 'contains' },
            'category': { value: '', matchMode: 'contains' },
            'access': { value: '', matchMode: 'contains' },
        }
    });

    //-------------------------------

    let params = useParams();

    let ElementMap = [
        { "field":"allFields",         "element":"multi_match" },
        { "field":"title",             "element":"title.value" },
        { "field":"description",       "element":"description.value" },
        { "field":"controlledTerms",   "element":"keywords.value" },
        { "field":"otherKeywords",     "element":"keywords.value" },
        { "field":"region",            "element":"geography.regions.code" },
        { "field":"oceans",            "element":"geography.regions.code" },
        { "field":"country",           "element":"geography.countries.code_UNM49" },
        { "field":"provider",          "element":"providers.short_name" },
        { "field":"author",            "element":"authors.full_name" },
        { "field":"release_year",      "element":"release_year" },
        { "field":"temporal_coverage", "element":"data_temporal_coverage" },
        { "field":"funder",            "element":"funding_organisations.full_name" },
        { "field":"project",           "element":"projects.short_name" }
    ];

    //-------------------------------

    let full_query = JSON.parse(params.query);

    useEffect(() => {

        if (params.query) {

            let FULLquery  = JSON.parse(params.query);

            //--------------------------

            let ElasticQuery="{ \\\"query\\\": ";

            function parseQuery(Qlevel, obj) {

                if (obj.rules) {
                    //------------------------- FIX GROUP > FIRST
                    if (Qlevel > 0) { ElasticQuery += ","; }
                    //------------------------- GROUP PRE STATEMENTS
                    ElasticQuery += "{ \\\"bool\\\": {";
                    if (obj.combinator === "and") {
                        ElasticQuery += "\\\"must\\\": [ ";
                    } else {
                        ElasticQuery += "\\\"should\\\": [ ";
                    }
                    //---------------------------------- GROUP MAIN BODY

                    for (let i = 0; i < obj.rules.length; i++) {
                        if (obj.rules[i].rules) {
                            parseQuery(i, obj.rules[i]);
                        } else {
                            //---------------------------------- FIX RULE > FIRST
                            if (i > 0) { ElasticQuery += ","; }
                            //---------------------------------- GET MODEL ELEMENT
                            let element =""
                            for (let j = 0; j < ElementMap.length; j++) {
                                if (ElementMap[j].field === obj.rules[i].field) {
                                    element = ElementMap[j].element;
                                }
                            }
                            //---------------------------------- RULE MAIN BODY
                            if (element) {
                                //----------- contains (contains)
                                if (obj.rules[i].operator === "contains") {
                                    if (element === "multi_match") {
                                        ElasticQuery += "{ \\\"multi_match\\\": { \\\"query\\\":\\\"" + obj.rules[i].value + "\\\", \\\"fields\\\": [ \\\"title.value\\\", \\\"description.value\\\", \\\"keywords.value.keyword\\\" ], \\\"type\\\":\\\"phrase\\\" } }";
                                    }
                                    else {
                                        ElasticQuery += "{ \\\"match_phrase\\\": { \\\"" + element + "\\\": \\\"" + obj.rules[i].value + "\\\" } }";
                                    }
                                }
                                //----------- does not contain (doesNotContain)
                                if (obj.rules[i].operator === "doesNotContain") {

                                    if (element === "multi_match") {
                                        ElasticQuery += "{ \\\"bool\\\": { \\\"must_not\\\": [ { \\\"multi_match\\\": { \\\"query\\\":\\\"" + obj.rules[i].value + "\\\", \\\"fields\\\": [ \\\"title.value\\\", \\\"description.value\\\", \\\"keywords.value.keyword\\\" ], \\\"type\\\":\\\"phrase\\\" } } ] } }";
                                    }
                                    else {
                                        ElasticQuery += "{ \\\"bool\\\": { \\\"must_not\\\": [ { \\\"match_phrase\\\": { \\\"" + element + "\\\": \\\"" + obj.rules[i].value + "\\\" } } ] } }";
                                    }
                                }

                                //----------- is (=)
                                if (obj.rules[i].operator === "=") {
                                    ElasticQuery += "{ \\\"term\\\": { \\\"" + element + ".keyword\\\": { \\\"value\\\": \\\"" + obj.rules[i].value + "\\\" } } }";
                                }

                                //----------- is not (!=)
                                if (obj.rules[i].operator === "!=") {
                                    ElasticQuery += "{ \\\"bool\\\": { \\\"must_not\\\": [ { \\\"term\\\": { \\\"" + element +".keyword\\\": { \\\"value\\\": \\\"" + obj.rules[i].value + "\\\" } } } ] } }";
                                }

                                //----------- is greater than or equal to (>=)
                                if (obj.rules[i].operator === ">=") {
                                    ElasticQuery += "{ \\\"range\\\": { \\\"" + element + ".from\\\": { \\\"gte\\\": \\\"" + obj.rules[i].value + "\\\" } } }";
                                }

                                //----------- is less than or equal to (<=)
                                if (obj.rules[i].operator === "<=") {
                                    ElasticQuery += "{ \\\"range\\\": { \\\"" + element + ".to\\\": { \\\"lte\\\": \\\"" + obj.rules[i].value + "\\\" } } }";
                                }
                            }
                        }
                    }
                    //------------------------- GROUP POST STATEMENTS
                    ElasticQuery += "] } } ";
                }
                return ElasticQuery;
            }

            //--------------------------

            let ElasticSearchQuery = parseQuery(0,FULLquery.json_query)+"}";

            //--------------------------
            let cleanQUERY = ElasticSearchQuery.replace("{ \\\"query\\\":","");
            cleanQUERY = cleanQUERY.slice(0, cleanQUERY.lastIndexOf("}"));
            //--------------------------

            let dataQUERY = "{ \\\"query\\\": { \\\"bool\\\": { \\\"must\\\": [ { \\\"term\\\": { \\\"resource_type.type.keyword\\\": { \\\"value\\\": \\\"dataset\\\" } } }, " + cleanQUERY + " ] } } }";

            let pubsQUERY = "{ \\\"query\\\": { \\\"bool\\\": { \\\"must\\\": [ { \\\"term\\\": { \\\"resource_type.type.keyword\\\": { \\\"value\\\": \\\"document\\\" } } }, " + cleanQUERY + " ] } } }";


            //--------------------------

            let pubsQUERYX = "{ \"query\": \"" + pubsQUERY + "\"}";

            let dataQUERYX = "{ \"query\": \"" + dataQUERY + "\"}";

            //--------------------------

            setLoadingPUBS(true);

            setLoadingDATA(true);

            //--------------------------

            if ( params.query === "{\"user_query\":\"gardian demo\",\"json_query\":{\"rules\":[{\"field\":\"allFields\",\"value\":\"gardian\",\"operator\":\"contains\"},{\"field\":\"allFields\",\"value\":\"demo\",\"operator\":\"contains\"}],\"combinator\":\"and\",\"not\":false}}") {

                const dataqvantumService = new QvantumService();

                dataqvantumService.getPubResults().then(data => {

                    setTotalPubs("demo");
                    setTotalData("demo");

                    setPubResults(data);

                    setLoadingPUBS(false);
                    setLoadingDATA(false);
                });

            }
            else {    //------------- HERE IS THE REAL CALL

                //-------------------------- FULL Call for publications

                const fullpubqvantumService = new QvantumService();

                fullpubqvantumService.getResults(pubsQUERYX,fullParamsQService).then(data =>{

                    let fullPUBres = {

                        "summaries": data.aggregations,
                        "results" : data.documents
                    };

                    console.log(data);

                    setPubFullResults(fullPUBres);
                    setTotalFullPubs(data.total);

                } );

                //-------------------------- FULL Call for datasets

                const fulldataqvantumService = new QvantumService();

                fulldataqvantumService.getResults(dataQUERYX,fullParamsQService).then(data =>{

                    console.log(dataQUERYX);

                    let fullDATAres = {

                        "summaries": data.aggregations,
                        "results" : data.documents
                    };

                    console.log(fullDATAres);

                    setDatasetFullResults(fullDATAres);
                    setTotalFullData(data.total);

                } );


                //-------------------------- Lazy First Call for publications

                const pubqvantumService = new QvantumService();

                pubqvantumService.getResults(pubsQUERYX,lazyParamsPublication).then(data =>{

                    let PUBres = {

                        "summaries": data.aggregations,
                        "results" : data.documents
                    };

                    setPubResults(PUBres);
                    setTotalPubs(data.total);
                    setLoadingPUBS(false);

                } );

                //--------------------------  Lazy First Call for datasets

                const dataqvantumService = new QvantumService();

                dataqvantumService.getResults(dataQUERYX,lazyParamsDataset).then(data =>{

                    let DATAres = {

                        "summaries": data.aggregations,
                        "results" : data.documents
                    };

                    setDatasetResults(DATAres);
                    setTotalData(data.total);
                    setLoadingDATA(false);

                } );

            }

        }


    }, [params.query]);

    useEffect(() => {
        let tabindex = 0;

        if (params.tab) {

            if ((parseInt(params.tab)) && (parseInt(params.tab)<3)) {
                tabindex = parseInt(params.tab);
            }
        }

        setActiveIndex(tabindex);

    }, [params.tab]);


    useEffect(() => {

        setPubHeader("Documents / Digital Assets ("+totalPubs+")");

    }, [totalPubs]);

    useEffect(() => {

        setDataHeader("Datasets ("+totalData+")");

    }, [totalData]);

    const executeLazyQuery = (assetType,event,eventType) =>{

        let FULLquery  = JSON.parse(params.query);

        //--------------------------

        let ElasticQuery="{ \\\"query\\\": ";

        function parseQuery(Qlevel, obj) {

            if (obj.rules) {
                //------------------------- FIX GROUP > FIRST
                if (Qlevel > 0) { ElasticQuery += ","; }
                //------------------------- GROUP PRE STATEMENTS
                ElasticQuery += "{ \\\"bool\\\": {";
                if (obj.combinator === "and") {
                    ElasticQuery += "\\\"must\\\": [ ";
                } else {
                    ElasticQuery += "\\\"should\\\": [ ";
                }
                //---------------------------------- GROUP MAIN BODY

                for (let i = 0; i < obj.rules.length; i++) {
                    if (obj.rules[i].rules) {
                        parseQuery(i, obj.rules[i]);
                    } else {
                        //---------------------------------- FIX RULE > FIRST
                        if (i > 0) { ElasticQuery += ","; }
                        //---------------------------------- GET MODEL ELEMENT
                        let element =""
                        for (let j = 0; j < ElementMap.length; j++) {
                            if (ElementMap[j].field === obj.rules[i].field) {
                                element = ElementMap[j].element;
                            }
                        }
                        //---------------------------------- RULE MAIN BODY
                        if (element) {
                            //----------- contains (contains)
                            if (obj.rules[i].operator === "contains") {
                                if (element === "multi_match") {
                                    ElasticQuery += "{ \\\"multi_match\\\": { \\\"query\\\":\\\"" + obj.rules[i].value + "\\\", \\\"fields\\\": [ \\\"title.value\\\", \\\"description.value\\\", \\\"keywords.value.keyword\\\" ], \\\"type\\\":\\\"phrase\\\" } }";
                                }
                                else {
                                    ElasticQuery += "{ \\\"match_phrase\\\": { \\\"" + element + "\\\": \\\"" + obj.rules[i].value + "\\\" } }";
                                }
                            }
                            //----------- does not contain (doesNotContain)
                            if (obj.rules[i].operator === "doesNotContain") {

                                if (element === "multi_match") {
                                    ElasticQuery += "{ \\\"bool\\\": { \\\"must_not\\\": [ { \\\"multi_match\\\": { \\\"query\\\":\\\"" + obj.rules[i].value + "\\\", \\\"fields\\\": [ \\\"title.value\\\", \\\"description.value\\\", \\\"keywords.value.keyword\\\" ], \\\"type\\\":\\\"phrase\\\" } } ] } }";
                                }
                                else {
                                    ElasticQuery += "{ \\\"bool\\\": { \\\"must_not\\\": [ { \\\"match_phrase\\\": { \\\"" + element + "\\\": \\\"" + obj.rules[i].value + "\\\" } } ] } }";
                                }
                            }

                            //----------- is (=)
                            if (obj.rules[i].operator === "=") {
                                ElasticQuery += "{ \\\"term\\\": { \\\"" + element + ".keyword\\\": { \\\"value\\\": \\\"" + obj.rules[i].value + "\\\" } } }";
                            }

                            //----------- is not (!=)
                            if (obj.rules[i].operator === "!=") {
                                ElasticQuery += "{ \\\"bool\\\": { \\\"must_not\\\": [ { \\\"term\\\": { \\\"" + element +".keyword\\\": { \\\"value\\\": \\\"" + obj.rules[i].value + "\\\" } } } ] } }";
                            }

                            //----------- is greater than or equal to (>=)
                            if (obj.rules[i].operator === ">=") {
                                ElasticQuery += "{ \\\"range\\\": { \\\"" + element + ".from\\\": { \\\"gte\\\": \\\"" + obj.rules[i].value + "\\\" } } }";
                            }

                            //----------- is less than or equal to (<=)
                            if (obj.rules[i].operator === "<=") {
                                ElasticQuery += "{ \\\"range\\\": { \\\"" + element + ".to\\\": { \\\"lte\\\": \\\"" + obj.rules[i].value + "\\\" } } }";
                            }
                        }
                    }
                }
                //------------------------- GROUP POST STATEMENTS
                ElasticQuery += "] } } ";
            }
            return ElasticQuery;
        }

        //--------------------------

        let ElasticSearchQuery = parseQuery(0,FULLquery.json_query)+"}";

        //--------------------------
        let cleanQUERY = ElasticSearchQuery.replace("{ \\\"query\\\":","");
        cleanQUERY = cleanQUERY.slice(0, cleanQUERY.lastIndexOf("}"));
        //--------------------------

        let dataQUERY = "{ \\\"query\\\": { \\\"bool\\\": { \\\"must\\\": [ { \\\"term\\\": { \\\"resource_type.type.keyword\\\": { \\\"value\\\": \\\"dataset\\\" } } }, " + cleanQUERY + " ] } } }";

//            let pubsQUERY = "{ \\\"query\\\": { \\\"bool\\\": { \\\"must_not\\\": [ { \\\"term\\\": { \\\"resource_type.type.keyword\\\": { \\\"value\\\": \\\"dataset\\\" } } }, " + cleanQUERY + " ] } } }";

        let pubsQUERY = "{ \\\"query\\\": { \\\"bool\\\": { \\\"must\\\": [ { \\\"term\\\": { \\\"resource_type.type.keyword\\\": { \\\"value\\\": \\\"document\\\" } } }, " + cleanQUERY + " ] } } }";


        //--------------------------

        let pubsQUERYX = "{ \"query\": \"" + pubsQUERY + "\"}";

        let dataQUERYX = "{ \"query\": \"" + dataQUERY + "\"}";

        //--------------------------




        //  console.log(params.query)


        if ( params.query === "{\"user_query\":\"gardian demo\",\"json_query\":{\"rules\":[{\"field\":\"allFields\",\"value\":\"gardian\",\"operator\":\"contains\"},{\"field\":\"allFields\",\"value\":\"demo\",\"operator\":\"contains\"}],\"combinator\":\"and\",\"not\":false}}") {

            const dataqvantumService = new QvantumService();

            dataqvantumService.getPubResults().then(data => {
                setPubResults(data);
                setLoadingPUBS(false);
            });

        }
        else {    //------------- HERE IS THE REAL CALL

            // const resultsService = new ResultsService();
            //
            // resultsService.getPubResults().then(data => {
            //     setPubResults(data);
            //     setLoadingPUBS(false);
            // });


            //console.log(pubsQUERYX);

            if(assetType === "publications"){

                let _lazyParams = lazyParamsPublication
                if(eventType === "page"){
                    _lazyParams.first = event.first;
                    _lazyParams.rows = event.rows;
                    _lazyParams.page = event.page;
                }else if(eventType === "sort"){
                    _lazyParams.sort_field = event.sortField;
                    _lazyParams.sort_order = event.sortOrder;
                }else if(eventType === "filter"){
                    _lazyParams.filters = event.filters;
                    setLazyParamsPublication({..._lazyParams})

                }else if(eventType === "release_year"){
                    _lazyParams.filters.release_year = event;
                }else if(eventType === "title"){
                    _lazyParams.filters.title = event;
                }else if(eventType === "clear"){
                    _lazyParams = {
                        first: 0,
                        page: 1,
                        sort_field:null,
                        sort_order:null,
                        rows:10,
                        filters: {
                            'release_year': { value: '', matchMode: 'contains' },
                            'title': { value: '', matchMode: 'contains' },
                            'category': { value: '', matchMode: 'contains' },
                            'access': { value: '', matchMode: 'contains' },
                        }
                    };
                }



                setLoadingPUBS(true);

                const pubqvantumService = new QvantumService();

                pubqvantumService.getResults(pubsQUERYX,_lazyParams).then(data =>{

                    let PUBres = {

                        "summaries": data.aggregations,
                        "results" : data.documents
                    };

                    //console.log(PUBres);

                    setPubResults(PUBres);
                    setTotalPubs(data.total);
                    setLoadingPUBS(false);
                    setLazyParamsPublication({..._lazyParams})

                } );





            }
        }

        // console.log(dataQUERYX);


        //--------------------------


        if(assetType === "datasets"){

            let _lazyParams = lazyParamsDataset
            if(eventType === "page"){
                _lazyParams.first = event.first;
                _lazyParams.rows = event.rows;
                _lazyParams.page = event.page;
            }else if(eventType === "sort"){
                _lazyParams.sort_field = event.sortField;
                _lazyParams.sort_order = event.sortOrder;
            }else if(eventType === "filter"){
                _lazyParams.filters = event.filters;
            }else if(eventType === "release_year"){
                _lazyParams.filters.release_year = event;
            }else if(eventType === "title"){
                _lazyParams.filters.title = event;
            }else if(eventType === "clear"){
                _lazyParams = {
                    first: 0,
                    page: 1,
                    sort_field:null,
                    sort_order:null,
                    rows:10,
                    filters: {
                        'release_year': { value: '', matchMode: 'contains' },
                        'title': { value: '', matchMode: 'contains' },
                        'category': { value: '', matchMode: 'contains' },
                        'access': { value: '', matchMode: 'contains' },
                    }
                };
            }


            setLoadingDATA(true);
            const dataqvantumService = new QvantumService();

            dataqvantumService.getResults(dataQUERYX,_lazyParams).then(data =>{

                let DATAres = {

                    "summaries": data.aggregations,
                    "results" : data.documents
                };

                // console.log(DATAres);

                setDatasetResults(DATAres);
                setTotalData(data.total);
                setLoadingDATA(false);
                setLazyParamsDataset({..._lazyParams})

            } );
        }
    }


    const renderResultsPage = (resultsDATA, fullDATA, AssetType, urlPath, loading,total,fulltotal,lazyParams) => {
        return (
            <>
                <div className="p-grid">
                    <div className="p-col">
                        <ResultsTable tableData={resultsDATA}
                                      fullData={fullDATA}
                                      loading={loading}
                                      AssetType={AssetType}
                                      urlPath={urlPath}
                                      fullQUERY={full_query}
                                      lazyFunction={executeLazyQuery}
                                      total={total}
                                      fulltotal={fulltotal}
                                      lazyParams={lazyParams}
                        > </ResultsTable>
                    </div>
                </div>
            </>
        )
    }



    return (

        <div>
            <div className="search-bar-layout-content search-bar">
                <span className="p-input-icon-right display-search">
                    <AdvancedSearch user_query={full_query}></AdvancedSearch>
                </span>
            </div>
            <div className="tabview-demo our-layout-content">
                <div className="card">
                    <TabView className="tableview-tab" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                        <TabPanel leftIcon="fad fa-table" header={dataHeader}>
                            {
                                (datasetResults && (totalData!=="demo") )?renderResultsPage(datasetResults, datasetFullResults,"datasets", '/#/asset/', loadingDATA,totalData,totalFullData,lazyParamsDataset):console.log()
                            }
                        </TabPanel>


                        <TabPanel leftIcon="fad fa-file-alt" header= {pubHeader}>
                            {
                                pubResults?renderResultsPage(pubResults, pubFullResults, "publications",'/#/asset/', loadingPUBS,totalPubs,totalFullPubs,lazyParamsPublication):console.log()
                            }
                        </TabPanel>

                    </TabView>
                </div>
            </div>
        </div>

    );




}

