import React, {useEffect, useRef, useState } from 'react';
import {FeatureGroup, LayersControl, MapContainer,  Marker, Popup, TileLayer} from "react-leaflet";
import Fullscreen from 'react-leaflet-fullscreen-plugin';
import L from "leaflet";
import 'leaflet-draw';
import "./leafletextensions/modal/L.Control.Window.css"
import "./leafletextensions/modal/L.Control.Window"
//Chroma
import chroma from "chroma-js";
import QvantumService from "../../../service/QvantumService";

var parse_georaster = require("georaster");
var GeoRasterLayer = require("georaster-layer-for-leaflet");


export const Map = (props) => {

    const [map, setMap] = useState(null);
    const layersConfigurations = useRef();
    const layerGroup = useRef();
    const position = [51.505, -0.09]

    const options = {
        position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
        title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
        forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: false, // Dom element to render in full screen, false by default, fallback to map._container
    };


    const getLayerByIndexId = (id) =>{
        let isPresent = layerGroup.current.getLayers().filter(
            (layer) =>{
                if(id === layer.options.glowglobeId){
                    return layer;
                }
            }
        )

        if(isPresent.length>0){
            return isPresent[0];
        }else{
            return -1;
        }
    }

    const loadLayers = (layers) =>{
        //cleanLayerGroup(layers);
        layers.forEach(
            (item)=> {
                let layerIsPresent = getLayerByIndexId(item.id);

                if(layerIsPresent !== -1){
                    layerIsPresent.setOpacity(item.configuration.opacity)
                }else if (item.configuration.type === "geotiff"){
                    const geospatialServices = new QvantumService();
                    geospatialServices.getGeotiffData({source: item.configuration.location}).then(
                        (data) => {
                            let base64ToSource = Buffer.from(data, "base64");
                            parse_georaster(base64ToSource).then(georaster => {
                                let layer = new GeoRasterLayer({
                                    glowglobeId:item.id,
                                    georaster: georaster,
                                    opacity: item.configuration.opacity,
                                    pixelValuesToColorFn: function (pixelValues) {
                                        let scale  = chroma.scale([
                                            item.configuration.minimumColor,
                                            item.configuration.maximumColor])

                                        const min = item.configuration.minimum;
                                        const max = item.configuration.maximum;

                                        let pixelValue = null;
                                        if(item.configuration.multiband === false){
                                            pixelValue = pixelValues[0]; // there's just one band in this raster
                                        }

                                        // if there's zero wind, don't return a color
                                        //if (pixelValue <= 0) return null;

                                        // scale to 0 - 1 used by chroma
                                        let scaledPixelValue = (pixelValue - min) / (max-min);
                                        let color = scale(scaledPixelValue).hex();
                                        return color;
                                    },
                                    resolution: item.configuration.resolution
                                })
                                layerGroup.current.addLayer(layer);
                                layerGroup.current.setZIndex(650);
                                map.fitBounds(layer.getBounds());
                            })
                        })
                }else if(item.configuration.type === "tile"){

                    let layer = L.tileLayer(item.configuration.location, {glowglobeId:item.id});

                    layerGroup.current.addLayer(layer);
                    layerGroup.current.setZIndex(650);
                    //map.fitBounds(layer.getBounds());

                }




            }
        )
    }

    useEffect(() => {
        if (!map) return;
        //Layers Group
        layerGroup.current = L.layerGroup();
        layerGroup.current.addTo(map);

        //Layers Draw
        let editableLayers = new L.FeatureGroup();
        map.addLayer(editableLayers);

        //Layers Configuration
        layersConfigurations.current = [];

        let drawnLayer = null;
        const options = {
            position: "topleft",
            draw: {
                polyline: false,
                polygon: false,
                rectangle: false,
                circle:false,
                circlemarker:false
            },
            edit: {
                featureGroup: editableLayers, //REQUIRED!!
            }
        };

        const drawControl = new L.Control.Draw(options);
        map.addControl(drawControl);

        map.on(L.Draw.Event.DRAWSTART,function(e){
            if(drawnLayer !== null){
                editableLayers.removeLayer(drawnLayer)
            }

        });

        map.on(L.Draw.Event.CREATED, function (e) {
            let layer = e.layer;

            drawnLayer = layer;

            editableLayers.addLayer(layer);
        });

        map.on(L.Draw.Event.DELETESTART, function () {
            editableLayers.eachLayer(
                (layer)=>{
                    layer.off('mouseout')
                    layer.off('mouseover')
                    layer.off('click')
                }
            )

        });

        map.on(L.Draw.Event.DELETESTOP, function () {
            editableLayers.eachLayer(
                (layer)=>{

                }
            )
        });

        //loadLayers(sampleLayer);

    }, [map]);

    useEffect(()=>{
        if(map&&props.layers){
            if(Array.isArray(props.layers)){
                if(props.layers.length < 1){
                    layerGroup.current.clearLayers();
                }else{
                    loadLayers(props.layers);
                }
            }else{
                throw 'Input should be an array. Please check whether props.layers is initialized correctly.'
            }

        }
    },[props.layers]);

    useEffect(()=>{
        if(map&&props.action){
            if(props.action.action === "zoom"){
                let layerIsPresent = getLayerByIndexId(props.action.id);
                if(layerIsPresent !== -1){
                    try{
                        map.fitBounds(layerIsPresent.getBounds());
                    }catch {
                        map.fitWorld();
                    }
                }
            }else if(props.action.action === "recenter"){
                map.fitWorld();
            }else if(props.action.action === "resize"){
                map.invalidateSize();
            }
        }
    },[props.action]);

    return(
        <div className="large-map">
            <MapContainer zoom={2} center={position} scrollWheelZoom={true} whenCreated={setMap}>
                <Fullscreen
                    eventHandlers={{
                        enterFullscreen: (event) => console.log('entered fullscreen', event),
                        exitFullscreen: (event) => console.log('exited fullscreen', event),
                    }}
                    {...options}
                />
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked name="Esri WorldStreet Map">
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer checked name="Esri WorldTopo Map">
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked name="Esri World Imagery">
                        <TileLayer
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        />
                    </LayersControl.BaseLayer>
                </LayersControl>
            </MapContainer>
        </div>
    )

}

