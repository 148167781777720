import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import ReactHtmlParser from "react-html-parser";

export const ModalAccessRights = (props) => {

    const [terms, setTerms] = useState(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');

    useEffect(() => {
        if(props.type === "access-modal"){
            displayTermsOfUse(props.data);
        }
    }, []);

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const displayTermsOfUse = (data) =>{
        let newList = [];
        data.forEach((obj) => {
            if(props.type === "access-modal"){
                    if(obj.language.name !== "English"){
                        newList.push(
                            <>
                                <h2 className="title-project-landing">[in {obj.language.name}]</h2>
                                <h2  className="title-project-landing" style={{textAlign: "justify"}}> { ReactHtmlParser(obj.value) }</h2>
                            </>
                        )
                }else if(obj.language.name === "English"){
                    newList.unshift(
                        <>
                            <h2  className="title-project-landing" style={{textAlign: "justify", paddingTop:"20px"}}> { ReactHtmlParser(obj.value) }</h2>
                        </>
                    )
                }
            }
        })
        return setTerms(newList)
    }

    return (
        <>
            <hr/>
            <p className="terms-of-use">{props.title}</p>
            <a className="access-rights-view" onClick={() => onClick('displayBasic')}> VIEW </a>
            <Dialog header={props.title} className="modal-footer" visible={displayBasic} position={position} style={{ width: '90vw' }}  onHide={() => onHide('displayBasic')}>
                {terms}
            </Dialog>
        </>
    );
}
