import React, {useEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";

export const Am_TagCloud = (props) => {

    useEffect( () => {

        am4core.addLicense("CH289451215");
        //-----------------------
        am4core.useTheme(am4themes_animated);
        //-----------------------
        let chart = am4core.create(props.chartId, am4plugins_wordCloud.WordCloud);
        chart.fontFamily = "Courier New";
        let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
        series.randomness = 0.1;
        series.rotationThreshold = 0.5;
        series.data = props.chartData;



        if(props.callback) {

            series.labels.template.tooltipText = "[bold]{value}[/] \n{word}\n\n (click to filter)";

            series.labels.template.events.on("hit", function(ev) {

                let resultCHARTClick = {
                    term: ev.target.currentText,
                    type: props.filterType
                };

                if (props.useVOC === "true") {

                    resultCHARTClick = {
                        term: ev.target.dataItem.dataContext.voc_code,
                        type: props.filterType
                    };
                }

                if(props.callback){
                    props.callback(resultCHARTClick);
                }

            });

        } else {
            series.labels.template.tooltipText = "";
            if (props.useVOC === "true") {

                series.labels.template.tooltipText = "[bold]{scheme}[/] \n{code}";


            }

        }

        series.dataFields.word = "value";
        series.dataFields.value = "frequency";

        series.angles = [0];
        series.minFontSize = 14;
        series.randomness = 0.6;


        series.heatRules.push({
            "target": series.labels.template,
            "property": "fill",
            "min": am4core.color("#8884d8"),
            "max": am4core.color("#82ca9d"),
            "dataField": "value"
        });

        let hoverState = series.labels.template.states.create("hover");
        hoverState.properties.fill = am4core.color("#ffc657");


    }, [props.filter_query, props.chartData])


    if (props.chartTitle) {
        return (
            <div style={{textAlign:"center"}}>
                <h4 style={{margin: "0px"}}>{props.chartTitle}</h4>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }else{
        return (
            <div>
                <div id={props.chartId} style={{height: props.chartHeight}}></div>
            </div>
        );
    }

}


