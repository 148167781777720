import React, {useEffect, useRef, useState } from 'react';
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {FileUpload} from "primereact/fileupload";
import {Legend} from "@amcharts/amcharts4/.internal/charts/Legend";
import {Divider} from "primereact/divider";
import {HTMLLegend} from "./htmllegeng/HTMLLegend";
import {Slider} from "primereact/slider";
import {ProgressBar} from "primereact/progressbar";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import { jsPanel } from 'jspanel4/es6module/jspanel';
import 'jspanel4/es6module/extensions/modal/jspanel.modal';
import 'jspanel4/dist/jspanel.min.css';
import * as ReactDOM from "react-dom";
import {Map} from "./leaflet/Map";
import { TreeTable } from 'primereact/treetable';
import QvantumService from "../../service/QvantumService";
import {Sidebar} from "primereact/sidebar";
import {Toolbar} from "primereact/toolbar";


export const GlowGlobe = (props) => {


    const [layers, setLayers] = useState(null);
    const [expandedLayers, setExpandedLayers] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [selectedNodeKeys3, setSelectedNodeKeys3] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [action, setAction] = useState(null);
    const [dockLayersPanel, setDockLayersPanel] = useState(null);

    const [hideLayers,setHideLayers] = useState(false);
    const [hideMap,setHideMap] = useState(false);
    const [hideVariables,setHideVariables] = useState(false);

    const [displayDialog, setDisplayDialog] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [globalFilter1, setGlobalFilter1] = useState(null);


    const toast = useRef(null);
    const fileUploadRef = useRef(null);

    const [sidebarDatasets, setSidebarDatasets] = useState(false);
    const [datasetDialog, setDatasetDialog] = useState(false);
    const [datasetDescription, setDatasetDescription] = useState(false);

    const dockerLayerPanel = useRef(null);


    useEffect(() => {

        /*
        qvantumService.getLayers().then(
            data =>{
                setLayers(data.layers);
            }
        )*/
        const qvantumService = new QvantumService();
        qvantumService.getTreeTableNodes().then(data => setNodes(data));
    }, []);


    const rowExpansionTemplate = (data) => {
        return (
            <div style={{backgroundColor:"white",padding:"10px 10px"}}>
                <h6>Legend:</h6>
                {
                    data.configuration.distribution === "continuous"?
                        <Legend data={data} className="legend-div"/>:
                        <HTMLLegend data={data} />

                }

                <Divider align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="fad fa-filter p-mr-2"></i>
                        <span>Filter</span>
                    </div>
                </Divider>
            </div>
        );
    };

    const onRowGroupExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Row Group Expanded', detail: 'Value: ' + event.data.type, life: 3000 });
    }

    const onRowGroupCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Row Group Collapsed', detail: 'Value: ' + event.data.type, life: 3000 });
    }

    const onRowReorder = (e) => {

        const reorderedSelectedLayers = e.value.map(
            (item)=>{

                let result = (selectedLayers.filter(
                    (selectedItem)=>{
                        if(selectedItem.id === item.id){
                            return selectedItem
                        }
                    }
                ))
                return result[0];
            }
        )
        let cleanSelection = reorderedSelectedLayers.filter(function(x) {
            return x !== undefined;
        });

        setLayers(e.value);
        setSelectedLayers([...cleanSelection]);

    }

    const titleTemplate = (data,props) =>{
        return (
            <div>
                <span style={{fontSize:"10px"}}>{data[props.field]}</span>
            </div>
        )

    }

    const updateOpacityLayer = (id,opacity) =>{
        let _layers = layers.map(
            (item)=>{
                if(item.id === id){
                    item.reload = true;
                    item.configuration.opacity = opacity
                }
                return item;
            }
        )

        console.log(selectedLayers)

        let _selectedLayers = [];

        if(selectedLayers){
            _selectedLayers = selectedLayers.map(
                (item)=>{
                    if(item.id === id){
                        item.reload = true;
                        item.configuration.opacity = opacity
                    }
                    return item;
                }
            )

        }

        setLayers([..._layers]);
        setSelectedLayers([..._selectedLayers]);
    }

    const sliderTemplate = (data,props) =>{
        return (
            <div>
                <Slider
                    value={data.configuration[props.field]*100}
                    onChange={(e) => updateOpacityLayer(data.id,e.value/100)}
                    step={1}
                    animate={true}
                />
            </div>
        )
    }


    const sendAction = (action,id) =>{
        const actionObject = {
            action:action,
            id:id
        }
        setAction({...actionObject})
    }

    const zoomTemplate = (data) =>{
        return (
            <div>
                <Button
                    icon="fad fa-search-plus"
                    className="p-button-rounded  p-button-icon-only p-button-text"
                    onClick={()=>sendAction("zoom",data.id)}
                />
            </div>
        )
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;

        for (const [key, value] of Object.entries(e.files)) {
            _totalSize += value.size;
        }
        setTotalSize(_totalSize);
    }

    const onTemplateUpload = (e) => {
        let _totalSize = 0;
        e.files.forEach(file => {
            _totalSize += (file.size || 0);
        });

        setTotalSize(_totalSize);
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const onHide = () => {
        setDisplayDialog(false)
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize/10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`}
                             style={{width: '300px', height: '20px', marginLeft: 'auto'}}>
                </ProgressBar>
            </div>
        );
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="fad fa-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="p-my-5">Drag and Drop File Here</span>
            </div>
        )
    }

    const chooseOptions = {icon: 'fad fa-image', iconOnly: true,
        className: 'custom-choose-btn p-button-primary p-button-raised p-button-text'};
    const uploadOptions = {icon: 'fad fa-upload', iconOnly: true,
        className: 'custom-upload-btn p-button-success p-button-raised p-button-text'};
    const cancelOptions = {icon: 'fad fa-times', iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-raised p-button-text'};

    const itemTemplate = (file, props) => {
        let fileExt = file.name.split('.').pop();

        let fileIcon ="fad fa-file-code fa-2x";
        if(fileExt === "shp"){
            fileIcon = "fad fa-hexagon fa-2x"
        }else if(fileExt === "geojson"){
            fileIcon = "fad fa-brackets-curly fa-2x"
        }else if(fileExt === "kml"){
            fileIcon = "fad fa-map-marked fa-2x"
        }


        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                    <i className={fileIcon}></i>
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
                <Button type="button" icon="fad fa-times"
                        className="p-button-danger p-button-raised p-button-text p-ml-auto"
                        onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const renderHeader = () =>{
        return (
            <div className="">
                <i className="fad fa-layer-group"></i><span className="p-ml-2">Layer Panel</span>

            </div>
        )
    }

    const renderFooter = () =>{
        return (
            <>
                <Divider layout="horizontal"/>
                <div className="p-grid p-justify-around p-col">
                    {/*<Button icon="fad fa-home"
                            className="
                            p-button-success
                            p-button-raised p-button-text
                            p-button-icon-only"
                            onClick={()=>sendAction("recenter","N/A")}

                    />*/}


                    {/*<Button icon="fad fa-upload"
                            className="
                            p-button-success
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>createPanel()}
                    />*/}



                    { /* <Button icon="fad fa-download"
                            className="
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>expandAll()}
                    />


                    <Button icon="fad fa-minus"
                            className="
                            p-button-raised p-button-text
                            p-button-icon-only
                            "
                            onClick={()=>collapseAll()}
                    />*/
                    }

                </div>
            </>

        )
    }


    const createPanel =()=>{

        const element = document.getElementById("dockerized_layer_panel");
        if(element){
            element.remove();
        }

        jsPanel.create({
            position:    "left-top",
            contentSize: {
                width: 'auto',
                height: 'auto'
            },
            content: panel => {
                const div = document.createElement('div');
                div.id = "dockerized_layer_panel";
                panel.content.append(div);
                const node = document.getElementById("dockerized_layer_panel");
                ReactDOM.render(renderLayerPanel(),node)


            },
            callback: panel => {
                /*panel.content.style.padding = '10px';
                const maxHeight = window.innerHeight - (window.innerHeight * 30) / 100;
                panel.content.style.maxHeight = `${maxHeight}px`;
                panel.content.style.maxWidth = `${window.innerWidth - 20}px`;*/
            },
        });

    }

    const header = renderHeader();
    const footer = renderFooter();

    const treeTableFuncMap = {
        'globalFilter1': setGlobalFilter1
    };

    const getHeader = (globalFilterKey) => {
        return (
            <div className="p-text-right">
                <div className="p-input-icon-left">

                </div>
            </div>
        );
    }


    let header1 = getHeader('globalFilter1');

    const selectTreeElement = (value) =>{
        setSelectedNodeKeys3(value)
        let allValues = Object.keys(value);

        const ids = allValues.filter(
            (localValue)=>{
                if(!isNaN(localValue)){
                    return parseInt(localValue)
                }
            }
        )

        const check = ids.map(
            (item)=>{
                return parseInt(item)
            }
        )

        const qvantumService = new QvantumService();
        qvantumService.getLayers().then(
            data =>{
                const chosenLayers = data.layers.filter(
                    (localValue) =>{
                        if(check.includes(localValue.id)){
                            return localValue
                        }
                    }
                )
                setLayers(chosenLayers);

            }
        )
    }


    const renderFooterDatasetDialog = () => {
        return (
            <div>
                <Button label="OK" icon="fad fa-check" onClick={() => setDatasetDialog(false)} autoFocus />
            </div>
        );
    }

    const actionTemplate = (node, column) => {
        return <div>
            <Button type="button" icon="fad fa-external-link-alt"  onClick={()=>{window.open(node.data.location,'_blank');}}></Button>
        </div>;
    }



    const leftContents = (
        <React.Fragment>
            <Button label="Select Dataset(s)"
                    icon="fad fa-clipboard-check" onClick={() => setSidebarDatasets(true)} className="mr-2" />
        </React.Fragment>
    );

    const rightContents = (
        <React.Fragment>

        </React.Fragment>
    );

    const renderLayerPanel = () =>{
        return (
        <div className="p-col-12" hidden={hideLayers}>
            <div className="card">
                <div className="card-body">
                    <DataTable
                        footer={footer}
                        header={header}
                        value={layers}
                        dataKey="id"
                        className="p-datatable-customers p-datatable-sm"
                        autoLayout={true}
                        stripedRows
                        expandedRows={expandedLayers}
                        onRowToggle={(e) => setExpandedLayers(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onRowExpand={onRowGroupExpand}
                        onRowCollapse={onRowGroupCollapse}
                        emptyMessage = "No Available Layers"
                        selection={selectedLayers}
                        onSelectionChange={e => setSelectedLayers(e.value)}
                        onRowReorder={onRowReorder}

                        paginator
                        rows={5} rowsPerPageOptions={[5,10,20,50]}
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    >

                        <Column selectionMode="multiple" style={{width:'2em'}}/>
                        <Column
                            key="name"
                            field="name"
                            header="Layer"
                            body={(data,props) => titleTemplate(data,props)}
                        />
                        <Column
                            key="opacity"
                            field="opacity"
                            header="Opacity"
                            body={(data,props) => sliderTemplate(data,props)}
                        />
                        <Column  style={{width: '2em'}}
                                 body={(data,props) => zoomTemplate(data)}
                        />
                        <Column rowReorder rowReorderIcon="fad fa-arrows" style={{width: '2em'}} />

                    </DataTable>
                </div>
            </div>
        </div>);
    }



    return(
        <div>
            <Dialog header="About" visible={datasetDialog} style={{ width: '50vw', zIndex:'11000'}}
                    footer={renderFooterDatasetDialog()} onHide={() => setDatasetDialog(false)}>
                <p>{datasetDescription}</p>
            </Dialog>

            <Toast ref={toast}></Toast>

            <Sidebar className="p-sidebar-lg"
                     style={{zIndex: "10000!important;"}}
                     visible={sidebarDatasets}
                     onHide={() => setSidebarDatasets(false)}
                     blockScroll={true}
            >
                <h4>Select Dataset(s) / Layers</h4>
                <div className="p-col-12" hidden={hideVariables}>
                    <TreeTable value={nodes}
                               selectionMode="checkbox"
                               selectionKeys={selectedNodeKeys3}
                               onSelectionChange={e => selectTreeElement(e.value)}
                               header={header1}
                               scrollable
                               scrollHeight="65vh"
                               paginator rows={20}
                               resizableColumns columnResizeMode="expand" showGridlines
                               className="geodatasetselection"
                               id="geodatasetselection"
                    >
                        <Column field="name" header="Name" expander sortable></Column>
                        <Column body={actionTemplate} style={{ textAlign: 'center', width: '10rem' }} />
                    </TreeTable>
                </div>
            </Sidebar>

            <div className="">
                <Toolbar style={{paddingLeft:"0px",paddingTop:"0px"}} left={leftContents} right={rightContents} />
            </div>
            <div className="p-grid dashboard-grid">
                <div className="p-col" hidden={hideMap}>
                    <div className="card">
                        <Map layers={selectedLayers} action={action}/>
                    </div>
                </div>

                <div className="p-col-4" hidden={hideLayers}>
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                footer={footer}
                                header={header}
                                value={layers}
                                dataKey="id"
                                className="p-datatable-customers p-datatable-sm"
                                autoLayout={true}
                                stripedRows
                                expandedRows={expandedLayers}
                                onRowToggle={(e) => setExpandedLayers(e.data)}
                                rowExpansionTemplate={rowExpansionTemplate}
                                onRowExpand={onRowGroupExpand}
                                onRowCollapse={onRowGroupCollapse}
                                emptyMessage = "No Available Layers"
                                selection={selectedLayers}
                                onSelectionChange={e => {setSelectedLayers(e.value);}}
                                onRowReorder={onRowReorder}

                                paginator
                                rows={5} rowsPerPageOptions={[5,10,20,50]}
                                paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                              >

                                <Column selectionMode="multiple" style={{width:'2em'}}/>
                                <Column
                                    key="name"
                                    field="name"
                                    header="Layer"
                                    body={(data,props) => titleTemplate(data,props)}
                                />
                                <Column
                                    key="opacity"
                                    field="opacity"
                                    header="Opacity"
                                    body={(data,props) => sliderTemplate(data,props)}
                                />
                                <Column  style={{width: '2em'}}
                                         body={(data,props) => zoomTemplate(data)}
                                />
                                <Column rowReorder rowReorderIcon="fad fa-arrows" style={{width: '2em'}} />

                            </DataTable>
                        </div>
                    </div>
                </div>
                <Dialog header="Upload your Polygon"
                        modal style={{ width: '50vw' }}
                        visible={displayDialog}
                        onHide={() => onHide()}
                        draggable={false} resizable={false}>

                    <FileUpload ref={fileUploadRef} name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" multiple accept="image/*" maxFileSize={1000000}
                                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

                </Dialog>

            </div>

            <div>
                <p>Attributions:</p>
                <p>The <a href="http://gadm.org/" target="_blank">
                    Database of Global Administrative Areas (GADM)</a> has been used as the source of the boundaries and names shown.</p>
                <p>The map is Powered by Esri</p>
                <p>Map Disclaimer: The boundaries and names shown and the designations used on this map do not imply official endorsement or acceptance by CGIAR Platform for Big Data in Agriculture. Dotted lines represent approximately the Line of Control in Jammu and Kashmir agreed upon by India and Pakistan. The final status of Jammu and Kashmir has not yet been agreed by the parties. Final boundary between the Republic of Sudan and Republic of South Sudan has not yet been determined.</p>
            </div>
        </div>
    )
}
